import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import timestampsQuery from '../../../../../../../../Events/lib/timestampsQuery'
import Loading from '../../../../../../../../Loading'
import Error from '../../../../../../../../Error'
import Timestamp from './Timestamp'
import Total from './Total'

export default ({ eventId }) => {
  const { loading, error, data } = useQuery(timestampsQuery, {
    variables: {
      id: eventId,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const {
    event: { timestamps },
  } = data

  return (
    <>
      <Total timestamps={timestamps} />
      {_.map(timestamps, (timestamp, i) => (
        <Timestamp
          key={timestamp.id}
          timestamp={timestamp}
          previousTimestamp={timestamps[i - 1]}
        />
      ))}
    </>
  )
}
