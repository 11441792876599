import React from 'react'
import { Formik, Form } from 'formik'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import TextInput from '../../Input/Text'
import Checkbox from '../../Input/Checkbox'
import CurrentUser from '../../CurrentUser'
import AccountInput from '../../Input/Account'
import Actions from './Actions'
import Config from './Config'
import validationSchema from './lib/validationSchema'
import normalize from './lib/normalize'

export default ({ children, onSubmit, ...rest }) => (
  <Formik
    validationSchema={validationSchema}
    onSubmit={(data) => onSubmit(normalize(data))}
    {...rest}
  >
    {(formikProps) => (
      <Form>
        <FormContainer>
          <TextInput label='Name' name='name' $maxWidth='300px' />
          <Checkbox label='Active' name='active' />
          <Config formikProps={formikProps} />
          <CurrentUser>
            {({ currentUser: { isGlobalAdmin } }) =>
              isGlobalAdmin && <AccountInput includeEmpty={false} />
            }
          </CurrentUser>

          <Actions formikProps={formikProps} />

          {children}

          <FormButton />
        </FormContainer>
      </Form>
    )}
  </Formik>
)
