import { gql } from 'graphql.macro'
import SensorFragment from '../../../../Sensors/lib/SensorFragment'

export default gql`
  query FilteredSensors($filters: [FilterInput!]!) {
    sensors(filters: $filters, activeOnly: true) {
      ...SensorFragment
    }
  }

  ${SensorFragment}
`
