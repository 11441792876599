import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import Layout from '../../Layout'
import Loading from '../../Loading'
import Error from '../../Error'
import EditHeader from '../../Edit/Header'
import EditList from '../../Edit/List'
import Form from '../Form'
import query from './lib/query'
import mutation from './lib/mutation'
import initialValues from './lib/initialValues'

export default ({
  match: {
    params: { id },
  },
}) => {
  const { loading, error, data } = useQuery(query, {
    variables: {
      id,
    },
  })

  const [mutate, mutationProps] = useMutation(mutation)
  const [mapImageUrlMutate, mapImageUrlMutationProps] = useMutation(mutation, {
    variables: {
      id,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Layout>
      <EditHeader>Edit area</EditHeader>

      <Form
        initialValues={initialValues(data.area)}
        onSubmit={(data) => mutate({ variables: { id, data } })}
        mapImageUrlMutate={mapImageUrlMutate}
        mapImageUrlMutationProps={mapImageUrlMutationProps}
      >
        <EditList
          header='Gateways'
          label={({ item: { name, externalId } }) =>
            `${name} (${externalId || 'no external id'})`
          }
          items={data.area.gateways}
        />
      </Form>
      {mutationProps.error && <Error leftAligned />}
      {mutationProps.data && <Redirect to='/areas' />}
    </Layout>
  )
}
