import React from 'react'
import { Toggle } from 'libreact/lib/Toggle'
import JSONPretty from 'react-json-pretty'
import styled from 'styled-components'
import Modal from 'react-modal'
import Button from '../../../../../../Button'

const Container = styled.div``

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 5px;
`

const CodeContainer = styled.div`
  padding: 10px;
  font-family: monospace;
`

const style = {
  content: {
    maxWidth: '500px',
    margin: '0 auto',
  },
}

export default ({ data }) => (
  <Toggle>
    {({ on, toggle }) => (
      <Container>
        <ButtonContainer>
          <Button size='small' onClick={toggle}>
            {on ? 'Hide' : 'Raw JSON'}
          </Button>
        </ButtonContainer>
        <Modal isOpen={on} onRequestClose={toggle} style={style}>
          {on && (
            <CodeContainer>
              <JSONPretty data={data} />
            </CodeContainer>
          )}
        </Modal>
      </Container>
    )}
  </Toggle>
)
