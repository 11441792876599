import React from 'react'
import _ from 'lodash'
import { useQuery, useMutation } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import Layout from '../../Layout'
import Loading from '../../Loading'
import Error from '../../Error'
import EditHeader from '../../Edit/Header'
import readingTypesQuery from '../../ReadingTypes/List/Items/lib/query'
import eventTypesQuery from '../../EventTypes/List/Items/lib/query'
import Form from '../Form'
import query from './lib/query'
import mutation from './lib/mutation'
import initialValues from './lib/initialValues'

export default ({
  match: {
    params: { id },
  },
}) => {
  const [mutate, mutationProps] = useMutation(mutation)

  const { loading, error, data } = useQuery(query, {
    variables: {
      id,
    },
  })

  const readingTypesQueryProps = useQuery(readingTypesQuery)
  const eventTypesQueryProps = useQuery(eventTypesQuery)

  if (_.some([readingTypesQueryProps, eventTypesQueryProps], 'loading')) {
    return <Loading />
  }

  if (_.some([readingTypesQueryProps, eventTypesQueryProps], 'error')) {
    return <Error />
  }

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Layout>
      <EditHeader>Edit rule</EditHeader>

      <Form
        initialValues={initialValues({
          rule: data.rule,
          readingTypesQueryProps,
          eventTypesQueryProps,
        })}
        onSubmit={(data) => mutate({ variables: { id, data } })}
      />
      {mutationProps.error && <Error leftAligned />}
      {mutationProps.data && <Redirect to='/rules' />}
    </Layout>
  )
}
