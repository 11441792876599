import React from 'react'
import RangeInput from '../../../../../../Input/Range'
import useCurrentUser from '../../../../../../../hooks/useCurrentUser'
import value from './lib/value'
import valueToPosition from './lib/valueToPosition'
import onDrag from './lib/onDrag'
import formatValue from './lib/formatValue'
import grid from './lib/grid'

export default ({ formikProps }) => {
  const { currentUser } = useCurrentUser()
  if (!currentUser) return null

  const upperValue = value({ formikProps, thresholdName: 'upper' })
  const lowerValue = value({ formikProps, thresholdName: 'lower' })

  return (
    <RangeInput
      upperValue={upperValue}
      lowerValue={lowerValue}
      valueToPosition={valueToPosition}
      onDrag={onDrag({ formikProps, upperValue, lowerValue })}
      grid={grid}
      formatValue={formatValue({ currentUser })}
    />
  )
}
