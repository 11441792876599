import * as yup from 'yup'

const schema = yup.object().shape({
  value: yup
    .number()
    .typeError('You must specify a number.')
    .moreThan(0, 'Must be greater than 0.'),
  unit: yup.string().nullable().min(1, 'Required field'),
})

export default (value) => {
  let error

  try {
    schema.validateSyncAt('value', value)
  } catch(e) {
    error = e.message
  }

  return error
}
