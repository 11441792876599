import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { setLightness } from 'polished'
import { FiX } from 'react-icons/fi'
import ExtendedLink from '../ExtendedLink'

const STATUS_COLOR_MAPPING = {
  none: 'grey',
  online: 'green',
  offline: 'yellow',
  alert: 'reddish',
  [undefined]: 'grey',
}

const backgroundColor = (props) => setLightness(0.9, color(props))

export const color = ({ theme: { colors }, status }) =>
  colors[STATUS_COLOR_MAPPING[status]]

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${backgroundColor};
  padding: 10px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
`

const CloseLink = styled.div`
  display: flex;
  font-weight: 600;
`

const Name = styled.div`
  font-weight: 600;
  color: ${color};
`

const Actions = styled.div`
  font-weight: 600;
  color: ${color};
  display: flex;
  align-items: center;
  flex-shrink: 0;
`

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.colors.dark8Transparent} 0px 3px 3px;
  min-height: 200px;
  border-radius: 8px;
  min-width: 230px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`

export default forwardRef(
  ({ children, status, name, closeLink, actions }, ref) => (
    <Container ref={ref} status={status}>
      <Header status={status}>
        <Name status={status}>{name}</Name>
        <Actions>
          {actions}
          {closeLink && (
            <CloseLink as={ExtendedLink} to={closeLink}>
              <FiX />
            </CloseLink>
          )}
        </Actions>
      </Header>

      {children}
    </Container>
  )
)
