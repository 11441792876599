export default ({
  type: {
    id,
    __typename,
  },
  formikProps: {
    values: {
      scope: {
        scopableId,
        scopable,
      },
    },
  },
}) => (
  id === scopableId && scopable === __typename
)
