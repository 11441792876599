import { gql } from 'graphql.macro'
import PeriodFragment from '../../Periods/lib/PeriodFragment'
import ReadingTypeFragment from '../../ReadingTypes/lib/ReadingTypeFragment'
import ConditionSecondLayerFragment from '../../Conditions/lib/ConditionSecondLayerFragment'

export default gql`
  fragment TermFragment on Term {
    id
    type
    value

    period {
      ...PeriodFragment
    }

    readingType {
      ...ReadingTypeFragment
    }

    condition {
      ...ConditionSecondLayerFragment
    }
  }

  ${PeriodFragment}
  ${ReadingTypeFragment}
  ${ConditionSecondLayerFragment}
`
