import React from 'react'
import styled from 'styled-components'
import duration from './lib/duration'

const Container = styled.div`
  padding-bottom: 20px;
  display: flex;
`

const Label = styled.div`
  padding-right: 5px;
  font-weight: 600;
`

const Value = styled.div``

export default ({ timestamps }) => (
  <Container>
    <Label>Total duration (ms)</Label>
    <Value>{duration({ timestamps })}</Value>
  </Container>
)
