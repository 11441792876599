import { gql } from 'graphql.macro'
import AreaFragment from './AreaFragment'

export default gql`
  {
    areas {
      ...AreaFragment
    }
  }

  ${AreaFragment}
`
