import React from 'react'
import {
  NavLink,
  withRouter,
} from 'react-router-dom'

export default withRouter(({
  to,
  location: {
    search,
  },
  left,
  right,
  staticContext,
  ...rest
}) => (
  <NavLink
    to={{
      pathname: to,
      search,
    }}
    {...rest}
  />
))
