import { gql } from 'graphql.macro'
import UserFragment from '../../../lib/UserFragment'

export default gql`
  {
    users {
      ...UserFragment
    }
  }

  ${UserFragment}
`
