import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding-bottom: 5px;
  display: flex;
`

const Label = styled.div`
  font-weight: 600;
  padding-right: 5px;
`

const Value = styled.div``

export default ({ label, children }) => (
  <Container>
    {label && <Label>{label}</Label>}
    <Value>{children}</Value>
  </Container>
)
