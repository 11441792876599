import { gql } from 'graphql.macro'
import SensorFragment from '../../Sensors/lib/SensorFragment'
import AreaFragment from '../../Areas/lib/AreaFragment'
import LocationFragment from '../../lib/LocationFragment'
import GatewayBaseFragment from './GatewayBaseFragment'
import ManufacturerFragment from '../../Manufacturers/lib/ManufacturerFragment'
import ModelFragment from '../../Models/lib/ModelFragment'

export default gql`
  fragment GatewayFragment on Gateway {
    ...GatewayBaseFragment

    primarySensors: sensors(primary: true) {
      ...SensorFragment
    }

    nonPrimarySensors: sensors(primary: false) {
      ...SensorFragment
    }

    area {
      ...AreaFragment
    }

    location {
      ...LocationFragment
    }

    manufacturer {
      ...ManufacturerFragment
    }

    model {
      ...ModelFragment
    }
  }

  ${GatewayBaseFragment}
  ${SensorFragment}
  ${AreaFragment}
  ${LocationFragment}
  ${ManufacturerFragment}
  ${ModelFragment}
`
