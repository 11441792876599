import _ from 'lodash'
import query from '../query'

export default (
  cache,
  {
    data: {
      deleteAction: { id },
    },
  }
) => {
  const { actions } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { actions: _.reject(actions, { id }) },
  })
}
