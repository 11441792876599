import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import Reading from './Reading'
import LastEvents from './LastEvents'
import Model from './Model'
import Footer from './Footer'
import dynamicReadings from './lib/dynamicReadings'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding: 10px;
`

const Main = styled.div`
  padding: 20px 0;
  display: flex;
  flex-grow: 1;
`

const Data = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`

const ManufacturerLabel = styled.div`
  padding-top: 5px;
  font-size: 12px;
  font-weight: 300;
`

export default ({
  sensor: { lastReadings, manufacturerLabel },
  sensor,
  lastEvents,
}) => (
  <Container>
    {manufacturerLabel && (
      <ManufacturerLabel>{manufacturerLabel}</ManufacturerLabel>
    )}
    <Main>
      <Data>
        {_.map(dynamicReadings(lastReadings), (reading) => (
          <Reading key={reading.id} reading={reading} sensor={sensor} />
        ))}

        <LastEvents lastEvents={lastEvents} />
      </Data>

      {sensor.model && <Model sensor={sensor} />}
    </Main>
    <Footer sensor={sensor} lastReadings={lastReadings} />
  </Container>
)
