import React from 'react'
import styled from 'styled-components'
import { Field } from 'formik'
import BaseContainer from './Container'
import Label from './Label'
import CheckmarkContainer from './CheckmarkContainer'

const Container = styled(BaseContainer)`
  cursor: pointer;
  flex-direction: row;
  align-items: center;
`

const Input = styled.input`
  background-color: ${(props) => props.theme.colors.light5};
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  margin-right: 10px;
  appearance: none;
`

export default ({
  label,
  paddingBottom,
  onClick = ({ form, field }) => form.setFieldValue(field.name, !field.value),
  value = ({ field }) => field.value,
  ...rest
}) => (
  <Field {...rest}>
    {({ field, form }) => (
      <Container
        onClick={() => onClick({ field, form })}
        paddingBottom={paddingBottom}
      >
        <CheckmarkContainer checkmarkVisible={value({ field })}>
          <Input
            type='checkbox'
            checked={value({ field })}
            style={{ padding: '10px' }}
            {...field}
          />
        </CheckmarkContainer>

        <Label>{label}</Label>
      </Container>
    )}
  </Field>
)
