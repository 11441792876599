import isInvertedConfig from '../isInvertedConfig'
import invert from './invert'
import revert from './revert'

export default ({ formikProps, thresholdName, newValue, isInverted }) => {
  if (isInverted && !isInvertedConfig({ formikProps })) {
    return invert({
      formikProps,
      thresholdName,
      newValue,
    })
  }

  if (!isInverted && isInvertedConfig({ formikProps })) {
    return revert({
      formikProps,
      thresholdName,
      newValue,
    })
  }
}
