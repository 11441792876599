import { gql } from 'graphql.macro'
import AreaWithGatewaysFragment from '../../../lib/AreaWithGatewaysFragment'

export default gql`
  {
    areas {
      ...AreaWithGatewaysFragment
    }
  }

  ${AreaWithGatewaysFragment}
`
