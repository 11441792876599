import _ from 'lodash'
import query from './query'

export default (cache, {
  data: {
    deleteManufacturer: {
      id,
    },
  },
}) => {
  const { manufacturers } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { manufacturers: _.reject(manufacturers, { id }) },
  })
}
