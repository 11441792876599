import { gql } from 'graphql.macro'
import AccountFragment from '../../Accounts/lib/AccountFragment'
import SiteFragment from '../../Sites/lib/SiteFragment'

export default  gql`
  fragment UserRoleFragment on UserRole {
    id

    roleId
    role {
      id
      name
    }

    objectable
    objectableId
    object {
      ...AccountFragment
      ...SiteFragment
    }
  }

  ${AccountFragment}
  ${SiteFragment}
`
