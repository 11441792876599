import React from 'react'
import { FiClock } from 'react-icons/fi'
import styled from 'styled-components'
import Tooltip from 'react-tooltip-lite'
import TooltipContent from './TooltipContent'

const Container = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors[props.color || 'grey9']};
  font-weight: 600;
`

const Icon = styled.div`
  font-size: 12px;
  stroke-width: 3px;
`

export default ({
  event,
  tooltipContentLabel = '',
  tooltipContentFromNow = false,
  ...rest
}) => (
  <Tooltip
    content={
      <TooltipContent
        label={tooltipContentLabel}
        fromNow={tooltipContentFromNow}
        event={event}
      />
    }
  >
    <Container {...rest}>
      <Icon as={FiClock} />
    </Container>
  </Tooltip>
)
