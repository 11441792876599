import React from 'react'
import { useQuery } from '@apollo/client'
import Loading from '../Loading'
import Error from '../Error'
import baseQuery from '../Gateways/lib/baseQuery'
import RelationSelectInput from './Relation/Select'

export default (props) => {
  const { loading, error, data } = useQuery(baseQuery)

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <RelationSelectInput
      label='Gateway'
      name='gatewayId'
      options={data.gateways}
      $maxWidth='300px'
      {...props}
    />
  )
}
