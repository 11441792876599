import React from 'react'
import _ from 'lodash'
import Label from '../../../List/Items/Item/Label'

export default (props) => (
  <Label
    imageUrl='model.imageUrl'
    meta={[
      (data) =>
        `${_.get(data, 'manufacturer.name', '')} ${_.get(
          data,
          'model.name',
          ''
        )}`,
      'ipAddress',
      'externalId',
    ]}
    {...props}
  />
)
