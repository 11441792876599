import React from 'react'
import styled from 'styled-components'
import Tooltip from 'react-tooltip-lite'
import CurrentUser from '../../../../../../CurrentUser'
import formattedReadingValue from '../../../../../../../lib/formattedReadingValue'

const Container = styled.div`
  font-size: 14px;
  text-align: ${(props) => props.textAlign};
  display: flex;
  align-items: center;
`

const Value = styled.div``

const Name = styled.div`
  font-size: 12px;
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`

export default ({ align = 'left', name, icon, ...rest }) => (
  <CurrentUser>
    {({ currentUser }) => (
      <Tooltip content={<Name>{name}</Name>}>
        <Container textAlign={align}>
          <Icon as={icon} />

          <Value>{formattedReadingValue({ ...rest, currentUser })}</Value>
        </Container>
      </Tooltip>
    )}
  </CurrentUser>
)
