import { gql } from 'graphql.macro'
import ModelFragment from '../../lib/ModelFragment'

export default gql`
  query Model($id: ID!) {
    model(id: $id) {
      ...ModelFragment
    }
  }

  ${ModelFragment}
`
