import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import Loading from '../../../Loading'
import Error from '../../../Error'
import readingTypesQuery from '../../../ReadingTypes/List/Items/lib/query'
import Description from '../../../Input/Description'
import tokens from './lib/tokens'

const TokensContainer = styled.div`
  padding-top: 10px;
`

const TokenContainer = styled.div`
  padding-bottom: 10px;
`

const Token = styled.span`
  font-family: monospace;
`

export default () => {
  const { loading, error, data } = useQuery(readingTypesQuery)

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Description>
      All fields above can use token interpolation. Available tokens:
      <TokensContainer>
        {_.map(tokens({ data }), ({ token, description }) => (
          <TokenContainer key={token}>
            <Token>{`{{${token}}}`}</Token> - {description}
          </TokenContainer>
        ))}
      </TokensContainer>
    </Description>
  )
}
