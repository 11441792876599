import React from 'react'
import styled from 'styled-components'
import SensorsBroadList from '../../../../Sensors/BroadList'
import TooltipContainer from '../../TooltipContainer'

const Label = styled.div`
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.secondary};
  padding-top: 5px;
  padding-bottom: 50px;
`

export default ({
  gateway: {
    name,
    sensors,
  },
  ...rest
}) => (
  <TooltipContainer
    name={name}
    {...rest}
  >
    <Label>
      Gateway
    </Label>

    <SensorsBroadList sensors={sensors} />
  </TooltipContainer>
)
