import { gql } from 'graphql.macro'
import SensorEditFragment from '../../../lib/SensorEditFragment'

export default gql`
  mutation DeleteSensorHistory($id: ID!) {
    deleteSensorHistory(id: $id) {
      ...SensorEditFragment
    }
  }

  ${SensorEditFragment}
`
