import React, { useState } from 'react'
import styled from 'styled-components'
import { Field } from 'formik'
import ReactFilestack from 'filestack-react'
import LabelContainer from '../LabelContainer'
import Container from '../Container'
import BaseButton from '../../Button'
import Error from '../../Error'
import ImageContainer from '../../ImageContainer'
import onSuccess from './lib/onSuccess'

const Button = styled(BaseButton)`
  margin: 0 auto;
`

const actionOptions = {
  accept: 'image/*',
}

export default ({
  label,
  mutate = () => null,
  mutationProps = {},
  ...rest
}) => {
  const [error, setError] = useState(null)

  return (
    <Container>
      <LabelContainer>{label}</LabelContainer>

      <Field {...rest}>
        {(formikProps) => (
          <ImageContainer center url={formikProps.field.value}>
            <ReactFilestack
              apikey={process.env.REACT_APP_FILESTACK_API_KEY}
              actionOptions={actionOptions}
              onSuccess={onSuccess({ formikProps, mutate })}
              onError={(error) => setError(error)}
              customRender={({ onPick }) => (
                <Button onClick={onPick}>Upload</Button>
              )}
            />
          </ImageContainer>
        )}
      </Field>

      {(error || mutationProps.error) && <Error />}
    </Container>
  )
}
