import styled from 'styled-components'

export default styled.div`
  height: 100px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.white};
  background-image: url('${(props) => props.$imageUrl}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`
