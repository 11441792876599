import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import Loading from '../../../Loading'
import Error from '../../../Error'
import ListGroupedBySiteItems from '../../../List/GroupedBySiteItems'
import query from './lib/query'
import reorderMutation from '../../Edit/lib/mutation'
import deleteMutation from './lib/deleteMutation'
import deleteUpdate from './lib/deleteUpdate'
import Label from './Label'

export default () => {
  const [onDelete, mutationProps] = useMutation(deleteMutation, {
    update: deleteUpdate,
  })

  const { loading, error, data } = useQuery(query)

  if (loading) return <Loading />
  if (error) return <Error />

  const { gateways } = data

  return (
    <>
      <ListGroupedBySiteItems
        items={gateways}
        query={query}
        queryName='gateways'
        reorderMutation={reorderMutation}
        reorderMutationName='updateGateway'
        onDelete={onDelete}
        editUrl={({ id }) => `/gateways/edit/${id}`}
        itemLabel={Label}
        includeSiteNull
      />
      {mutationProps.error && <Error />}
    </>
  )
}
