import { gql } from 'graphql.macro'

export default gql`
  fragment ScopeFragment on Scope {
    id
    scopableId
    scopable
    name
  }
`
