import { gql } from 'graphql.macro'
import EventTypeFragment from '../../EventTypes/lib/EventTypeFragment'
import ModelReadingTypeFragment from '../../ModelReadingTypes/lib/ModelReadingTypeFragment'

export default gql`
  fragment ModelFragment on Model {
    id
    name
    code
    type
    imageUrl

    eventTypes {
      ...EventTypeFragment
    }

    modelReadingTypes {
      ...ModelReadingTypeFragment
    }
  }

  ${EventTypeFragment}
  ${ModelReadingTypeFragment}
`
