import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import _ from 'lodash'
import Unit from './Unit'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint('md')`
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
  `}
`

const Name = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`

export default ({
  data: { readingTypes },
  item: { readingTypeId },
  item,
  formikProps,
  index,
}) => {
  const { name } = _.find(readingTypes, { id: readingTypeId })

  return (
    <Container>
      <Name>{name}</Name>
      <Unit item={item} index={index} formikProps={formikProps} />
    </Container>
  )
}
