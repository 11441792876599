import _ from 'lodash'

export default ({
  formikProps: {
    values: { eventTypeDebounces },
  },
  eventType: { id },
}) => {
  const eventTypeDebounce = _.find(eventTypeDebounces, {
    eventTypeId: id,
  })

  if (!eventTypeDebounce) return ''

  return eventTypeDebounce.periodInSeconds
}
