import { gql } from 'graphql.macro'
import LocationFragment from '../../lib/LocationFragment'

export default gql`
  fragment GatewayBaseFragment on Gateway {
    id
    name
    active
    photoImageUrl
    areaId
    externalId
    manufacturerId
    modelId
    siteId
    index
    ipAddress

    location {
      ...LocationFragment
    }
  }

  ${LocationFragment}
`
