export default ({
  type: {
    id,
    __typename,
    name,
  },
  formikProps: {
    setFieldValue,
  },
}) => () => (
  setFieldValue('scope', {
    scopableId: id,
    scopable: __typename,
    name,
  })
)
