import React from 'react'
import _ from 'lodash'
import PresetSelect from './PresetSelect'
import Additional from './Additional'
import Timestamp from './Timestamp'
import groups from '../../../lib/groups'

export default ({ readingTypesData, eventTypesData, formikProps }) => (
  <>
    <PresetSelect
      formikProps={formikProps}
      readingTypesData={readingTypesData}
      eventTypesData={eventTypesData}
    />

    <Additional
      formikProps={formikProps}
      types={_.flatMap(groups({ readingTypesData, eventTypesData }), 'types')}
    />

    <Timestamp formikProps={formikProps} />
  </>
)
