import { gql } from 'graphql.macro'
import UserFragment from '../../../lib/UserFragment'

export default gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      ...UserFragment
    }
  }

  ${UserFragment}
`
