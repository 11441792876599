import React from 'react'
import Handle from './Handle'

export default ({
  lowerPosition,
  lowerValue,
  upperPosition,
  upperValue,
  ...rest
}) => (
  <>
    <Handle
      thresholdName='lower'
      backgroundColor='green'
      position={lowerPosition}
      value={lowerValue}
      {...rest}
    />
    <Handle
      thresholdName='upper'
      backgroundColor='reddish'
      position={upperPosition}
      value={upperValue}
      {...rest}
    />
  </>
)
