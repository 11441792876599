import { gql } from 'graphql.macro'
import SensorFullFragment from '../../Sensors/lib/SensorFullFragment'
import EventBaseFragment from './EventBaseFragment'
import EventFullFragment from './EventFullFragment'

export default gql`
  fragment EventFullWithSensorLastEventFragment on Event {
    ...EventFullFragment

    sensor {
      ...SensorFullFragment

      lastEvents {
        ...EventBaseFragment
      }
    }
  }

  ${EventFullFragment}
  ${EventBaseFragment}
  ${SensorFullFragment}
`
