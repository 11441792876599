// import moment from 'moment'
import emptyValue from './emptyValue'

export default () => ({
  sensorId: '',
  gatewayId: '',
  manufacturerId: '',
  modelId: '',
  // timestamp: moment.utc().format(),
  db: -10,
  ipAddress: '',
  consumerKey: '',
  consumerSecret: '',
  event: [],
  values: [emptyValue],
})
