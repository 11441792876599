import { gql } from 'graphql.macro'

export default gql`
  fragment ReadingRangeFragment on ReadingRange {
    id
    readingTypeId
    minValue
    maxValue
  }
`
