import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Item from './Item'
import selectableColors from '../../../../../theme/selectableColors'

const Container = styled.div`
  padding-bottom: 60px;
`

const Pill = styled.div`
  display: inline-block;
  font-size: 12px;
  border-radius: 4px;
  margin-right: 10px;
  font-weight: 400;
  background-color: ${(props) => props.theme.colors.lightGrey};
  color: ${(props) => props.theme.colors.black};
  padding: 5px;
`

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: ${(props) =>
    _.isNumber(props.color)
      ? selectableColors[props.color]
      : props.theme.colors.orange5};
  color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  margin-bottom: 10px;

  ${breakpoint('lg')`
    max-width: 50%;
  `}
`

const Name = styled.div`
  padding-left: 5px;
  font-weight: 600;
`

const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 10px;
`

export default ({ group: { area, sensors } }) => (
  <Container>
    <NameContainer color={area?.color}>
      <Pill>Area</Pill>
      <Name>{area?.name || 'Not set'}</Name>
    </NameContainer>

    <ItemsContainer>
      {_.map(sensors, (sensor) => (
        <Item key={sensor.id} sensor={sensor} />
      ))}
    </ItemsContainer>
  </Container>
)
