import _ from 'lodash'

export default ({
  formikProps: {
    values: { scopes },
    setFieldValue,
  },
}) => (getOptionValue) => () => (option) =>
  setFieldValue(
    'scopes',
    _.compact(
      _.concat(
        _.reject(scopes, { scopable: 'Hub' }),
        option.id && {
          scopable: 'Hub',
          scopableId: option.id,
          name: option.name,
        }
      )
    )
  )
