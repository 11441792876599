import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import TextInput from '../../../../Input/Text'
import value from './lib/value'
import onChange from './lib/onChange'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
`

const Pill = styled.div`
  display: inline-block;
  font-size: 12px;
  border-radius: 4px;
  margin-right: 10px;
  font-weight: 400;
  background-color: ${(props) => props.theme.colors.lightGrey};
  color: ${(props) => props.theme.colors.black};
  padding: 5px;
`

export default ({
  eventType: { id, name },
  formikProps: {
    values: { eventTypeDebounces },
  },
  eventType,
  formikProps,
}) => {
  let eventTypeDebounceIndex = _.findIndex(eventTypeDebounces, {
    eventTypeId: id,
  })

  if (eventTypeDebounceIndex === -1) {
    eventTypeDebounceIndex = eventTypeDebounces.length
  }

  return (
    <Container>
      <TextInput
        label={
          <>
            <Pill>Event Type: {name}</Pill> debounce period
          </>
        }
        name={`eventTypeDebounces[${eventTypeDebounceIndex}].periodInSeconds`}
        value={value({ formikProps, eventType })}
        onChange={onChange({ eventType, formikProps, eventTypeDebounceIndex })}
        description='Period in seconds (default - 10s)'
        $maxWidth='100px'
      />
    </Container>
  )
}
