import React from 'react'
import { Field } from 'formik'
import ImageContainer from '../../ImageContainer'
import ImageContainerDot from '../../ImageContainer/Dot'
import Container from '../Container'
import LabelContainer from '../LabelContainer'
import onClick from './lib/onClick'

const Dot = ({
  field: {
    value,
  },
}) => (
  <ImageContainerDot {...value} />
)

export default ({
  label,
  backgroundUrl,
  ...rest
}) => (
  <Container>
    <LabelContainer>
      {label}
    </LabelContainer>

    <Field {...rest}>
      {(formikProps) => (
        <ImageContainer url={backgroundUrl} cursor='crosshair' onClick={onClick(formikProps)}>
          <Dot {...formikProps} />
        </ImageContainer>
      )}
    </Field>
  </Container>
)
