import _ from 'lodash'
import moment from 'moment-timezone'
import { celsiusToFahrenheit } from 'temperature'

const TEMPERATURE_READING_TYPE_NAMES = ['Temperature', 'Ambient temperature']

const formatValue = ({ name, currentUser: { systemOfUnits }, value }) => {
  if (!_.includes(TEMPERATURE_READING_TYPE_NAMES, name)) return value
  if (systemOfUnits === 'metric') return value

  return celsiusToFahrenheit(value)
}

export default ({
  data: {
    sensor: { aggregatedReadings },
  },
  currentUser,
  data,
  name,
}) =>
  _.orderBy(
    _.map(aggregatedReadings, ({ timestamp, value }) => ({
      timestamp: +moment.utc(timestamp),
      value: _.round(formatValue({ value, currentUser, name }), 2),
    })),
    ['timestamp'],
    ['asc']
  )
