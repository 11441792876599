import { gql } from 'graphql.macro'
import FeedItemFragment from '../../../../../FeedItems/lib/FeedItemFragment'

export default gql`
  query FeedItems($filters: [FilterInput!]!, $cursor: ID, $limit: Int) {
    feedItems(filters: $filters, cursor: $cursor, limit: $limit)
      @connection(key: "feedItems", filter: ["filters"]) {
      results {
        ...FeedItemFragment
      }

      cursors {
        after
        before
        hasNext
      }
    }
  }

  ${FeedItemFragment}
`
