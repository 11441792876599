import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import Layout from '../../Layout'
import Loading from '../../Loading'
import Error from '../../Error'
import EditHeader from '../../Edit/Header'
import KeysBroadList from '../../Keys/BroadList'
import GatewaysBroadList from '../../Gateways/BroadList'
import Form from '../Form'
import query from './lib/query'
import mutation from './lib/mutation'
import initialValues from './lib/initialValues'

export default ({
  match: {
    params: { id },
  },
}) => {
  const [mutate, mutationProps] = useMutation(mutation)

  const { loading, error, data } = useQuery(query, {
    variables: {
      id,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Layout>
      <EditHeader>Edit hub</EditHeader>

      <Form
        initialValues={initialValues(data.hub)}
        onSubmit={(data) => mutate({ variables: { id, data } })}
      >
        <KeysBroadList keys={data.hub.keys} />
        <GatewaysBroadList gateways={data.hub.gateways} />
      </Form>
      {mutationProps.error && <Error leftAligned />}
      {mutationProps.data && <Redirect to='/hubs' />}
    </Layout>
  )
}
