import { gql } from 'graphql.macro'
import RuleFragment from '../../../lib/RuleFragment'

export default gql`
  {
    rules {
      ...RuleFragment
    }
  }

  ${RuleFragment}
`
