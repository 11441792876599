import _ from 'lodash'
import event from './event'

export default ({ lastEvents }) => {
  const eventTypes = _.uniqBy(_.flatMap(lastEvents, 'eventTypes'), 'id')

  return _.map(eventTypes, (eventType) => ({
    eventType,
    event: event({ lastEvents, eventType }),
  }))
}
