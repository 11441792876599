import { gql } from 'graphql.macro'
import RuleFragment from '../../lib/RuleFragment'

export default gql`
  mutation UpdateRule($id: ID!, $data: RuleDataInput!) {
    updateRule(id: $id, data: $data) {
      ...RuleFragment
    }
  }

  ${RuleFragment}
`
