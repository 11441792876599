import { gql } from 'graphql.macro'
import TermSecondLayerFragment from '../../Terms/lib/TermSecondLayerFragment'
import PeriodFragment from '../../Periods/lib/PeriodFragment'

export default gql`
  fragment ConditionSecondLayerFragment on Condition {
    id
    comparison

    termOne {
      ...TermSecondLayerFragment
    }

    termTwo {
      ...TermSecondLayerFragment
    }

    period {
      ...PeriodFragment
    }
  }

  ${TermSecondLayerFragment}
  ${PeriodFragment}
`
