import React from 'react'
import TextInput from '../../../Input/Text'
import TextArea from '../../../Input/TextArea'
import Select from '../../../Input/Select'
import validate from './lib/validate'
import pushoverPriorityOptions from './lib/pushoverPriorityOptions'
import pushoverSoundOptions from './lib/pushoverSoundOptions'

export default ({ values: { webhookAuth }, values }) => (
  <>
    <TextInput
      label='Token'
      name='pushoverToken'
      validate={validate('pushoverToken')}
      $maxWidth='400px'
    />

    <TextInput
      label='Pushover User'
      name='pushoverUser'
      description='The user/group key (not e-mail address) of your user (or you), viewable when logged into Pushover dashboard (often referred to as USER_KEY in documentation and code examples).'
      validate={validate('pushoverUser')}
      $maxWidth='400px'
    />

    <TextArea
      label='Message'
      name='pushoverMessage'
      validate={validate('pushoverMessage')}
    />

    <TextInput
      label='Device'
      name='pushoverDevice'
      description="Your user's device name to send the message directly to that device, rather than all of the user's devices (multiple devices may be separated by a comma)."
      isOptional
      $maxWidth='400px'
    />

    <TextInput
      label='Title'
      name='pushoverTitle'
      description="Your message's title, otherwise your app's name is used."
      isOptional
      $maxWidth='400px'
    />

    <TextInput
      label='Url'
      name='pushoverUrl'
      description='A supplementary URL to show with your message.'
      isOptional
    />

    <TextInput
      label='Url Title'
      name='pushoverUrlTitle'
      description='A title for your supplementary URL, otherwise just the URL is shown.'
      isOptional
    />

    <Select
      label='Priority'
      name='pushoverPriority'
      options={pushoverPriorityOptions}
      $maxWidth='300px'
    />

    <Select
      label='Sound'
      name='pushoverSound'
      options={pushoverSoundOptions}
      $maxWidth='200px'
    />
  </>
)
