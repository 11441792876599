import React from 'react'
import styled from 'styled-components'

const Container = styled.span`
  color: ${(props) => props.theme.colors.reddish};
`

export default ({ deleteJob, deleteHistoryJob }) => {
  if (deleteJob) {
    return <Container>Being deleted...</Container>
  }

  if (deleteHistoryJob) {
    return <Container>History is being deleted...</Container>
  }

  return null
}
