import { useSubscription } from '@apollo/client'
import subscription from '../../Feed/Content/List/lib/subscription'
import onSubscriptionData from './lib/onSubscriptionData'
import useSensorFilters from '../../../SensorFilters/useSensorFilters'

export default () => {
  const sensorFiltersProps = useSensorFilters()

  useSubscription(subscription, {
    onSubscriptionData: onSubscriptionData({ sensorFiltersProps }),
  })

  return null
}
