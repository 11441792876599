import { gql } from 'graphql.macro'
import ActionFragment from '../../../../lib/ActionFragment'

export default gql`
  mutation TestAction($id: ID!) {
    testAction(id: $id) {
      ...ActionFragment
    }
  }

  ${ActionFragment}
`
