import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import _ from 'lodash'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import Value from './Value'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint('md')`
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
  `}
`

const Name = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`

export default ({
  data: { readingTypes },
  data,
  item: { readingTypeId },
  item,
  formikProps,
  index,
}) => {
  const { currentUser } = useCurrentUser()

  if (!currentUser) return null

  const { systemOfUnits } = currentUser

  let unitSymbol = ''

  const { name } = _.find(readingTypes, { id: readingTypeId })

  if (name === 'Temperature') {
    unitSymbol = ` (°${systemOfUnits === 'metric' ? 'C' : 'F'})`
  }

  return (
    <Container>
      <Name>{name}</Name>
      <Value
        name='minValue'
        index={index}
        label={`Min value${unitSymbol}`}
        formikProps={formikProps}
        systemOfUnits={systemOfUnits}
      />
      <Value
        name='maxValue'
        index={index}
        label={`Max value${unitSymbol}`}
        formikProps={formikProps}
        systemOfUnits={systemOfUnits}
      />
    </Container>
  )
}
