import _ from 'lodash'

export default ({
  currentUser: {
    userRoles,
  },
}) => (
  _.some(userRoles, {
    objectable: 'Account',
  })
)
