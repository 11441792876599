import _ from 'lodash'
import termOnePath from '../TimeInterval/lib/termOnePath'

const INTERVAL_TYPES = ['timestamp-minute-of-day', 'timestamp-hour-of-day']

export default ({ formikProps: { values }, formikProps }) =>
  _.includes(
    INTERVAL_TYPES,
    _.get(values, `${termOnePath({ formikProps })}.type`)
  )
