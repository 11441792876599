import _ from 'lodash'
import moment from 'moment-timezone'

export default ({ timestamps }) => {
  if (_.isEmpty(timestamps)) return 0

  return moment(_.first(timestamps).timestamp).diff(
    _.last(timestamps).timestamp
  )
}
