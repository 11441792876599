import _ from 'lodash'
import merge from '../../../../../lib/merge'
import sensorsQuery from '../../../Content/SensorsQuery/lib/query'
import passesFilters from './passesFilters'

export default ({
  sensorFiltersProps,
  sensorFiltersProps: { filters },
  client,
  feedItemAdded: {
    item: { sensor },
  },
  feedItemAdded,
}) => {
  if (!passesFilters({ sensorFiltersProps, feedItemAdded })) return
  if (!sensor) return

  let prev

  try {
    prev = client.readQuery({
      query: sensorsQuery,
      variables: { filters },
    })
  } catch (e) {}

  if (!prev) return

  const { id } = sensor

  if (_.some(prev.sensors, { id })) return

  client.writeQuery({
    query: sensorsQuery,
    variables: { filters },
    data: merge({}, prev, {
      sensors: [sensor, ...prev.sensors],
    }),
  })
}
