import _ from 'lodash'
import arrayMove from 'array-move'
import insert from './insert'
import order from './order'

export default ({
  reorderMutationName,
  query,
  queryName,
}) => (cache, {
  data: {
    [reorderMutationName]: {
      id,
      index,
      siteId,
    },
    [reorderMutationName]: item,
  },
}) => {
  const { [queryName]: items } = cache.readQuery({ query })
  const siteItems = order(_.filter(items, { siteId }))

  const sourceIndex = _.findIndex(siteItems, { id })
  let updatedRecords

  if (sourceIndex === -1) {
    if (_.isEmpty(siteItems)) {
      updatedRecords = [item]
    } else {
      updatedRecords = insert(siteItems, item, index)
    }
  } else {
    updatedRecords = arrayMove(siteItems, sourceIndex, index)
  }

  // const result = _.map(updatedRecords, (record, newIndex) => ({
  //   ...record,
  //   index: newIndex,
  // }))

  const otherSiteItems = _.reject(items, { siteId })

  cache.writeQuery({
    query,
    data: {
      [queryName]: _.concat(updatedRecords, otherSiteItems),
    },
  })
}
