import { gql } from 'graphql.macro'
import TermFragment from '../../Terms/lib/TermFragment'
import PeriodFragment from '../../Periods/lib/PeriodFragment'

export default gql`
  fragment ConditionFragment on Condition {
    id
    comparison

    termOne {
      ...TermFragment
    }

    termTwo {
      ...TermFragment
    }

    period {
      ...PeriodFragment
    }
  }

  ${TermFragment}
  ${PeriodFragment}
`
