import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import onClick from './lib/onClick'

const Container = styled.div`
  padding: 5px;
  background-color: ${(props) =>
    props.theme.colors[props.active ? 'black' : 'lightGrey']};
  color: ${(props) => props.theme.colors[props.active ? 'white' : 'black']};
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;

  ${breakpoint('md')`
    margin-left: 5px;
    margin-right: 0;
  `}
`

export default ({
  type,
  formikProps: {
    values: { resolution },
  },
  formikProps,
  children,
}) => (
  <Container
    active={resolution === type}
    onClick={onClick({ formikProps, type })}
  >
    {children}
  </Container>
)
