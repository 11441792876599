import React from 'react'
import _ from 'lodash'
import { Formik, Form } from 'formik'
import { useQuery } from '@apollo/client'
import Loading from '../../Loading'
import Error from '../../Error'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import TextInput from '../../Input/Text'
import Checkbox from '../../Input/Checkbox'
import ImageInput from '../../Input/Image'
import ColorInput from '../../Input/Color'
import RelationMultiSelect from '../../Input/Relation/MultiSelect'
import ImageLocationInput from '../../Input/ImageLocation'
import ManufacturerInput from '../../Input/Manufacturer'
import GatewayInput from '../../Input/Gateway'
import ModelInput from '../../Input/Model'
import gatewaysQuery from '../../Gateways/List/Items/lib/query'
import areasQuery from '../../Areas/List/Items/lib/query'
import manufacturersQuery from '../../Manufacturers/List/Items/lib/query'
import modelsQuery from '../../Models/List/Items/lib/query'
import SensorEventTypeDebouncesInput from './SensorEventTypeDebouncesInput'
import SensorRangesInput from './SensorRangesInput'
import SensorOffsetsInput from './SensorOffsetsInput'
import serializeOptions from '../lib/serializeOptions'
import locationBackgroundUrl from './lib/locationBackgroundUrl'
import validationSchema from './lib/validationSchema'
import colorOptions from './lib/colorOptions'

export default ({
  children,
  photoImageUrlMutate = () => null,
  photoImageUrlMutationProps = () => null,
  ...rest
}) => {
  const modelsQueryProps = useQuery(modelsQuery)
  const manufacturersQueryProps = useQuery(manufacturersQuery)
  const gatewaysQueryProps = useQuery(gatewaysQuery)
  const areasQueryProps = useQuery(areasQuery)

  const queryProps = [
    modelsQueryProps,
    manufacturersQueryProps,
    gatewaysQueryProps,
    areasQueryProps,
  ]

  if (_.some(queryProps, 'loading')) return <Loading />
  if (_.some(queryProps, 'error')) return <Error />

  return (
    <Formik validationSchema={validationSchema} {...rest}>
      {(formikProps) => {
        const { values } = formikProps

        return (
          <Form>
            <FormContainer>
              <TextInput label='Name' name='name' $maxWidth='300px' />
              <TextInput
                label='Manufacturer Label'
                name='manufacturerLabel'
                $maxWidth='300px'
              />
              <Checkbox label='Active' name='active' />
              <ColorInput
                label='Color'
                name='color'
                options={colorOptions({
                  values,
                  modelsQueryProps,
                  manufacturersQueryProps,
                })}
              />
              <TextInput
                label='External ID'
                name='externalId'
                $maxWidth='300px'
              />

              <GatewayInput label='Primary Gateway' name='primaryGatewayId' />

              <RelationMultiSelect
                label='All connected Gateways'
                name='gateways'
                options={serializeOptions(gatewaysQueryProps.data.gateways)}
                maxWidth='100%'
              />

              <ManufacturerInput />
              <ModelInput type='sensor' />

              <ImageInput
                label='Photo'
                name='photoImageUrl'
                mutate={photoImageUrlMutate}
                mutationProps={photoImageUrlMutationProps}
              />

              <ImageLocationInput
                label='Location'
                name='location'
                backgroundUrl={locationBackgroundUrl(
                  { ...areasQueryProps.data, ...gatewaysQueryProps.data },
                  values
                )}
              />

              <SensorEventTypeDebouncesInput formikProps={formikProps} />
              <SensorRangesInput formikProps={formikProps} />
              <SensorOffsetsInput formikProps={formikProps} />

              {children}

              <FormButton />
            </FormContainer>
          </Form>
        )
      }}
    </Formik>
  )
}
