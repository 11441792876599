import React from 'react'
import styled from 'styled-components'
import { ReactComponent as SenseCookie } from '../../../../../../../../../../assets/devices/sense-cookie.svg'
import selectableColors from '../../../../../../../../../../theme/selectableColors'
import Container from '../Container'

const Inner = styled.div`
  height: 40px;
`

export default ({
  sensor: {
    color,
    model: { imageUrl },
  },
}) => (
  <Container as='div'>
    <Inner as={SenseCookie} fill={selectableColors[color]} />
  </Container>
)
