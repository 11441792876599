import React from 'react'
import styled from 'styled-components'
import Moment from 'react-moment'

const Label = styled.div`
  font-size: 12px;
  padding-right: 5px;
`

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.grey1};
  font-size: 12px;
  display: flex;
  align-items: center;
`

const format = 'YYYY-MM-DD HH:mm:ss'

export default ({ event: { timestamp }, label, fromNow }) => (
  <Container>
    {label && <Label>{label}</Label>}
    <Moment fromNow={fromNow} {...(fromNow ? {} : { format })}>
      {timestamp}
    </Moment>
  </Container>
)
