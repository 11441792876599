import isInvertedConfig from '../Input/lib/isInvertedConfig'

export default ({
  formikProps: {
    values: { condition },
    values,
  },
  formikProps,
}) => {
  if (condition?.comparison !== 'and') return false
  if (isInvertedConfig({ formikProps })) return true
  if (condition?.termTwo?.condition?.comparison !== 'and') return false
  if (
    condition?.termTwo?.condition?.termOne?.condition?.termOne?.type ===
    'timestamp'
  ) {
    return true
  }

  return false
}
