import { gql } from 'graphql.macro'
import SiteFragment from '../../../lib/SiteFragment'

export default gql`
  {
    sites {
      ...SiteFragment
    }
  }

  ${SiteFragment}
`
