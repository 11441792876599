import withSymbol from './withSymbol'
import celsius from './celsius'
import fraction from './fraction'

export default {
  fahrenheit: withSymbol,
  celsius,
  percent: withSymbol,
  fraction,
}
