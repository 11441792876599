import _ from 'lodash'
import query from '../../List/Items/lib/query'

export default (cache, {
  data: {
    createHub,
  },
}) => {
  const { hubs } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { hubs: _.concat(hubs, createHub) },
  })
}
