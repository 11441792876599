import React from 'react'
import styled from 'styled-components'
import Image from './Image'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const NamesContainer = styled.div``

const Name = styled.div`
  font-size: 12px;
`

export default ({
  sensor: {
    manufacturerLabel,
    model: { name },
  },
  sensor,
}) => (
  <Container>
    <Image sensor={sensor} />
    <NamesContainer>
      <Name>{name}</Name>
    </NamesContainer>
  </Container>
)
