import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;

  ${breakpoint('md')`
    padding: ${(props) => props.padding};
  `}
`

export const InnerContainer = styled.div`
  border-radius: 8px;
  flex-grow: 1;
  max-width: ${(props) => props.maxWidth};
  background-color: ${(props) => props.theme.colors.white};
  padding: ${(props) => props.padding};
  padding-bottom: 20px;
  box-shadow: ${(props) => props.theme.colors.dark8Transparent} 0px 3px 3px;
  margin: ${(props) => props.margin};
  display: flex;
  flex-direction: column;
`

export default ({ maxWidth = '768px', outerPadding = '20px', ...rest }) => (
  <Container padding={outerPadding}>
    <InnerContainer maxWidth={maxWidth} {...rest} />
  </Container>
)
