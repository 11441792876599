import React from 'react'
import _ from 'lodash'
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
} from 'recharts'
import moment from 'moment-timezone'
import styled from 'styled-components'
import useCurrentUser from '../../../../../../../hooks/useCurrentUser'
import Tick from './Tick'
import serialize from './lib/serialize'
import tickFormatter from './lib/tickFormatter'
import labelFormatter from './lib/labelFormatter'

const TEMPERATURE_READING_TYPE_NAMES = ['Temperature', 'Ambient temperature']

const getName = ({ name, currentUser: { systemOfUnits } }) => {
  if (!_.includes(TEMPERATURE_READING_TYPE_NAMES, name)) return name

  if (systemOfUnits === 'metric') {
    return `${name} (°C)`
  }

  return `${name} (°F)`
}

const Container = styled.div`
  display: flex;
`

const ChildContainer = styled.div`
  width: 0;
  flex-grow: 1;
`

export default ({
  data,
  formikProps: {
    values: {
      startDate,
      endDate,
      scope: { name },
    },
  },
}) => {
  const { currentUser } = useCurrentUser()
  if (!currentUser) return null

  return (
    <Container>
      <ChildContainer>
        <ResponsiveContainer width='100%' height={300}>
          <AreaChart
            data={serialize({ data, currentUser, name })}
            margin={{ top: 10, bottom: 10 }}
          >
            <XAxis
              dataKey='timestamp'
              type='number'
              domain={[+moment(startDate).local(), +moment(endDate).local()]}
              tick={<Tick formatter={tickFormatter} dy={10} />}
            />

            <YAxis
              tick={<Tick dx={-10} dy={5} />}
              domain={['dataMin - 1', 'dataMax + 1']}
            />

            <Tooltip
              labelFormatter={labelFormatter}
              formatter={(value) => [value, getName({ currentUser, name })]}
            />

            <Area
              type='monotone'
              dataKey='value'
              stroke='#8884d8'
              fill='#8884d8'
            />
          </AreaChart>
        </ResponsiveContainer>
      </ChildContainer>
    </Container>
  )
}
