import React, { Fragment } from 'react'
import _ from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import Note from '../../Note'
import Item from '../Items/Item'
import onDragEnd from './lib/onDragEnd'
import type from './lib/type'
import filter from './lib/filter'
import keyByGroupKey from './lib/keyByGroupKey'

const Container = styled.div`
  width: 100%;
`

export default ({
  groups,
  onReorder,
  groupKey,
  groupLabel = null,
  groupDroppableId,
  groupIsDropDisabled = () => false,
  itemIsDragDisabled = () => false,
  isDroppableBetweenGroups = true,
  hideEmptyGroups,
  ...rest
}) => {
  if (_.isEmpty(groups) || _.every(groups, ({ items }) => _.isEmpty(items))) {
    return <Note>No records yet.</Note>
  }

  if (!onReorder) {
    return (
      <>
        {_.map(groups, (group) => (
          <Fragment key={keyByGroupKey({ group, groupKey })}>
            {groupLabel && groupLabel({ group })}

            {_.isEmpty(group.items) && <Note>No records yet.</Note>}
            {_.map(_.sortBy(group.items, 'name'), (item, index) => (
              <Item key={item.id} item={item} {...rest} />
            ))}
          </Fragment>
        ))}
      </>
    )
  }

  return (
    <DragDropContext onDragEnd={onDragEnd({ onReorder })}>
      {_.map(filter({ groups, hideEmptyGroups }), (group) => (
        <Fragment key={keyByGroupKey({ group, groupKey })}>
          {groupLabel && groupLabel({ group })}

          <Droppable
            droppableId={groupDroppableId({ group })}
            isDropDisabled={groupIsDropDisabled({ group })}
            type={type({ isDroppableBetweenGroups, group, groupKey })}
          >
            {(provided, snapshot) => (
              <Container ref={provided.innerRef}>
                {_.isEmpty(group.items) && <Note>No records yet.</Note>}

                {_.map(group.items, (item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={itemIsDragDisabled({ item })}
                    type={type({ isDroppableBetweenGroups, group })}
                  >
                    {(provided, snapshot) => (
                      <Item
                        key={item.id}
                        innerRef={provided.innerRef}
                        provided={provided}
                        onReorder={onReorder}
                        item={item}
                        isDragDisabled={itemIsDragDisabled({ item })}
                        {...rest}
                      />
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}
              </Container>
            )}
          </Droppable>
        </Fragment>
      ))}
    </DragDropContext>
  )
}
