import * as yup from 'yup'

const eventTypeDebounce = yup.object().shape({
  eventTypeId: yup.number().required('Required field'),
  periodInSeconds: yup
    .number()
    .typeError('Must be a number')
    .required('Required field'),
})

const readingOffset = yup.object().shape({
  readingTypeId: yup.number().required('Required field'),
  offset: yup.number().typeError('Must be a number').required('Required field'),
})

export default yup.object().shape({
  name: yup.string().min(1).required('Required field'),
  externalId: yup.string().min(1).required('Required field'),
  gateways: yup
    .array()
    .min(1, 'Must select at least one Gateway')
    .required('Required field'),
  eventTypeDebounces: yup.array().of(eventTypeDebounce),
  readingOffsets: yup.array().of(readingOffset),
})
