import _ from 'lodash'
import order from './order'

const OMITTED_READING_TYPE_NAMES = [
  'Signal Strength',
  'Voltage',
  'IP Address',
  'Door',
  'Motion',
]

export default (readings) =>
  _.orderBy(
    _.reject(readings, ({ readingType: { name } }) =>
      _.includes(OMITTED_READING_TYPE_NAMES, name)
    ),
    [order, 'readingType.name'],
    ['asc', 'asc']
  )
