import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import formattedReadingValue from '../../../../../../../lib/formattedReadingValue'
import CurrentUser from '../../../../../../CurrentUser'
import Row from '../Row'
import formattedTimestamp from '../lib/formattedTimestamp'
import Data from './Data'
import Timestamps from './Timestamps'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export default ({
  event: {
    id,
    timestamp,
    data,
    sensor: { id: sensorId, name },
    sensor,
    readings,
    eventTypes,
  },
}) => (
  <>
    <Row label='Event ID' value={id} />
    <Row label='Event' value={JSON.stringify(_.map(eventTypes, 'name'))} />
    <Row label='Sensor' value={name} href={`/sensors/edit/${sensorId}`} />

    <CurrentUser>
      {({ currentUser }) => (
        <>
          {_.map(readings, (reading) => (
            <Row
              key={reading.id}
              label={reading.readingType.name}
              value={formattedReadingValue({
                reading,
                sensor,
                currentUser,
              })}
            />
          ))}
        </>
      )}
    </CurrentUser>

    <Row label='Timestamp' value={formattedTimestamp({ timestamp })} />

    <Container>
      <Timestamps eventId={id} />
      <Data data={data} />
    </Container>
  </>
)
