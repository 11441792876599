import styled from 'styled-components'

const padding = ({ withBackground }) => (
  withBackground ? '10px' : '40px 0'
)

const borderRadius = ({ withBackground }) => (
  withBackground ? '4px' : 0
)

export default styled.div`
  color: ${props => props.color || props.theme.colors.grey};
  background-color: ${props => props.withBackground && props.theme.colors.white};
  font-weight: 600;
  width: 100%;
  text-align: ${props => props.leftAligned ? 'left' : 'center'};
  width: 100%;
  padding: ${padding};
  border-radius: ${borderRadius};
`
