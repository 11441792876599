import React from 'react'
import { useQuery } from '@apollo/client'
import { FieldArray } from 'formik'
import Loading from '../../../Loading'
import Error from '../../../Error'
import EditList from '../../../Edit/List'
import readingTypesQuery from '../../../ReadingTypes/List/Items/lib/query'
import AddButton from './AddButton'
import Label from './Label'
import onDelete from './lib/onDelete'

export default ({
  formikProps: {
    values: { modelReadingTypes },
    values,
  },
  formikProps,
  actions,
}) => {
  const { loading, error, data } = useQuery(readingTypesQuery)

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <FieldArray
      name='modelReadingTypes'
      render={(arrayHelpers) => (
        <EditList
          header='Reading types'
          addButton={
            <AddButton
              data={data}
              values={values}
              arrayHelpers={arrayHelpers}
            />
          }
          label={({ item, index }) => (
            <Label
              item={item}
              index={index}
              data={data}
              formikProps={formikProps}
            />
          )}
          items={modelReadingTypes}
          onDelete={onDelete({ arrayHelpers, values })}
        />
      )}
    />
  )
}
