import { gql } from 'graphql.macro'
import AccountFragment from '../../../lib/AccountFragment'

export default gql`
  {
    accounts {
      ...AccountFragment
    }
  }

  ${AccountFragment}
`
