import { gql } from 'graphql.macro'
import ActionFragment from '../../lib/ActionFragment'

export default gql`
  mutation UpdateAction($id: ID!, $data: ActionDataInput!) {
    updateAction(id: $id, data: $data) {
      ...ActionFragment
    }
  }

  ${ActionFragment}
`
