import React, { useState } from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Content from './Content'
import Feed from './Feed'
import FeedMenu from './Feed/Menu'
import FeedContent from './Feed/Content'
import Subscriptions from './Subscriptions'
import SensorFiltersProvider from '../SensorFilters/Provider'
import SensorFiltersInputButton from '../SensorFilters/Input/Button'
import SensorFiltersInputContent from '../SensorFilters/Input/Content'

const Container = styled.div`
  ${breakpoint('md')`
    padding: 20px 0 20px 20px;
  `}
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${breakpoint('md')`
    flex-direction: row;
  `}
`

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 0;

  ${breakpoint('md')`
    padding: 0 0 20px;
  `}
`

const TopFeedButtonContainer = styled.div`
  display: flex;

  ${breakpoint('md')`
    display: none;
  `}
`

const SensorFiltersInputContentContainer = styled.div`
  padding: 10px;
`

const TopFeedContentContainer = styled.div`
  ${breakpoint('md')`
    display: none;
  `}
`

const RightFeedContainer = styled.div`
  display: none;

  ${breakpoint('md')`
    display: flex;
  `}
`

export default (props) => {
  const [sensorFiltersIsOpen, setSensorFiltersIsOpen] = useState(false)
  const [feedIsOpen, setFeedIsOpen] = useState(false)

  return (
    <SensorFiltersProvider>
      <Container>
        <Subscriptions />
        <TopContainer>
          <SensorFiltersInputButton
            isOpen={sensorFiltersIsOpen}
            setIsOpen={setSensorFiltersIsOpen}
          />
          <TopFeedButtonContainer>
            <FeedMenu isOpen={feedIsOpen} setIsOpen={setFeedIsOpen} />
          </TopFeedButtonContainer>
        </TopContainer>

        {sensorFiltersIsOpen && (
          <SensorFiltersInputContentContainer>
            <SensorFiltersInputContent backgroundColor='white' />
          </SensorFiltersInputContentContainer>
        )}

        {feedIsOpen && (
          <TopFeedContentContainer>
            <FeedContent setIsOpen={setFeedIsOpen} />
          </TopFeedContentContainer>
        )}

        <InnerContainer>
          <Content />
          <RightFeedContainer>
            <Feed isOpen={feedIsOpen} setIsOpen={setFeedIsOpen} />
          </RightFeedContainer>
        </InnerContainer>
      </Container>
    </SensorFiltersProvider>
  )
}
