import styled from 'styled-components'
import _ from 'lodash'

export default styled.div`
  padding-bottom: ${(props) =>
    _.isUndefined(props.$paddingBottom) ? '20px' : props.$paddingBottom};
  padding-right: ${(props) => props.$paddingRight};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${(props) =>
    props.theme.colors[props.$backgroundColor] || 'none'};
`
