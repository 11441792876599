import * as yup from 'yup'

export default (value) => {
  let error

  try {
    yup
      .boolean()
      .typeError('You must specify true/false.')
      .required('Required field')
      .validateSync(value)
  } catch(e) {
    error = e.message
  }

  return error
}
