import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import UnitInput from '../../../../../Input/Unit'

const Container = styled.div`
  display: flex;
  padding-top: 10px;
  margin-bottom: -20px;
  flex-grow: 1;

  ${breakpoint('md')`
    padding-top: 0;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
    max-width: 200px;
  `}
`

export default ({ item: { unitId }, item, index, formikProps }) => (
  <Container>
    <UnitInput name={`modelReadingTypes[${index}].unitId`} />
  </Container>
)
