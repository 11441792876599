import React from 'react'
import Plain from '../../../Advanced/Condition/Term/Plain'
import Unit from './Unit'
import termTwoPath from './lib/termTwoPath'

export default ({ formikProps }) => (
  <>
    <Plain
      label='Interval'
      name={`${termTwoPath({ formikProps })}.value`}
      description='(shortest interval is 1 minute)'
    />
    <Unit formikProps={formikProps} />
  </>
)
