import _ from 'lodash'

export default ({
  serializedDatas,
  ...filter
}) => (
  _.max(_.flatMap(serializedDatas, ({
    data,
  }) => (
    _.map(data, ({ events }) => _.filter(events, filter).length)
  )))
)
