import { gql } from 'graphql.macro'
import EventTypeFragment from '../../../lib/EventTypeFragment'

export default gql`
  {
    eventTypes {
      ...EventTypeFragment
    }
  }

  ${EventTypeFragment}
`
