export default [
  {
    value: 'imperial',
    label: 'Imperial (°F)',
  },
  {
    value: 'metric',
    label: 'Metric (°C)',
  },
]
