import React from 'react'
import styled from 'styled-components'
import ValueContainer from '../ValueContainer'
import Time from './Time'
import Icon from './Icon'
import CurrentUser from '../../../../../../../CurrentUser'
import formattedReadingValue from '../../../../../../../../lib/formattedReadingValue'
import color from './lib/color'

const Content = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors[props.$color]};
`

const Value = styled.div`
  font-size: 24px;
  font-weight: 800;
  padding: 0 10px;
`

export default ({
  reading: {
    value,
    readingType: { name },
    readingType,
    event,
  },
  reading,
  sensor,
}) => (
  <CurrentUser>
    {({ currentUser }) => (
      <ValueContainer type={readingType} collection='readingTypes'>
        <Content $color={color({ reading, sensor })}>
          <Icon reading={reading} sensor={sensor} />
          <Value>
            {formattedReadingValue({ reading, sensor, currentUser })}
          </Value>
        </Content>
        <Time event={event} />
      </ValueContainer>
    )}
  </CurrentUser>
)
