import _ from 'lodash'
import query from '../../List/Items/lib/query'

export default (cache, {
  data: {
    createArea,
  },
}) => {
  const { areas } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { areas: _.concat(areas, createArea) },
  })
}
