import _ from 'lodash'

export default ({ sensorFiltersProps: { filters }, feedItemAdded }) => {
  const filter = _.find(filters, { type: 'Area' })

  if (!filter) return true
  if (!feedItemAdded.item.sensor) return true
  if (!feedItemAdded.item.sensor.gateways) return true

  return _.includes(
    _.map(feedItemAdded.item.sensor.gateways, 'areaId'),
    filter.id
  )
}
