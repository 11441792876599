import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const Container = styled.div`
  font-size: 14px;
  margin: 0;
  padding: 5px;
  border-radius: 4px;
  font-weight: 600;
  color: ${(props) => props.theme.colors[props.color || 'light2']};
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${(props) =>
    props.theme.colors[props.backgroundColor || 'transparent']};
  border: ${(props) => props.theme.borders[props.border || 'none']};

  :hover {
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.transparentGrey};
  }

  ${breakpoint('md')`
    margin: 0 5px;
  `}
`

const Icon = styled.div`
  padding-right: ${(props) => props.$paddingRight};
  flex-shrink: 0;
`

export default ({ icon, children, iconPaddingRight = '5px', to, ...rest }) => (
  <Container
    as={to ? NavLink : 'div'}
    activeStyle={{
      color: 'black',
    }}
    to={to}
    {...rest}
  >
    <Icon as={icon} $paddingRight={iconPaddingRight} /> {children}
  </Container>
)
