import { gql } from 'graphql.macro'
import HubFragment from '../../../lib/HubFragment'

export default gql`
  mutation DeleteHub($id: ID!) {
    deleteHub(id: $id) {
      ...HubFragment
    }
  }

  ${HubFragment}
`
