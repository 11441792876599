import _ from 'lodash'
import moment from 'moment-timezone'

export default ({ lastEvents, eventType }) =>
  _.last(
    _.sortBy(
      _.filter(lastEvents, ({ eventTypes }) =>
        _.some(eventTypes, { id: eventType.id })
      ),
      ({ timestamp }) => +moment(timestamp)
    )
  )
