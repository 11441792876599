import _ from 'lodash'
import query from '../query'

export default (
  cache,
  {
    data: {
      deleteRule: { id },
    },
  }
) => {
  const { rules } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { rules: _.reject(rules, { id }) },
  })
}
