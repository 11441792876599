import _ from 'lodash'

export default (scopes) => (
  _.map(scopes, ({
    scopable,
    scopableId,
    name,
  }) => ({
    scopable,
    scopableId,
    name,
  }))
)
