import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Layout from '../../Layout'
import Heading from '../../Heading'
import Button from '../../Button'
import ListHeader from '../../List/Header'
import SensorFiltersProvider from '../../SensorFilters/Provider'
import SensorFiltersInput from '../../SensorFilters/Input'
import Items from './Items'
import availableFilters from './lib/availableFilters'

const FiltersContainer = styled.div`
  padding: 0 10px;

  ${breakpoint('md')`
    padding: 0 40px;
  `}
`

export default () => (
  <SensorFiltersProvider availableFilters={availableFilters}>
    <Layout>
      <ListHeader>
        <Heading>Sensors</Heading>
        <Button $primary as={Link} to='/sensors/new'>
          New Sensor
        </Button>
      </ListHeader>
      <FiltersContainer>
        <SensorFiltersInput />
      </FiltersContainer>

      <Items />
    </Layout>
  </SensorFiltersProvider>
)
