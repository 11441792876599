const descriptions = {
  'voltage-below-threshold': 'Range of 1.0-3.5',
  'voltage-above-threshold': 'Range of 1.0-3.5',
}

export default ({
  formikProps: {
    values: { slug, condition },
  },
}) => descriptions[slug]
