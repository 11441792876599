import { gql } from 'graphql.macro'
import GatewayFragment from '../../lib/GatewayFragment'

export default gql`
  mutation UpdateGateway($id: ID!, $data: GatewayDataInput!) {
    updateGateway(id: $id, data: $data) {
      ...GatewayFragment
    }
  }

  ${GatewayFragment}
`
