import React from 'react'
import Select from '../../Select'
import validate from './lib/validate'
import options from './lib/options'

export default (props) => (
  <Select
    validate={validate}
    options={options}
    {...props}
  />
)
