import value from '../value'
import nest from './nest'
import unnest from './unnest'

export default ({ formikProps: { values, setValues }, formikProps }) => {
  if (value({ formikProps })) {
    setValues({
      ...values,
      condition: unnest({ values }),
    })
  } else {
    setValues({
      ...values,
      condition: nest({ values }),
    })
  }
}
