import _ from 'lodash'
import moment from 'moment-timezone'
import { mix } from 'polished'
import colors from '../../../../../../../../../theme/colors'

export default ({
  entry: { events, datetime },
  maxEventsCount,
  maxTriggersCount,
}) => {
  if (moment(datetime).isAfter(moment())) return colors.transparentGrey3

  let result = colors.lightGrey

  if (maxEventsCount > 0) {
    const eventsRatio =
      _.filter(events, { type: 'event' }).length / maxEventsCount

    result = mix(eventsRatio, colors.blue, result)
  }

  if (maxTriggersCount > 0) {
    const triggersRatio =
      _.filter(events, { type: 'trigger' }).length / maxTriggersCount

    result = mix(triggersRatio, colors.reddish, result)
  }

  return result
}
