import React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import _ from 'lodash'

const dateFormat = 'dddd YYYY-MM-DD'
const timeFormat = 'HH:mm:ss'

const Container = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.colors.dark8Transparent} 0px 3px 3px;
  min-width: 200px;
`

const Date = styled.div`
  font-weight: 600;
`

const InnerContainer = styled.div`
  padding-top: 10px;
`

const NoEvents = styled.div``

const Event = styled.div`
  display: flex;
`

const Period = styled.div`
  font-size: 14px;
`

const EventDateTime = styled.div``

const Type = styled.div`
  padding-right: 5px;
`

const EventsCount = styled.div`
  font-size: 14px;
`

const LastEvents = styled.div`
  font-size: 14px;
  padding-top: 10px;
`

const LastEventsText = styled.div`
  padding-right: 5px;
`

export default ({ active, ...rest }) => {
  if (!active) return null

  const { datetime, events } = _.get(rest, 'payload[0].payload')

  const firstLength = 6
  const sortedEvents = _.sortBy(events, 'timestamp')
  const firstEvents = _.take(sortedEvents, firstLength)
  const lastEvents = _.slice(sortedEvents, firstLength)
  const lastEventEvents = _.filter(lastEvents, { type: 'event' })
  const lastTriggerEvents = _.filter(lastEvents, { type: 'trigger' })

  return (
    <Container>
      <Date>
        {moment(datetime).local().format(dateFormat)}
        <Period>
          {moment(datetime).local().format(timeFormat)} -{' '}
          {moment(datetime).local().add(1, 'hour').format(timeFormat)}
        </Period>
      </Date>

      <InnerContainer>
        {_.isEmpty(events) && <NoEvents>No events.</NoEvents>}

        {!_.isEmpty(events) && (
          <EventsCount>{events.length} events:</EventsCount>
        )}

        {_.map(firstEvents, ({ type, timestamp }, index) => (
          <Event key={index}>
            <Type>{_.capitalize(type)}</Type>
            <EventDateTime>
              ({moment(timestamp).local().format(timeFormat)}, local{' '}
              {moment().local().format('Z')})
            </EventDateTime>
          </Event>
        ))}

        {!_.isEmpty(lastEvents) && (
          <LastEvents>
            {!_.isEmpty(lastEventEvents) && (
              <LastEventsText>
                {lastEventEvents.length} more events.
              </LastEventsText>
            )}
            {!_.isEmpty(lastTriggerEvents) && (
              <LastEventsText>
                {lastTriggerEvents.length} more triggers.
              </LastEventsText>
            )}
          </LastEvents>
        )}
      </InnerContainer>
    </Container>
  )
}
