import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import Moment from 'react-moment'
import ValueContainer from '../ValueContainer'
import Icon from '../Icon'
import Time from '../Time'
import isOutdated from '../lib/isOutdated'
import byEventType from './lib/byEventType'
import filter from './lib/filter'

const Container = styled.div`
  display: flex;
`

const Content = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.div`
  font-size: 14px;
  padding: 0 10px;
  font-weight: 500;
  color: ${(props) =>
    props.theme.colors[props.isOutdated ? 'grey9' : 'reddish']};
`

export default ({ lastEvents }) => (
  <Container>
    {_.map(
      filter(byEventType({ lastEvents })),
      ({ eventType: { name }, eventType, event }) => (
        <ValueContainer
          key={`${eventType.id}-${event.id}`}
          type={eventType}
          collection='eventTypes'
        >
          <Icon type={eventType} collection='eventTypes' />
          <Content>
            <Label isOutdated={isOutdated({ event })}>
              <Moment fromNow ago>
                {event.timestamp}
              </Moment>
            </Label>
            <Time
              event={event}
              color={isOutdated({ event }) ? 'grey9' : 'reddish'}
            />
          </Content>
        </ValueContainer>
      )
    )}
  </Container>
)
