import React from 'react'
import { Formik } from 'formik'
import initialValues from './lib/initialValues'
import filters from './lib/filters'
import defaultAvailableFilters from './lib/defaultAvailableFilters'
import Context from '../Context'

export default ({ children, availableFilters = defaultAvailableFilters }) => (
  <Formik initialValues={initialValues({ availableFilters })}>
    {(formikProps) => (
      <Context.Provider
        value={{
          formikProps,
          filters: filters({ formikProps }),
        }}
      >
        {children}
      </Context.Provider>
    )}
  </Formik>
)
