import React from 'react'
import { useQuery } from '@apollo/client'
import Loading from '../Loading'
import Error from '../Error'
import query from '../Hubs/List/Items/lib/query'
import RelationSelectInput from './Relation/Select'

export default (props) => {
  const { loading, error, data } = useQuery(query)

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <RelationSelectInput
      label='Hub'
      name='hubId'
      options={data.hubs}
      $maxWidth='300px'
      {...props}
    />
  )
}
