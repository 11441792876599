import { gql } from 'graphql.macro'
import ReadingTypeFragment from '../../ReadingTypes/lib/ReadingTypeFragment'

export default gql`
  fragment ReadingFragment on Reading {
    id
    value

    readingType {
      ...ReadingTypeFragment
    }
  }

  ${ReadingTypeFragment}
`
