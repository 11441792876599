import React from 'react'
import {
  Route,
  Redirect,
} from 'react-router-dom'
import CurrentUser from '../CurrentUser'
import Nav from '../Nav'

export default ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <CurrentUser fetchPolicy='network-only'>
        {({ currentUser }) => {
          if (!currentUser) return <Redirect to='/login' />

          return (
            <>
              <Nav />
              <Component {...props} />
            </>
          )
        }}
      </CurrentUser>
    )}
  />
)
