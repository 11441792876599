import { gql } from 'graphql.macro'
import UserFragment from '../../lib/UserFragment'

export default gql`
  query User($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  }

  ${UserFragment}
`
