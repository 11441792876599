import React from 'react'
import _ from 'lodash'
import Gateway from '../../../../../../Input/Gateway'
import onChange from './lib/onChange'

const emptyOption = { id: '', name: 'Any gateway' }

export default ({
  formikProps,
  formikProps: {
    values: {
      scopes,
    },
  },
}) => (
  <Gateway
    value={() => _.find(scopes, { scopable: 'Gateway' }) || emptyOption}
    onChange={onChange({ formikProps })}
    emptyOption={emptyOption}
  />
)
