import _ from 'lodash'
import initialValueDefaults from '../../lib/initialValueDefaults'
import serializeOptions from '../../lib/serializeOptions'

export default ({
  name,
  manufacturerLabel,
  active,
  color,
  externalId,
  primaryGatewayId,
  gateways,
  manufacturerId,
  modelId,
  photoImageUrl,
  location,
  eventTypeDebounces,
  readingRanges,
  readingOffsets,
}) =>
  _.defaults(
    {
      name,
      manufacturerLabel,
      active,
      color,
      externalId,
      gateways: serializeOptions(gateways),
      primaryGatewayId,
      manufacturerId,
      modelId,
      photoImageUrl,
      location: _.pick(location, 'x', 'y'),
      eventTypeDebounces,
      readingRanges,
      readingOffsets,
    },
    initialValueDefaults
  )
