import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import CurrentUser from '../../CurrentUser'
import Loading from '../../Loading'
import Error from '../../Error'
import sitesQuery from '../../Sites/List/Items/lib/query'
import GroupedItems from '../GroupedItems'
import DefaultGroupLabel from './DefaultGroupLabel'
import onReorder from './lib/onReorder'
import reorderUpdate from './lib/reorderUpdate'
import groupDroppableId from './lib/groupDroppableId'
import groupIsDropDisabled from './lib/groupIsDropDisabled'
import itemIsDragDisabled from './lib/itemIsDragDisabled'
import groups from './lib/groups'
import refetchQueries from './lib/refetchQueries'

export default ({
  items,
  reorderMutation,
  reorderMutationName,
  query,
  queryName,
  includeSiteNull = false,
  GroupLabel = DefaultGroupLabel,
  ...rest
}) => {
  const [reorder, mutationProps] = useMutation(reorderMutation, {
    refetchQueries: refetchQueries({ query }),
    update: reorderUpdate({
      reorderMutationName,
      query,
      queryName,
    }),
  })

  const { loading, error, data: sitesData } = useQuery(sitesQuery)

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <CurrentUser>
      {({ currentUser }) => (
        <>
          <GroupedItems
            groupKey='site'
            groupLabel={GroupLabel}
            groupDroppableId={groupDroppableId}
            groupIsDropDisabled={groupIsDropDisabled({ currentUser })}
            itemIsDragDisabled={itemIsDragDisabled({ currentUser })}
            groups={groups({ items, sitesData, includeSiteNull })}
            onReorder={onReorder({
              reorder,
              reorderMutationName,
              items,
            })}
            {...rest}
          />
          {mutationProps.error && <Error />}
        </>
      )}
    </CurrentUser>
  )
}
