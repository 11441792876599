import * as yup from 'yup'

const schema = yup.object().shape({
  sparkPostApiKey: yup.string().nullable().min(1, 'Required field'),
  from: yup.string().nullable().min(1, 'Required field'),
  subject: yup.string().nullable().min(1, 'Required field'),
  text: yup.string().nullable().min(1, 'Required field'),
})

export default (key) => (value) => {
  let error = ''

  try {
    schema.validateSyncAt(key, value)
  } catch (e) {
    error = e.message
  }

  return error
}
