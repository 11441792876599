import { gql } from 'graphql.macro'
import SensorEditFragment from '../../lib/SensorEditFragment'

export default gql`
  mutation UpdateSensor($id: ID!, $data: SensorDataInput!) {
    updateSensor(id: $id, data: $data) {
      ...SensorEditFragment
    }
  }

  ${SensorEditFragment}
`
