import React, { useState } from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { FiMenu, FiX } from 'react-icons/fi'
import Logo from './Logo'
import Menu from './Menu'

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-bottom: ${(props) => props.theme.borders.dark8};
  padding: 10px;

  ${breakpoint('md')`
    padding: 20px;
  `}
`

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MenuButton = styled.div`
  font-size: 20px;
  color: ${(props) => props.theme.colors.grey7};
  cursor: pointer;

  ${breakpoint('lg')`
    display: none;
  `}
`

const TopMenuContainer = styled.div`
  display: none;

  ${breakpoint('lg')`
    display: block;
  `}
`

const BottomMenuContainer = styled.div`
  display: block;

  ${breakpoint('lg')`
    display: none;
  `}
`

export default () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container>
      <TopContainer>
        <Logo />
        <MenuButton
          as={isOpen ? FiX : FiMenu}
          onClick={() => setIsOpen(!isOpen)}
        />
        <TopMenuContainer>
          <Menu closeTopMenu={() => setIsOpen(false)} />
        </TopMenuContainer>
      </TopContainer>
      {isOpen && (
        <BottomMenuContainer>
          <Menu closeTopMenu={() => setIsOpen(false)} />
        </BottomMenuContainer>
      )}
    </Container>
  )
}
