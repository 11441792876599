import { gql } from 'graphql.macro'
import AreaWithGatewaysFragment from '../../lib/AreaWithGatewaysFragment'

export default gql`
  query Area($id: ID!) {
    area(id: $id) {
      ...AreaWithGatewaysFragment
    }
  }

  ${AreaWithGatewaysFragment}
`
