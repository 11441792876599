import _ from 'lodash'
import query from '../../List/Items/lib/query'

export default (cache, {
  data: {
    createSite,
  },
}) => {
  const { sites } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { sites: _.concat(sites, createSite) },
  })
}
