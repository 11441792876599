import _ from 'lodash'

export default ({
  values: { modelId, manufacturerId },
  modelsData,
  manufacturersData,
}) => ({
  model: _.find(modelsData.models, { id: modelId }),
  manufacturer: _.find(manufacturersData.manufacturers, { id: manufacturerId }),
})
