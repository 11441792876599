import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.colors.grey5};
  height: 10px;
  left: ${(props) => props.$left}px;
  width: ${(props) => props.$right - props.$left}px;
  border-radius: 4px;
`

const halfOfBall = 16 / 2

export default ({ width, lowerPosition, upperPosition }) => {
  if (lowerPosition > upperPosition) {
    return (
      <>
        <Container $left={0} $right={upperPosition + halfOfBall} />
        <Container $left={lowerPosition + halfOfBall} $right={width} />
      </>
    )
  }

  return (
    <Container
      $left={lowerPosition + halfOfBall}
      $right={upperPosition + halfOfBall}
    />
  )
}
