import { gql } from 'graphql.macro'

export default gql`
  fragment ActionFragment on Action {
    id
    name
    active
    type
    accountId

    url
    sparkPostApiKey
    sandbox
    from
    subject
    text

    webhookMethod
    webhookAuth
    webhookAuthUsername
    webhookAuthPassword

    webhookHeaders {
      id
      key
      value
    }

    recipients {
      id
      email
    }

    pushoverToken
    pushoverUser
    pushoverMessage
    pushoverDevice
    pushoverTitle
    pushoverUrl
    pushoverUrlTitle
    pushoverPriority
    pushoverSound
  }
`
