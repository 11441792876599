import _ from 'lodash'

export default ({
  data: {
    actions,
  },
  values,
}) => (
  _.reject(actions, ({
    id,
  }) => (
    _.some(values.actions, { id })
  ))
)
