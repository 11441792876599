import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import { FieldArray } from 'formik'
import Loading from '../../Loading'
import Error from '../../Error'
import EditList from '../../Edit/List'
import AddButton from './AddButton'
import onDelete from './lib/onDelete'

export default ({
  query,
  variables = {},
  path,
  queryPath = path,
  header,
  formikProps: {
    values: { [path]: items },
    values,
  },
  onNew = ({ arrayHelpers: { push }, item }) =>
    push(_.pick(item, ['id', 'name'])),
  actions,
  getKey = ({ item: { id } }) => id,
  label,
  onDeleteProps = {},
  isSelected = ({ item: { id }, values }) => _.some(values, { id }),
}) => {
  const { loading, error, data } = useQuery(query, { variables })

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <FieldArray
      name={path}
      render={(arrayHelpers) => (
        <EditList
          header={header}
          addButton={
            <AddButton
              data={data}
              values={values}
              arrayHelpers={arrayHelpers}
              isSelected={isSelected}
              path={path}
              queryPath={queryPath}
              onNew={onNew}
            />
          }
          items={items}
          onDelete={onDelete({ arrayHelpers, values, path })}
          onDeleteProps={onDeleteProps}
          actions={actions}
          getKey={getKey}
          label={label}
        />
      )}
    />
  )
}
