import _ from 'lodash'
import initialValues from './initialValues'

export default ({
  arrayHelpers: {
    push,
  },
  data: {
    actions,
  },
}) => ({ actionId }, { resetForm }) => {
  push(_.pick(_.find(actions, { id: actionId }), ['id', 'name']))
  resetForm({ values: initialValues })
}
