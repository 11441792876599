import _ from 'lodash'

const termDefaults = {
  type: 'plain',
  value: '',
}

export default {
  termOne: _.cloneDeep(termDefaults),
  comparison: 'lt',
  termTwo: _.cloneDeep(termDefaults),
}
