import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import Layout from '../../Layout'
import Loading from '../../Loading'
import Error from '../../Error'
import EditHeader from '../../Edit/Header'
import SensorsBroadList from '../../Sensors/BroadList'
import Form from '../Form'
import query from './lib/query'
import mutation from './lib/mutation'
import initialValues from './lib/initialValues'

export default ({
  match: {
    params: { id },
  },
}) => {
  const { loading, error, data } = useQuery(query, {
    variables: {
      id,
    },
  })

  const [mutate, mutationProps] = useMutation(mutation)
  const [photoImageUrlMutate, photoImageUrlMutationProps] = useMutation(
    mutation,
    {
      variables: {
        id,
      },
    }
  )

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Layout>
      <EditHeader>Edit gateway</EditHeader>

      <Form
        initialValues={initialValues(data.gateway)}
        onSubmit={(data) => mutate({ variables: { id, data } })}
        photoImageUrlMutate={photoImageUrlMutate}
        photoImageUrlMutationProps={photoImageUrlMutationProps}
      >
        <SensorsBroadList
          header='Sensors (primary)'
          sensors={data.gateway.primarySensors}
        />
        <SensorsBroadList
          header='Sensors (non-primary)'
          sensors={data.gateway.nonPrimarySensors}
        />
      </Form>
      {mutationProps.error && <Error leftAligned />}
      {mutationProps.data && <Redirect to='/gateways' />}
    </Layout>
  )
}
