import { gql } from 'graphql.macro'
import GatewayBaseFragment from './GatewayBaseFragment'

export default gql`
  {
    gateways {
      ...GatewayBaseFragment
    }
  }

  ${GatewayBaseFragment}
`
