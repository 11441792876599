import _ from 'lodash'
import emptyOption from './emptyOption'

export default ({
  item: {
    __typename,
    id,
  },
  formikProps: {
    values: {
      userRoles,
    },
  },
  data: {
    roles,
  },
}) => () => {
  const userRole = _.find(userRoles, {
    objectable: __typename,
    objectableId: id,
  })

  if (!userRole) return emptyOption

  return _.find(roles, { id: userRole.roleId })
}
