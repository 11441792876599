import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { IoMdReorder } from 'react-icons/io'
import Actions from './Actions'

const Container = styled.div`
  border-top: ${(props) => props.theme.borders.light4};
  border-bottom: ${(props) => props.theme.borders.light4};
  background-color: white;
  padding: 20px 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${breakpoint('md')`
    padding: 20px 40px;
    flex-direction: row;
    align-items: center;
  `}
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
`

const Reorder = styled(IoMdReorder)`
  margin-right: 10px;
  font-size: 25px;
  fill: ${(props) => props.theme.colors.light3};
  flex-shrink: 0;

  ${breakpoint('md')`
    font-size: 30px;
  `}
`

const Label = styled.div`
  width: 100%;
  color: ${(props) => !props.active && props.theme.colors.light2};
  font-size: 14px;
  white-space: wrap;

  ${breakpoint('md')`
    font-size: 16px;
  `}
`

const label = (item, itemLabel) => {
  if (_.isFunction(itemLabel)) return itemLabel(item)

  return _.get(item, itemLabel)
}

export default ({
  innerRef,
  provided = {},
  onReorder,
  item,
  itemLabel = 'name',
  item: { active = true },
  isDragDisabled,
  ...rest
}) => (
  <Container
    ref={innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
  >
    <InnerContainer>
      {onReorder && !isDragDisabled && <Reorder />}
      <Label active={active}>{label(item, itemLabel)}</Label>
    </InnerContainer>
    <Actions item={item} {...rest} />
  </Container>
)
