import React from 'react'
import { useQuery } from '@apollo/client'
import Loading from '../Loading'
import Error from '../Error'
import baseQuery from '../Sensors/lib/baseQuery'
import RelationSelectInput from './Relation/Select'

export default ({ query = baseQuery, variables = {}, ...rest }) => {
  const { loading, error, data } = useQuery(query, { variables })

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <RelationSelectInput
      label='Sensor'
      name='sensorId'
      options={data.sensors}
      {...rest}
    />
  )
}
