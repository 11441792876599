import _ from 'lodash'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'

const moment = extendMoment(Moment)

export default ({
  date,
  data: {
    sensor: { aggregatedReadings },
  },
}) => {
  const dayEvents = _.filter(aggregatedReadings, ({ timestamp }) =>
    moment(timestamp).isSame(date, 'day')
  )

  const hours = Array.from(
    moment
      .range(
        date.local().startOf('day').format(),
        date.local().endOf('day').format()
      )
      .by('hour')
  )

  return _.map(hours, (datetime) => {
    const hourEvents = _.filter(dayEvents, ({ timestamp }) =>
      moment(timestamp).local().isSame(datetime, 'hour')
    )

    const events = _.filter(hourEvents, { type: 'event' })
    const triggers = _.filter(hourEvents, { type: 'trigger' })

    return {
      name: +datetime,
      datetime,
      eventsExist: _.isEmpty(events) ? 0 : 1,
      triggersExist: _.isEmpty(triggers) ? 0 : 1,
      events: hourEvents,
    }
  })
}
