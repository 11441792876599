import React from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import styled from 'styled-components'
import Moment from 'react-moment'
import Time from '../Time'
import isOutdated from '../lib/isOutdated'

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors[props.color]};
`

const Label = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
  font-size: 12px;
`

export default ({ sensor: { lastEvents } }) => {
  const event = _.last(
    _.sortBy(lastEvents, ({ timestamp }) => +moment(timestamp))
  )

  const { timestamp } = event

  const color = isOutdated({ event }) ? 'reddish' : 'grey9'

  return (
    <Container color={color}>
      <Time event={event} tooltipContentLabel='Last updated:' color={color} />
      <Label>
        {isOutdated({ event }) ? (
          'Offline'
        ) : (
          <Moment fromNow ago locale='en-short'>
            {timestamp}
          </Moment>
        )}
      </Label>
    </Container>
  )
}
