import _ from 'lodash'
import isSenseCookie from '../../../../../lib/isSenseCookie'
import sensor from './sensor'

export default ({
  values: { modelId, manufacturerId },
  values,
  manufacturersQueryProps: { data: manufacturersData },
  modelsQueryProps: { data: modelsData },
}) => ({ selectableColors }) => {
  if (!modelId) return selectableColors
  if (!manufacturerId) return selectableColors

  if (
    isSenseCookie({ sensor: sensor({ values, modelsData, manufacturersData }) })
  ) {
    return _.take(selectableColors, 4)
  }

  return selectableColors
}
