import recursiveOmitBy from '@nedomas/recursive-omit-by'
import cloneDeep from 'clone-deep'
import _ from 'lodash'

const isReadingType = (path) => (
  _.first(_.takeRight(path, 2)) === 'readingType'
)

export default (condition) => (
  recursiveOmitBy(cloneDeep(condition), ({ key, path }) => {
    if (key === '__typename') return true
    if (isReadingType(path)) {
      if (key === 'id') return false

      return true
    }

    if (key === 'id') {
      return true
    }

    return false
  })
)
