import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { Field } from 'formik'
import selectableColors from '../../theme/selectableColors'
import Container from './Container'
import LabelContainer from './LabelContainer'
import CheckmarkContainer from './CheckmarkContainer'

const InputContainer = styled.div`
  display: flex;
`

const Box = styled.div`
  background-color: ${({ color }) => color};
  height: 20px;
  width: 20px;
  margin-right: 10px;
  border-radius: 4px;
`

export default ({ label, options = () => selectableColors, ...rest }) => (
  <Container>
    <LabelContainer>{label}</LabelContainer>

    <Field {...rest}>
      {({ field, form }) => (
        <InputContainer>
          {_.map(options({ selectableColors }), (color, i) => (
            <CheckmarkContainer
              key={color}
              checkmarkVisible={i === field.value}
              $dark
              onClick={() => form.setFieldValue(field.name, i)}
            >
              <Box color={color} />
            </CheckmarkContainer>
          ))}
        </InputContainer>
      )}
    </Field>
  </Container>
)
