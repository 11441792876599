import { gql } from 'graphql.macro'

export default gql`
  fragment AccountFragment on Account {
    id
    name
    contactName
    email
    phone
    billingAddress
  }
`
