export default ({
  name,
  address,
  photoImageUrl,
  accountId,
}) => ({
  name,
  address,
  photoImageUrl,
  accountId,
})
