import React from 'react'
import Select from '../Select'
import onChange from './lib/onChange'

export default (props) => (
  <Select
    isMulti
    includeEmpty={false}
    onChange={onChange}
    {...props}
  />
)
