import { gql } from 'graphql.macro'
import SensorBaseFragment from '../../../../Sensors/lib/SensorBaseFragment'
import ModelFragment from '../../../../Models/lib/ModelFragment'

export default gql`
  query SensorWithEventTypesAndReadingTypes($id: ID!) {
    sensor(id: $id) {
      ...SensorBaseFragment

      model {
        ...ModelFragment
      }
    }
  }

  ${SensorBaseFragment}
  ${ModelFragment}
`
