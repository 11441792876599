import _ from 'lodash'
import isNumber from 'is-number'
import { celsiusToFahrenheit } from 'temperature'

export default ({
  value,
  reading: {
    readingType: { name },
  },
  currentUser: { systemOfUnits },
}) => {
  if (!isNumber(value)) return value
  if (name !== 'Temperature') return _.round(value, 2)

  if (systemOfUnits === 'imperial') {
    return `${_.round(celsiusToFahrenheit(value), 2)} °F`
  }

  return `${_.round(value, 2)} °C`
}
