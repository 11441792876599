import moment from 'moment-timezone'
import timestampsTerm from './timestampsTerm'

export default ({ values: { condition } }) => ({
  termOne: {
    type: 'boolean',
    condition,
  },
  comparison: 'and',
  termTwo: timestampsTerm({
    lowerThreshold: moment.utc().local().hour(8).minute(0).toISOString(),
    upperThreshold: moment.utc().local().hour(18).minute(0).toISOString(),
  }),
})
