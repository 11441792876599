import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Heading from '../Heading'

const Container = styled.div`
  padding: 20px 10px;
  border-bottom: ${(props) => props.theme.borders.light4};

  ${breakpoint('md')`
    padding: 20px 40px 40px;
  `}
`

export default (props) => (
  <Container>
    <Heading {...props} />
  </Container>
)
