import React from 'react'
import { Field } from 'formik'
import styled from 'styled-components'
import Container from './Container'
import LabelContainer from './LabelContainer'
import DefaultInput from './Input'
import Error from './Error'
import Description from './Description'

const Optional = styled.span`
  display: flex;
  font-size: 14px;
  color: ${(props) => props.theme.colors.grey};
  font-weight: 300;
  padding-bottom: 10px;
`

export default ({
  label,
  description,
  inputComponent: Input = DefaultInput,
  isOptional,
  optionalText = '(optional)',
  $maxWidth,
  $minWidth,
  ...rest
}) => (
  <Field {...rest}>
    {({ field, form }) => (
      <Container>
        <LabelContainer>{label}</LabelContainer>
        {isOptional && <Optional>{optionalText}</Optional>}
        {description && <Description>{description}</Description>}
        <Input
          $minWidth={$minWidth}
          $maxWidth={$maxWidth}
          {...field}
          {...rest}
        />
        <Error name={field.name} />
      </Container>
    )}
  </Field>
)
