import React from 'react'
import styled from 'styled-components'
import { FiBattery, FiWifi } from 'react-icons/fi'
import Value from '../Value'
import Time from './Time'
import voltage from './lib/voltage'
import signalStrength from './lib/signalStrength'
import voltageFormatter from './lib/voltageFormatter'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
`

export default ({ sensor, lastReadings }) => (
  <Container>
    <Value
      name='Voltage'
      icon={FiBattery}
      sensor={sensor}
      reading={voltage(lastReadings)}
      formatter={voltageFormatter}
    />
    <Time sensor={sensor} />
    <Value
      name='Signal (db)'
      icon={FiWifi}
      align='right'
      reading={signalStrength(lastReadings)}
      sensor={sensor}
    />
  </Container>
)
