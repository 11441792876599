import { gql } from 'graphql.macro'
import EventTypeBaseFragment from './EventTypeBaseFragment'

export default gql`
  {
    eventTypes {
      ...EventTypeBaseFragment
    }
  }

  ${EventTypeBaseFragment}
`
