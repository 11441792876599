import Moment from 'react-moment'
import moment from 'moment-timezone'

export default ({ currentUser }) => ({ updateUser }) => {
  if (currentUser.id !== updateUser.id) return

  const { timezone } = updateUser

  moment.tz.setDefault(timezone)
  Moment.globalTimezone = timezone
}
