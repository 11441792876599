import { gql } from 'graphql.macro'
import SensorFragment from '../../Sensors/lib/SensorFragment'
import ReadingFragment from '../../Readings/lib/ReadingFragment'
import EventTypeFragment from '../../EventTypes/lib/EventTypeFragment'
import EventBaseFragment from './EventBaseFragment'

export default gql`
  fragment EventFragment on Event {
    ...EventBaseFragment

    sensor {
      ...SensorFragment
    }

    readings {
      ...ReadingFragment
    }

    eventTypes {
      ...EventTypeFragment
    }
  }

  ${EventBaseFragment}
  ${SensorFragment}
  ${ReadingFragment}
  ${EventTypeFragment}
`
