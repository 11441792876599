import React from 'react'
import styled from 'styled-components'
import Threshold from './Threshold'
import Sensor from './Sensor'
import Gateway from './Gateway'
import Hub from './Hub'
import TemperatureRange from './TemperatureRange'
import TimeInterval from './TimeInterval'
import type from './lib/type'
import isThreshold from './lib/isThreshold'
import isTimeInterval from './lib/isTimeInterval'
import isTemperatureRange from './lib/isTemperatureRange'

const Container = styled.div`
  padding: 20px 0;
`

export default ({
  formikProps: {
    values: { slug, condition },
  },
  formikProps,
  sensorSelect,
  gatewaySelect,
  types,
  ...rest
}) => (
  <Container>
    {type({ types, slug }).sensorSelect && (
      <Sensor
        formikProps={formikProps}
        sensorSelect={type({ types, slug }).sensorSelect}
      />
    )}
    {type({ types, slug }).hubSelect && <Hub formikProps={formikProps} />}
    {type({ types, slug }).gatewaySelect && (
      <Gateway formikProps={formikProps} />
    )}
    {!isTimeInterval({ formikProps }) && isThreshold({ formikProps }) && (
      <Threshold formikProps={formikProps} />
    )}
    {isTimeInterval({ formikProps }) && (
      <TimeInterval formikProps={formikProps} />
    )}
    {isTemperatureRange({ formikProps }) && (
      <TemperatureRange formikProps={formikProps} />
    )}
  </Container>
)
