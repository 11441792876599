import { gql } from 'graphql.macro'
import HubFragment from '../../../lib/HubFragment'

export default gql`
  {
    hubs {
      ...HubFragment
    }
  }

  ${HubFragment}
`
