import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Item from './Item'
import Empty from './Empty'

const Container = styled.div`
  padding-bottom: 40px;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint('md')`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`

const HeaderText = styled.div`
  font-weight: 600;
`

const ListContainer = styled.div`
  padding-top: 10px;
`

export default ({
  header,
  addButton,
  items,
  getKey = ({ item: { id } }) => id,
  ...rest
}) => (
  <Container>
    <Header>
      <HeaderText>{header}</HeaderText>

      {addButton}
    </Header>
    <ListContainer>
      {_.map(items, (item, index) => (
        <Item key={getKey({ item })} index={index} item={item} {...rest} />
      ))}
      {_.isEmpty(items) && <Empty header={header} />}
    </ListContainer>
  </Container>
)
