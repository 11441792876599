export default ({
  formikProps: {
    values: {
      areaId,
      gatewayId,
      eventTypeId,
      readingTypeId,
      sensorId,
      status,
      modelId,
      manufacturerId,
      siteId,
    },
  },
}) => {
  const result = []

  if (areaId) {
    result.push({
      type: 'Area',
      id: areaId,
    })
  }

  if (gatewayId) {
    result.push({
      type: 'Gateway',
      id: gatewayId,
    })
  }

  if (eventTypeId) {
    result.push({
      type: 'EventType',
      id: eventTypeId,
    })
  }

  if (readingTypeId) {
    result.push({
      type: 'ReadingType',
      id: readingTypeId,
    })
  }

  if (sensorId) {
    result.push({
      type: 'Sensor',
      id: sensorId,
    })
  }

  if (status) {
    result.push({
      type: 'Status',
      id: status,
    })
  }

  if (modelId) {
    result.push({
      type: 'Model',
      id: modelId,
    })
  }

  if (manufacturerId) {
    result.push({
      type: 'Manufacturer',
      id: manufacturerId,
    })
  }

  if (siteId) {
    result.push({
      type: 'Site',
      id: siteId,
    })
  }

  return result
}
