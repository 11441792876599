import React from 'react'
import styled from 'styled-components'
import Item from './Item'
import duration from './lib/duration'

const Container = styled.div`
  padding-bottom: 10px;
`

export default ({ timestamp, previousTimestamp }) => (
  <Container>
    <Item>{timestamp.slug}</Item>
    <Item label='Duration (ms)'>
      {previousTimestamp ? duration({ timestamp, previousTimestamp }) : 'N/A'}
    </Item>
    <Item label='Timestamp'>{timestamp.timestamp}</Item>
  </Container>
)
