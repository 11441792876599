import moment from 'moment-timezone'

export default ({
  formikProps: { startDate, endDate, setFieldValue },
  type,
}) => () => {
  setFieldValue('resolution', type)

  if (type === 'weekly') {
    setFieldValue('startDate', moment(endDate).subtract(6, 'days').toDate())
  } else {
    setFieldValue('startDate', moment(endDate).subtract(1, 'month').toDate())
  }
}
