import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import groups from '../../../../../lib/groups'
import Type from '../Type'
import selectType from './lib/selectType'

const GroupsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const GroupContainer = styled.div`
  padding-bottom: 20px;
`

const GroupName = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.grey};
`

const GroupTypes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 10px 0 -10px;
`

export default ({
  readingTypesData,
  eventTypesData,
  formikProps,
  setIsOpen,
}) => (
  <GroupsContainer>
    {_.map(groups({ readingTypesData, eventTypesData }), ({ name, types }) => (
      <GroupContainer key={name}>
        <GroupName>{name}</GroupName>

        <GroupTypes>
          {_.map(types, (type, i) => (
            <Type
              key={i}
              type={type}
              formikProps={formikProps}
              onClick={() =>
                selectType({
                  formikProps,
                  type,
                  setIsOpen,
                })
              }
            />
          ))}
        </GroupTypes>
      </GroupContainer>
    ))}
  </GroupsContainer>
)
