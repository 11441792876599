import moment from 'moment-timezone'

export default ({
  sensor: { id },
  formikProps: {
    values: { startDate, endDate, ...restValues },
  },
}) => ({
  id,
  startDate: moment.utc(startDate).local().format(),
  endDate: moment.utc(endDate).local().format(),
  ...restValues,
})
