import { gql } from 'graphql.macro'
import GatewayFragment from '../../lib/GatewayFragment'

export default gql`
  mutation CreateGateway($data: GatewayDataInput!) {
    createGateway(data: $data) {
      ...GatewayFragment
    }
  }

  ${GatewayFragment}
`
