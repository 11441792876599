import React from 'react'
import { Formik, Form } from 'formik'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import TextInput from '../../Input/Text'
import Error from '../../Error'
import initialValues from './lib/initialValues'
import validationSchema from './lib/validationSchema'
import mutation from './lib/mutation'
import onCompleted from './lib/onCompleted'

export default () => {
  const history = useHistory()

  const [login, mutationProps] = useMutation(mutation, {
    onCompleted: onCompleted({ history }),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(variables) => login({ variables })}
    >
      {() => (
        <Form>
          <FormContainer $type='tiny'>
            <TextInput label='Email' name='email' type='email' />
            <TextInput label='Password' name='password' type='password' />

            <FormButton>Login</FormButton>

            {mutationProps.error && (
              <Error message={mutationProps.error.message} />
            )}
          </FormContainer>
        </Form>
      )}
    </Formik>
  )
}
