import React, { useEffect, useState } from 'react'
import { useVisible } from 'react-hooks-visible'
import moment from 'moment-timezone'
import _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import Note from '../../../../../Note'
import Loading from '../../../../../Loading'
import Error from '../../../../../Error'
import TooltipContainer from '../../../TooltipContainer'
import Content from './Content'
import ChartIcon from './ChartIcon'
import query from './lib/query'

export default ({ sensor: { id }, sensor: partialSensor, closeLink }) => {
  const [isVisited, setIsVisited] = useState(false)
  const [targetRef, visible] = useVisible()

  const [load, { loading, error, data }] = useLazyQuery(query, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    returnPartialData: true,
  })

  useEffect(() => {
    if (!isVisited) return

    load()
  }, [load, isVisited])

  useEffect(() => {
    if (isVisited) return
    if (visible === 0) return

    setIsVisited(true)
  }, [isVisited, visible])

  if (error) return <Error />

  const {
    sensor: { status, name, lastEvents },
    sensor,
  } = _.isEmpty(data) ? { sensor: partialSensor } : data

  const lastEvent = _.last(
    _.sortBy(lastEvents, ({ timestamp }) => +moment(timestamp))
  )

  return (
    <TooltipContainer
      ref={targetRef}
      status={status}
      name={name}
      closeLink={closeLink}
      actions={<ChartIcon status={status} sensor={sensor} />}
    >
      {loading && _.isEmpty(data) && <Loading />}
      {!loading && !_.isEmpty(data) && !lastEvent && (
        <Note>No events yet.</Note>
      )}
      {lastEvent && <Content sensor={sensor} lastEvents={lastEvents} />}
    </TooltipContainer>
  )
}
