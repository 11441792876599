import React from 'react'
import Checkbox from '../../../../../Input/Checkbox'
import Input from './Input'
import value from './lib/value'
import onClick from './lib/onClick'

export default ({ formikProps }) => {
  return (
    <>
      <Checkbox
        label='Limit to times of the day'
        value={() => value({ formikProps })}
        onClick={() => onClick({ formikProps })}
      />

      {value({ formikProps }) && <Input formikProps={formikProps} />}
    </>
  )
}
