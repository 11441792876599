import React from 'react'
import _ from 'lodash'
import { fahrenheitToCelsius, celsiusToFahrenheit } from 'temperature'
import { useQuery } from '@apollo/client'
import useCurrentUser from '../../../../../../../../hooks/useCurrentUser'
import Loading from '../../../../../../../Loading'
import Error from '../../../../../../../Error'
import readingTypesQuery from '../../../../../../../ReadingTypes/List/Items/lib/query'
import TextInput from '../../../../../../../Input/Text'
import ReadingValue from '../../../../../../../Input/Reading/Value'
import validate from './lib/validate'

const getReadingType = ({ data: { readingTypes }, dependentOn, values }) =>
  _.find(readingTypes, {
    id: _.get(values, `${dependentOn}.readingType.id`),
  })

const TEMPERATURE_READING_TYPE_NAMES = ['Temperature', 'Ambient temperature']

const value = ({ formikProps, otherTermReadingType, systemOfUnits, name }) => {
  if (!_.includes(TEMPERATURE_READING_TYPE_NAMES, otherTermReadingType?.name)) {
    return _.get(formikProps.values, name)
  }

  if (systemOfUnits === 'metric') {
    return _.get(formikProps.values, name)
  }

  return celsiusToFahrenheit(_.get(formikProps.values, name))
}

const onChange = ({
  formikProps,
  otherTermReadingType,
  systemOfUnits,
  name,
}) => (event) => {
  if (!_.includes(TEMPERATURE_READING_TYPE_NAMES, otherTermReadingType?.name)) {
    return formikProps.setFieldValue(name, event.target.value)
  }

  if (systemOfUnits === 'metric') {
    return formikProps.setFieldValue(name, event.target.value)
  }

  return formikProps.setFieldValue(
    name,
    _.toString(fahrenheitToCelsius(event.target.value))
  )
}

export default ({ name, label, description, formikProps, ...rest }) => {
  const { currentUser } = useCurrentUser()
  const { loading, error, data } = useQuery(readingTypesQuery)

  if (loading || currentUser.loading) return <Loading />
  if (error || currentUser.error) return <Error />

  const readingType = getReadingType({ data, ...rest })
  const { systemOfUnits } = currentUser

  const lastConditionPath = name.match(/.*condition/)
  const otherTermReadingTypeId = _.get(
    formikProps,
    `values.${lastConditionPath}.termOne.readingType.id`
  )
  const otherTermReadingType = _.find(data.readingTypes, {
    id: otherTermReadingTypeId,
  })

  if (!readingType) {
    return (
      <TextInput
        label={label}
        name={name}
        validate={validate}
        description={description}
        $maxWidth='100px'
        {...(otherTermReadingType
          ? {
              value: value({
                formikProps,
                name,
                otherTermReadingType,
                systemOfUnits,
              }),
              onChange: onChange({
                formikProps,
                name,
                otherTermReadingType,
                systemOfUnits,
              }),
            }
          : {})}
      />
    )
  }

  return <ReadingValue label={label} name={name} readingType={readingType} />
}
