import React from 'react'
import { Formik, Form } from 'formik'
import { useQuery } from '@apollo/client'
import Loading from '../../Loading'
import Error from '../../Error'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import TextInput from '../../Input/Text'
import Checkbox from '../../Input/Checkbox'
import ImageInput from '../../Input/Image'
import ImageLocationInput from '../../Input/ImageLocation'
import ManufacturerInput from '../../Input/Manufacturer'
import ModelInput from '../../Input/Model'
import SiteInput from '../../Input/Site'
import AreaInput from '../../Input/Area'
import areasQuery from '../../Areas/List/Items/lib/query'
import locationBackgroundUrl from './lib/locationBackgroundUrl'
import validationSchema from './lib/validationSchema'

export default ({
  children,
  photoImageUrlMutate = () => null,
  photoImageUrlMutationProps = {},
  ...rest
}) => {
  const { loading, error, data } = useQuery(areasQuery)

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Formik validationSchema={validationSchema} {...rest}>
      {({ values }) => (
        <Form>
          <FormContainer>
            <TextInput label='Name' name='name' $maxWidth='300px' />
            <Checkbox label='Active' name='active' />
            <SiteInput />
            <ManufacturerInput />
            <ModelInput type='gateway' />
            <TextInput
              label='External ID'
              name='externalId'
              $maxWidth='300px'
            />
            <AreaInput />
            <ImageInput
              label='Photo'
              name='photoImageUrl'
              mutate={photoImageUrlMutate}
              mutationProps={photoImageUrlMutationProps}
            />
            <ImageLocationInput
              label='Location'
              name='location'
              backgroundUrl={locationBackgroundUrl(data, values)}
            />

            {children}

            <FormButton />
          </FormContainer>
        </Form>
      )}
    </Formik>
  )
}
