import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { Formik } from 'formik'
import RelationSelectInput from '../../../../Input/Relation/Select'
import Button from '../../../../Form/Button'
import validationSchema from './lib/validationSchema'
import initialValues from './lib/initialValues'
import onSubmit from './lib/onSubmit'
import filter from './lib/filter'

const Container = styled.div`
  min-width: 250px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  ${breakpoint('md')`
    padding-top: 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  `}
`

const ButtonContainer = styled.div`
  padding-top: 10px;

  ${breakpoint('md')`
    padding-top: 0;
  `}
`

export default ({ data, values, arrayHelpers }) => (
  <Formik
    validationSchema={validationSchema}
    initialValues={initialValues}
    onSubmit={onSubmit({ arrayHelpers, data })}
  >
    {({ handleSubmit }) => (
      <Container>
        <RelationSelectInput
          name='itemId'
          options={filter({ data, values })}
          paddingBottom={0}
          paddingRight='10px'
        />
        <ButtonContainer>
          <Button size='medium' onClick={handleSubmit}>
            Add
          </Button>
        </ButtonContainer>
      </Container>
    )}
  </Formik>
)
