export default ({
  name,
  contactName,
  email,
  phone,
  billingAddress,
}) => ({
  name,
  contactName,
  email,
  phone,
  billingAddress,
})
