import React from 'react'
import styled from 'styled-components'
import requestBody from './lib/requestBody'

const Container = styled.div`
  background-color: ${props => props.theme.colors.light5};
  padding: 20px;
  font-family: monospace;
  margin-bottom: 20px;
`

export default (props) => (
  <Container>
    {requestBody(props)}
  </Container>
)
