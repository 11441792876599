import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import _ from 'lodash'
import TextInput from '../../../../Input/Text'
import UnitInput from '../../../../Input/Unit'
import unitSymbol from './lib/unitSymbol'

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint('md')`
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
  `}
`

const Name = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`

const ValueContainer = styled.div`
  display: flex;
  padding-top: 10px;
  flex-grow: 1;

  ${breakpoint('md')`
    padding-top: 0;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
  `}
`

export default ({
  readingTypesQueryProps: {
    data: { readingTypes },
  },
  unitsQueryProps,
  item: { readingTypeId },
  item,
  formikProps,
  index,
}) => {
  const { name } = _.find(readingTypes, { id: readingTypeId })

  return (
    <Container>
      <Name>{name}</Name>
      <ValueContainer>
        <TextInput
          label={`Offset${unitSymbol({ item, unitsQueryProps })}`}
          name={`readingOffsets[${index}].offset`}
          $maxWidth='100px'
        />
      </ValueContainer>
      <ValueContainer>
        <UnitInput
          label='Unit'
          name={`readingOffsets[${index}].unitId`}
          $maxWidth='150px'
        />
      </ValueContainer>
    </Container>
  )
}
