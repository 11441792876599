import _ from 'lodash'
import order from './order'

export default ({
  gatewayIdKey,
  items,
  gatewaysData: { gateways },
  includeGatewayNull,
}) =>
  _.compact(
    _.concat(
      _.map(gateways, (gateway) => ({
        gateway,
        items: order(_.filter(items, { [gatewayIdKey]: gateway.id })),
      })),
      includeGatewayNull && {
        gateway: {
          id: null,
        },
        items: order(_.filter(items, { [gatewayIdKey]: null })),
      }
    )
  )
