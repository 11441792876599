import React from 'react'
import _ from 'lodash'
import Select from 'react-windowed-select'
import { Field } from 'formik'
import { withTheme } from 'styled-components'
import Container from '../Container'
import LabelContainer from '../LabelContainer'
import Error from '../Error'
import { styles } from '../Select'
import options from './lib/options'

const onChange = ({ form, field }) => (option) => {
  form.setFieldValue(field.name, option.value)
}

const getValue = ({
  formikProps: {
    field: { value },
  },
}) =>
  _.find(options, {
    value,
  })

export default withTheme(
  ({
    paddingBottom,
    paddingRight,
    labelColor = 'initial',
    theme,
    $maxWidth,
    backgroundColor = 'light5',
    border = 0,
    ...rest
  }) => (
    <Field {...rest}>
      {(formikProps) => (
        <Container $paddingBottom={paddingBottom} $paddingRight={paddingRight}>
          <LabelContainer color={labelColor}>Timezone</LabelContainer>
          <Select
            value={getValue({ formikProps })}
            options={options}
            styles={styles({
              theme,
              $maxWidth,
              backgroundColor,
              border,
            })}
            onChange={onChange(formikProps)}
          />
          <Error name={formikProps.field.name} />
        </Container>
      )}
    </Field>
  )
)
