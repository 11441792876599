import _ from 'lodash'
import query from '../../List/Items/lib/query'

export default (cache, { data: { createAction } }) => {
  let prev

  try {
    prev = cache.readQuery({ query })
  } catch (e) {}

  if (!prev) return

  const { actions } = prev

  cache.writeQuery({
    query,
    data: { actions: _.concat(actions, createAction) },
  })
}
