import {
  FiThermometer,
  FiBattery,
  FiColumns,
  FiWifi,
  FiUsers,
  FiDroplet,
} from 'react-icons/fi'
import { FaThermometerFull } from 'react-icons/fa'
import colors from './colors'

export default [
  {
    name: 'Voltage',
    icon: FiBattery,
    backgroundColor: colors.types.voltage,
  },
  {
    name: 'Temperature',
    icon: FiThermometer,
    backgroundColor: colors.types.temperature,
  },
  {
    name: 'Ambient temperature',
    icon: FaThermometerFull,
    backgroundColor: colors.types.ambientTemperature,
  },
  {
    name: 'Humidity',
    icon: FiDroplet,
    backgroundColor: colors.types.humidity,
  },
  {
    name: 'Signal Strength',
    icon: FiWifi,
    backgroundColor: colors.types.signalStrength,
  },
  {
    name: 'Door',
    icon: FiColumns,
    backgroundColor: colors.types.door,
  },
  {
    name: 'Motion',
    icon: FiUsers,
    backgroundColor: colors.types.motion,
  },
]
