import _ from 'lodash'

export default ({
  groups,
  hideEmptyGroups,
}) => {
  if (!hideEmptyGroups) return groups

  return _.reject(groups, ({ items }) => (
    _.isEmpty(items)
  ))
}
