import _ from 'lodash'

export default ({ name, email, timezone, systemOfUnits, userRoles }) => ({
  name,
  email,
  timezone,
  systemOfUnits,
  password: '',
  userRoles: _.map(userRoles, ({ roleId, objectable, objectableId }) => ({
    roleId,
    objectable,
    objectableId,
  })),
})
