import React from 'react'
import _ from 'lodash'
import Select from '../Select'
import defaultEmptyOption from './lib/emptyOption'
import defaultIsMultiOnChange from './MultiSelect/lib/onChange'

const defaultGetOptionLabel = ({ name }) => name
const defaultGetOptionValue = ({ id }) => id
const defaultOnChange = (getOptionValue) => ({ form, field }) => (option) => {
  form.setFieldValue(field.name, getOptionValue(option))
}

export default ({
  emptyOption = defaultEmptyOption,
  includeEmpty = emptyOption,
  isMulti = false,
  options,
  getOptionLabel = defaultGetOptionLabel,
  getOptionValue = defaultGetOptionValue,
  onChange = isMulti ? defaultIsMultiOnChange : defaultOnChange,
  ...rest
}) => (
  <Select
    options={_.compact(_.flatten([includeEmpty ? includeEmpty : null, options]))}
    getOptionLabel={(args) => getOptionLabel(args, options)}
    getOptionValue={getOptionValue}
    onChange={onChange(getOptionValue)}
    isMulti={isMulti}
    {...rest}
  />
)
