import { gql } from 'graphql.macro'
import UserFragment from '../../Users/lib/UserFragment'

export default gql`
  {
    currentUser {
      ...UserFragment
    }
  }

  ${UserFragment}
`
