import timestampsTerm from '../../../../lib/onClick/timestampsTerm'
import value from '../../value'

export default ({
  formikProps: {
    values: { condition },
  },
  formikProps,
  thresholdName,
  newValue,
}) => ({
  ...condition,
  termTwo: timestampsTerm({
    lowerThreshold:
      thresholdName === 'lower'
        ? newValue
        : value({ formikProps, thresholdName: 'lower' }),
    upperThreshold:
      thresholdName === 'upper'
        ? newValue
        : value({ formikProps, thresholdName: 'upper' }),
  }),
})
