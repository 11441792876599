import React from 'react'
import { Formik, Form } from 'formik'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import TextInput from '../../Input/Text'
import TextArea from '../../Input/TextArea'
import validationSchema from './lib/validationSchema'

export default ({ children, ...rest }) => (
  <Formik validationSchema={validationSchema} {...rest}>
    {() => (
      <Form>
        <FormContainer>
          <TextInput label='Name' name='name' $maxWidth='300px' />
          <TextInput
            label='Contact name'
            name='contactName'
            $maxWidth='300px'
          />
          <TextInput
            label='Email'
            name='email'
            type='email'
            $maxWidth='300px'
          />
          <TextInput
            label='Phone'
            name='phone'
            type='phone'
            $maxWidth='300px'
          />
          <TextArea
            label='Billing address'
            name='billingAddress'
            $maxWidth='400px'
          />

          {children}

          <FormButton />
        </FormContainer>
      </Form>
    )}
  </Formik>
)
