import React from 'react'
import {
  FiChevronLeft,
} from 'react-icons/fi'
import styled from 'styled-components'
import ExtendedLink from '../../../ExtendedLink'

const Container = styled.div`
  color: ${props => props.theme.colors.light3};
  padding-right: 5px;
  font-size: 35px;
`

export default () => (
  <ExtendedLink to={`/dashboard/grid`}>
    <Container as={FiChevronLeft} to={`/dashboard/grid`} />
  </ExtendedLink>
)
