import React from 'react'
import styled from 'styled-components'
import { setLightness } from 'polished'
import { FiBarChart2 } from 'react-icons/fi'
import ExtendedLink from '../../../../../ExtendedLink'
import { color as tooltipColor } from '../../../../TooltipContainer'

const color = (props) => setLightness(0.3, tooltipColor(props))

const Container = styled.div`
  color: ${color};
  font-size: 18px;
`

export default ({ status, sensor: { id } }) => (
  <Container as={ExtendedLink} to={`/dashboard/sensors/${id}`} status={status}>
    <FiBarChart2 />
  </Container>
)
