import React from 'react'
import { FiActivity, FiChevronsRight } from 'react-icons/fi'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Heading from '../../../Heading'
import List from './List'

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.colors.dark8Transparent} 0px 3px 3px;
  display: flex;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  min-height: 70vh;

  ${breakpoint('md')`
    min-width: 300px;
    max-width: 400px;
    min-height: 100vh;
    min-height: 100vh;
    padding: 20px;
    margin: 0 20px;
  `}
`

const Icon = styled.div`
  font-size: 20px;
  color: ${(props) => props.theme.colors.grey7};
`

const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Content = styled.div``

const Collapse = styled.div`
  display: none;
  color: ${(props) => props.theme.colors.grey7};
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.colors.grey9};
  }

  ${breakpoint('md')`
    display: flex;
  `}
`

export default ({ setIsOpen }) => (
  <Container>
    <Heading compact>
      <HeadingContainer>
        <Content>
          <Icon as={FiActivity} /> Activity Feed
        </Content>
        <Collapse as={FiChevronsRight} onClick={() => setIsOpen(false)} />
      </HeadingContainer>
    </Heading>

    <List />
  </Container>
)
