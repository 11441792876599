import updateFeed from './updateFeed'
import updateSensorsQuery from './updateSensorsQuery'
import updateSensor from './updateSensor'

export default ({ sensorFiltersProps }) => ({
  client,
  subscriptionData: {
    data: { feedItemAdded },
  },
}) => {
  updateFeed({
    sensorFiltersProps,
    client,
    feedItemAdded,
  })

  updateSensorsQuery({
    sensorFiltersProps,
    client,
    feedItemAdded,
  })

  updateSensor({
    client,
    feedItemAdded,
  })
}
