import _ from 'lodash'
import format from './format'

export default ({
  reading: {
    readingType: { id },
    value,
  },
  reading,
  sensor: { readingRanges },
  currentUser,
}) => {
  const readingRange = _.find(readingRanges, { readingTypeId: id })
  if (!readingRange) return

  const { minValue, maxValue } = readingRange

  return `range: ${format({
    value: minValue,
    reading,
    currentUser,
  })} - ${format({
    value: maxValue,
    reading,
    currentUser,
  })}`
}
