import _ from 'lodash'
import query from '../query'

export default (cache, { data: { duplicateRule } }) => {
  let prev

  try {
    prev = cache.readQuery({ query })
  } catch (e) {}

  if (!prev) return

  const { rules } = prev

  cache.writeQuery({
    query,
    data: { rules: _.concat(rules, duplicateRule) },
  })
}
