import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import useCurrentUser from '../../../hooks/useCurrentUser'
import Layout from '../../Layout'
import Loading from '../../Loading'
import Error from '../../Error'
import EditHeader from '../../Edit/Header'
import Form from '../Form'
import query from './lib/query'
import mutation from './lib/mutation'
import initialValues from './lib/initialValues'
import onCompleted from './lib/onCompleted'

export default ({
  match: {
    params: { id },
  },
}) => {
  const { currentUser } = useCurrentUser()

  const [mutate, mutationProps] = useMutation(mutation, {
    onCompleted: onCompleted({ currentUser }),
  })

  const { loading, error, data } = useQuery(query, {
    variables: {
      id,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Layout>
      <EditHeader>Edit user</EditHeader>

      <Form
        initialValues={initialValues(data.user)}
        onSubmit={(data) => mutate({ variables: { id, data } })}
      />
      {mutationProps.error && <Error leftAligned />}
      {mutationProps.data && <Redirect to='/users' />}
    </Layout>
  )
}
