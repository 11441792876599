import React from 'react'
import CreatableSelect from 'react-select/creatable'
import Select from '../Select'
import value from './lib/value'
import onChange from './lib/onChange'

export default (props) => (
  <Select
    {...props}
    component={CreatableSelect}
    onChange={onChange}
    value={value}
  />
)
