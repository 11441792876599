import { gql } from 'graphql.macro'
import FeedItemFragment from './FeedItemFragment'
import EventFullWithSensorLastEventFragment from '../../Events/lib/EventFullWithSensorLastEventFragment'
import TriggerFragment from '../../Triggers/lib/TriggerFragment'

export default gql`
  fragment FeedItemWithSensorLastEventFragment on FeedItem {
    ...FeedItemFragment

    item {
      ...EventFullWithSensorLastEventFragment
      ...TriggerFragment
    }
  }

  ${FeedItemFragment}
  ${EventFullWithSensorLastEventFragment}
  ${TriggerFragment}
`
