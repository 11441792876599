
export default ({
  isDroppableBetweenGroups,
  groupKey,
  group: {
    [groupKey]: groupedBy,
  },
}) => {
  if (isDroppableBetweenGroups) return 'SingleGroup'

  if (!groupedBy) return 'SingleGroup-null'

  return `SingleGroup-${groupedBy.id}`
}
