import React from 'react'
import EditList from '../Edit/List'

export default ({ gateways }) => (
  <EditList
    header='Gateways'
    label={({ item: { name, externalId } }) =>
      `${name} (${externalId || 'no external id'})`
    }
    items={gateways}
  />
)
