import { gql } from 'graphql.macro'
import UnitFragment from '../../../lib/UnitFragment'

export default gql`
  {
    units {
      ...UnitFragment
    }
  }

  ${UnitFragment}
`
