import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../Layout'
import Heading from '../../Heading'
import Button from '../../Button'
import ListHeader from '../../List/Header'
import Items from './Items'

export default () => (
  <Layout>
    <ListHeader>
      <Heading>Users</Heading>
      <Button $primary as={Link} to='/users/new'>
        New User
      </Button>
    </ListHeader>

    <Items />
  </Layout>
)
