import _ from 'lodash'

export default ({ formikProps: { form, field }, mutate }) => ({
  filesUploaded,
}) => {
  const value = _.get(filesUploaded, '[0].url')
  form.setFieldValue(field.name, value)
  mutate({
    variables: {
      data: {
        [field.name]: value,
      },
    },
  })
}
