import React from 'react'
import styled from 'styled-components'
import Button from '../../../../Button'
import CreatableSelect from '../../../../Input/CreatableSelect'
import ReadingValueInput from '../../../../Input/Reading/Value'
import getOptionLabel from './lib/getOptionLabel'
import getNewOptionData from './lib/getNewOptionData'
import readingTypes from './lib/readingTypes'
import readingType from './lib/readingType'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
`

const ButtonContainer = styled.div``

export default ({ value: { name, value }, index, data, arrayHelpers }) => (
  <Container>
    <CreatableSelect
      label='Name'
      name={`values[${index}].name`}
      options={readingTypes(data)}
      getOptionLabel={getOptionLabel}
      getOptionValue={({ name }) => name}
      getNewOptionData={getNewOptionData}
    />

    <ReadingValueInput
      label='Value'
      name={`values[${index}].value`}
      readingType={readingType({ data, name })}
    />

    <ButtonContainer>
      <Button onClick={() => arrayHelpers.remove(index)}>Remove</Button>
    </ButtonContainer>
  </Container>
)
