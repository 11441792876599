import _ from 'lodash'
import moment from 'moment-timezone'
import getEvents from './events'
import getDatetime from './datetime'

export default ({
  day,
  startDate,
  data,
  data: {
    sensor: { aggregatedReadings },
  },
}) => {
  const dayEvents = _.filter(aggregatedReadings, ({ timestamp }) =>
    moment
      .utc(timestamp)
      .local()
      .isBetween(
        moment.utc(startDate).local().startOf('day').add(day, 'days'),
        moment
          .utc(startDate)
          .local()
          .startOf('day')
          .add(day + 1, 'days')
      )
  )

  const byHour = _.groupBy(dayEvents, ({ timestamp }) =>
    moment.utc(timestamp).local().hour()
  )

  return {
    day,
    data: _.reverse(
      _.flatMap(_.times(24), (hour) => {
        const datetime = getDatetime({ startDate, day, hour })
        const events = getEvents({ byHour, hour })

        return {
          hour: 1,
          datetime,
          events,
        }
      })
    ),
  }
}
