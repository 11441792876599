export default ({
  form: {
    values,
  },
  field: {
    name,
  },
}) => (
  values[name]
)
