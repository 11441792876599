import { gql } from 'graphql.macro'

export default gql`
  fragment SensorBaseFragment on Sensor {
    id
    name
    manufacturerLabel
    active
    color
    externalId
    photoImageUrl
    manufacturerId
    modelId
    index
  }
`
