import _ from 'lodash'

export default ({ sensorFiltersProps: { filters }, feedItemAdded }) => {
  const filter = _.find(filters, { type: 'Status' })

  if (!filter) return true
  if (!feedItemAdded.item.sensor) return true

  return feedItemAdded.item.sensor.status === filter.id
}
