import { gql } from 'graphql.macro'
import ActionFragment from '../../../../lib/ActionFragment'

export default gql`
  mutation DeleteAction($id: ID!) {
    deleteAction(id: $id) {
      ...ActionFragment
    }
  }

  ${ActionFragment}
`
