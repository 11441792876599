import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import EditList from '../../../Edit/List'
import accountsQuery from '../../../Accounts/List/Items/lib/query'
import sitesQuery from '../../../Sites/List/Items/lib/query'
import Loading from '../../../Loading'
import Error from '../../../Error'
import Actions from './Actions'

export default ({ formikProps }) => {
  const accountsQueryProps = useQuery(accountsQuery)
  const sitesQueryProps = useQuery(sitesQuery)

  if (_.some([accountsQueryProps, sitesQueryProps], 'loading')) {
    return <Loading />
  }

  if (_.some([accountsQueryProps, sitesQueryProps], 'error')) {
    return <Error />
  }

  return (
    <>
      <EditList
        header='Account access'
        items={accountsQueryProps.data.accounts}
        actions={(item) => <Actions formikProps={formikProps} item={item} />}
      />
      <EditList
        header='Site access'
        items={sitesQueryProps.data.sites}
        actions={(item) => <Actions formikProps={formikProps} item={item} />}
      />
    </>
  )
}
