import * as yup from 'yup'

export default (value) => {
  let error

  try {
    yup
      .number()
      .typeError('You must specify a number.')
      .required('Required field')
      .validateSync(value)
  } catch(e) {
    error = e.message
  }

  return error
}
