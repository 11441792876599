import React from 'react'
import { FiUser, FiLogOut } from 'react-icons/fi'
import { IoIosContact } from 'react-icons/io'
import Button from '../Button'
import Menu from '../Menu'

export default ({ currentUser: { id, name }, currentUser, closeTopMenu }) => (
  <Menu icon={IoIosContact} title={name} border='light2' onClick={closeTopMenu}>
    <Button icon={FiUser} to={`/users/edit/${id}`}>
      Profile settings
    </Button>
    <Button icon={FiLogOut} to='/logout'>
      Logout
    </Button>
  </Menu>
)
