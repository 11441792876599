import { gql } from 'graphql.macro'
import ModelFragment from '../../../lib/ModelFragment'

export default gql`
  {
    models {
      ...ModelFragment
    }
  }

  ${ModelFragment}
`
