export default ({ lowerThreshold, upperThreshold }) => ({
  type: 'boolean',
  condition: {
    termOne: {
      type: 'boolean',
      condition: {
        termOne: {
          type: 'timestamp',
        },
        comparison: 'gt',
        termTwo: {
          type: 'plain',
          value: lowerThreshold,
        },
      },
    },
    comparison: 'and',
    termTwo: {
      type: 'boolean',
      condition: {
        termOne: {
          type: 'timestamp',
        },
        comparison: 'lt',
        termTwo: {
          type: 'plain',
          value: upperThreshold,
        },
      },
    },
  },
})
