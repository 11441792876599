import _ from 'lodash'
import initialValueDefaults from '../../lib/initialValueDefaults'

export default ({
  name,
  active,
  areaId,
  manufacturerId,
  modelId,
  siteId,
  photoImageUrl,
  externalId,
  location,
}) => _.defaults({
    name,
    active,
    areaId,
    manufacturerId,
    modelId,
    siteId,
    photoImageUrl,
    externalId,
    location: _.pick(location, 'x', 'y'),
}, initialValueDefaults)
