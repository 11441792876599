import React from 'react'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import Button from '../../../Button'
import DeletionInProgress from './DeletionInProgress'
import mutation from './lib/mutation'
import onClick from './lib/onClick'
import label from './lib/label'
import disabled from './lib/disabled'

const Container = styled.div`
  padding-bottom: 60px;
`

export default ({ sensor: { id, deleteHistoryJob, deleteJob }, sensor }) => {
  const [mutate, { loading }] = useMutation(mutation, {
    variables: {
      id,
    },
  })

  if (deleteHistoryJob) {
    return (
      <DeletionInProgress
        heading='Sensor history deletion in progress...'
        job={deleteHistoryJob}
      />
    )
  }

  if (deleteJob) {
    return (
      <DeletionInProgress
        heading='Sensor deletion in progress...'
        job={deleteJob}
      />
    )
  }

  return (
    <Container>
      <Button
        danger
        disabled={disabled({ loading })}
        onClick={onClick({ mutate, loading })}
      >
        {label({ loading, sensor })}
      </Button>
    </Container>
  )
}
