import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Button from '../../Form/Button'

const Container = styled.div`
  padding: 20px 0;
  border-top: ${(props) => props.theme.borders.light4};
  border-bottom: ${(props) => props.theme.borders.light4};
  display: flex;
  flex-direction: column;

  ${breakpoint('md')`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`

const Label = styled.div`
  padding-bottom: 10px;

  ${breakpoint('md')`
    flex-grow: 1;
    padding-bottom: 0;
  `}
`

export default ({
  item,
  index,
  label,
  onDelete,
  onDeleteProps = {},
  actions,
}) => (
  <Container>
    <Label>
      {_.isFunction(label)
        ? label({ item, index })
        : _.get(item, label || 'name')}
    </Label>
    {actions && actions(item)}
    {onDelete && (
      <Button size='small' onClick={() => onDelete(item)} {...onDeleteProps}>
        Delete
      </Button>
    )}
  </Container>
)
