export default {
  black: '#000',
  white: '#fff',
  primary: '#000',
  secondary: '#5C5C5C',
  lightGrey: '#E6E6E6',
  grey: '#5C5C5C',
  grey1: '#ffffff',
  grey2: 'hsla(0, 0%, 95%, 1)',
  grey5: 'hsla(0, 0%, 50%, 1)',
  grey7: 'hsla(0, 0%, 30%, 1)',
  grey9: '#000',
  violet: '#8250E6',
  orange5: '#FCBE2C',
  transparentViolet: 'hsla(260, 75%, 61%, 0.3)',
  transparentGrey: 'hsla(0, 0%, 0%, 0.15)',
  transparentGrey1: 'hsla(0, 0%, 0%, 0.01)',
  transparentGrey2: 'hsla(0, 0%, 97%, 1)',
  transparentGrey3: 'hsla(0, 0%, 98%, 1)',
  transparent: 'hsla(0, 0%, 0%, 0)',
  light1: '#C8D3D8',
  light2: '#8FA6B2',
  light3: '#C7D2D8',
  light4: '#EAEEF0',
  light5: '#EFF3F5',
  light6: 'hsla(200, 23%, 98%, 1)',
  dark1: 'hsla(201, 19%, 55%, 1)',
  dark6: 'hsla(0, 0%, 0%, 0.15)',
  dark7: '#10384F',
  dark8: '#E5E9EB',
  dark8Transparent: 'hsla(200, 13%, 86%, 0.5)',
  reddish: '#FF3723',
  red2: 'hsla(5, 100%, 98%, 1)',
  green: 'hsla(163, 72%, 41%, 1)',
  yellow: 'hsla(48, 100%, 45%, 1)',
  blue: '#2684FF',
  rules: {
    temperatureOutsideReadingRange: 'hsla(20, 50%, 95%, 1)',
  },
  types: {
    button: '#f2e6ff',
    temperature: '#ffe6e6',
    ambientTemperature: 'hsla(30, 100%, 92%, 1)',
    motion: '#ffe6f7',
    absence: '#e0ebeb',
    presence: '#e6fff5',
    voltage: '#ffffe6',
    magnetOpened: 'hsla(80, 100%, 90%, 1)',
    signalStrength: '#e6e6ff',
    interval: 'hsla(225, 99%, 90%, 1)',
    humidity: 'hsla(190, 99%, 90%, 1)',
    door: 'hsla(31, 98%, 88%, 1)',
    hub: 'hsla(10, 98%, 88%, 1)',
  },
}
