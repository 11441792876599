import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import selectableColors from '../../../../theme/selectableColors'

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-grow: 1;
`

const Image = styled.img`
  width: 50px;
  max-height: 50px;
  margin-right: 5px;
`

const Name = styled.div``

const Meta = styled.div`
  display: flex;
  padding-top: 5px;
  flex-direction: column;
`

const Info = styled.div`
  padding: 2.5px 0;
  font-size: 12px;
  color: ${(props) => props.theme.colors.grey5};
`

const ColorDot = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 999px;
  margin-right: 5px;
  background-color: ${(props) => selectableColors[props.color]};
  flex-shrink: 0;
`

const InnerContainer = styled.div``

export default ({ name, imageUrl, meta, color, ...rest }) => (
  <Container>
    {!_.isUndefined(color) && <ColorDot color={color} />}
    {_.get(rest, imageUrl) && <Image src={_.get(rest, imageUrl)} />}

    <InnerContainer>
      <Name>{name}</Name>

      <Meta>
        {_.map(meta, (m, index) => (
          <Info key={index}>
            {_.isString(m) ? _.get(rest, m, '') : m(rest)}
          </Info>
        ))}
      </Meta>
    </InnerContainer>
  </Container>
)
