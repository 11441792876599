import _ from 'lodash'
import order from './order'

export default ({
  items,
  sitesData: {
    sites,
  },
  includeSiteNull,
}) => (
  _.compact(
    _.concat(
      _.map(sites, (site) => ({
        site,
        items: order(_.filter(items, { siteId: site.id })),
      })),
      includeSiteNull && {
        site: {
          id: null,
        },
        items: order(_.filter(items, { siteId: null })),
      },
    )
  )
)
