import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import AggregatedReading from './AggregatedReading'

const Container = styled.div`
  padding-top: 20px;
`

export default ({
  data: {
    sensor: { aggregatedReadings },
  },
  isTimestampUtc,
  currentUser,
}) => (
  <Container>
    {_.map(aggregatedReadings, (aggregatedReading, i) => (
      <AggregatedReading
        key={i}
        aggregatedReading={aggregatedReading}
        isTimestampUtc={isTimestampUtc}
        currentUser={currentUser}
      />
    ))}
  </Container>
)
