import { gql } from 'graphql.macro'
import TermFourthLayerFragment from '../../Terms/lib/TermFourthLayerFragment'
import PeriodFragment from '../../Periods/lib/PeriodFragment'

export default gql`
  fragment ConditionFourthLayerFragment on Condition {
    id
    comparison

    termOne {
      ...TermFourthLayerFragment
    }

    termTwo {
      ...TermFourthLayerFragment
    }

    period {
      ...PeriodFragment
    }
  }

  ${TermFourthLayerFragment}
  ${PeriodFragment}
`
