import React from 'react'
import styled from 'styled-components'
import { ResponsiveContainer, BarChart, Bar, Tooltip } from 'recharts'
import colors from '../../../../../../../../../theme/colors'
import CustomTooltip from '../../CustomTooltip'
import serialize from './lib/serialize'

const Container = styled.div`
  margin-left: -5px;
  display: flex;
`

const ChildContainer = styled.div`
  width: 0;
  flex-grow: 1;
`

export default ({ data, date }) => (
  <Container>
    <ChildContainer>
      <ResponsiveContainer width='100%' height={50}>
        <BarChart
          data={serialize({ data, date })}
          barSize={1}
          barGap={0}
          barCategoryGap={0}
        >
          <Bar
            dataKey='eventsExist'
            fill={colors.blue}
            background={{ fill: colors.light5 }}
          />
          <Bar
            dataKey='triggersExist'
            fill={colors.reddish}
            background={{ fill: colors.light5 }}
          />
          <Tooltip wrapperStyle={{ top: 50 }} content={<CustomTooltip />} />
        </BarChart>
      </ResponsiveContainer>
    </ChildContainer>
  </Container>
)
