import _ from 'lodash'
import config from './config'

export default ({
  type: {
    name,
  },
}) => {
  const item = _.find(config, { name })
  if (!item) return null

  return item.backgroundColor || 'white'
}
