export default {
  name: '',
  active: true,
  type: 'webhook',
  url: '',
  accountId: '',
  sparkPostApiKey: '',
  sandbox: false,
  from: '',
  recipients: [],
  subject: '',
  text: '',
  webhookMethod: 'post',
  webhookAuth: false,
  webhookAuthUsername: '',
  webhookAuthPassword: '',
  webhookHeaders: [{
    key: 'Content-Type',
    value: 'application/json',
  }],
  pushoverToken: '',
  pushoverUser: '',
  pushoverMessage: '',
  pushoverDevice: '',
  pushoverTitle: '',
  pushoverUrl: '',
  pushoverUrlTitle: '',
  pushoverPriority: 0,
  pushoverSound: 'pushover',
}
