import _ from 'lodash'
import initialValueDefaults from '../../lib/initialValueDefaults'

const serializeRecipients = (recipients) => (
  _.map(recipients, ({ email }) => ({
    email,
    name: email,
  }))
)

const serializeWebhookHeaders = (webhookHeaders) => (
  _.map(webhookHeaders, ({ key, value }) => ({
    key,
    value,
  }))
)

export default ({
  name,
  active,
  type,
  accountId,

  url,
  sparkPostApiKey,
  sandbox,
  from,
  recipients,
  subject,
  text,
  webhookMethod,
  webhookAuth,
  webhookAuthUsername,
  webhookAuthPassword,
  webhookHeaders,

  pushoverToken,
  pushoverUser,
  pushoverMessage,
  pushoverDevice,
  pushoverTitle,
  pushoverUrl,
  pushoverUrlTitle,
  pushoverPriority,
  pushoverSound,
}) => _.defaults({
  name,
  active,
  type,
  accountId,

  url,
  sparkPostApiKey,
  sandbox,
  from,
  recipients: serializeRecipients(recipients),
  subject,
  text,
  webhookMethod,
  webhookAuth,
  webhookAuthUsername,
  webhookAuthPassword,
  webhookHeaders: serializeWebhookHeaders(webhookHeaders),

  pushoverToken,
  pushoverUser,
  pushoverMessage,
  pushoverDevice,
  pushoverTitle,
  pushoverUrl,
  pushoverUrlTitle,
  pushoverPriority,
  pushoverSound,
}, initialValueDefaults)
