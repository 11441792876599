import { gql } from 'graphql.macro'
import KeyFragment from '../../Keys/lib/KeyFragment'
import GatewayBaseFragment from '../../Gateways/lib/GatewayBaseFragment'

export default gql`
  fragment HubFragment on Hub {
    id
    name
    localIpAddress
    publicIpAddress
    accountId

    keys {
      ...KeyFragment
    }

    gateways {
      ...GatewayBaseFragment
    }
  }

  ${KeyFragment}
  ${GatewayBaseFragment}
`
