import _ from 'lodash'
import initialValues from './initialValues'

export default ({
  queryPath,
  data: { [queryPath]: items },
  arrayHelpers,
  onNew,
}) => ({ itemId }, { resetForm }) => {
  onNew({
    arrayHelpers,
    item: _.find(items, { id: itemId }),
  })
  resetForm({ values: initialValues })
}
