import _ from 'lodash'

export default ({
  currentUser: {
    gateways,
  },
}) => ({
  group: {
    gateway: {
      id,
    },
  },
}) => {
  if (id === null) return false

  return !_.some(gateways, { id })
}
