import { gql } from 'graphql.macro'
import ModelFragment from '../../lib/ModelFragment'

export default gql`
  mutation CreateModel($data: ModelDataInput!) {
    createModel(data: $data) {
      ...ModelFragment
    }
  }

  ${ModelFragment}
`
