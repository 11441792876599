import _ from 'lodash'
import { celsiusToFahrenheit } from 'temperature'

export default ({
  reading: { value },
  modelReadingType: {
    unit: { slug, symbol },
  },
  currentUser,
}) => {
  if (currentUser.systemOfUnits === 'imperial') {
    return `${_.round(celsiusToFahrenheit(value), 1)} °F`
  }

  return `${_.round(value, 1)} °C`
}
