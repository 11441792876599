import { gql } from 'graphql.macro'
import AccountFragment from '../../lib/AccountFragment'

export default gql`
  mutation UpdateAccount($id: ID!, $data: AccountDataInput!) {
    updateAccount(id: $id, data: $data) {
      ...AccountFragment
    }
  }

  ${AccountFragment}
`
