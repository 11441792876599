import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;

  ${breakpoint('md')`
    padding: 20px 40px 40px;
  `}
`
