import _ from 'lodash'

const all = ({
  sensors,
}) => (
  _.uniq(_.flattenDeep(_.map(sensors, (sensor) => {
    const eventTypes = _.get(sensor, 'model.eventTypes')
    return _.map(eventTypes, 'readingTypes')
  })))
)

export default (data) => (
  _.compact(_.reject(all(data), { name: 'Signal Strength' }))
)
