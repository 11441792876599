import { gql } from 'graphql.macro'
import PeriodFragment from '../../Periods/lib/PeriodFragment'
import ReadingTypeFragment from '../../ReadingTypes/lib/ReadingTypeFragment'
import ConditionFifthLayerFragment from '../../Conditions/lib/ConditionFifthLayerFragment'

export default gql`
  fragment TermFourthLayerFragment on Term {
    id
    type
    value

    period {
      ...PeriodFragment
    }

    readingType {
      ...ReadingTypeFragment
    }

    condition {
      ...ConditionFifthLayerFragment
    }
  }

  ${PeriodFragment}
  ${ReadingTypeFragment}
  ${ConditionFifthLayerFragment}
`
