import React, { forwardRef } from 'react'
import Moment from 'react-moment'
import styled from 'styled-components'
import Event from './Event'
import Trigger from './Trigger'
import isItemLoaded from '../lib/isItemLoaded'

const Container = styled.div`
  padding-top: 20px;
`

const InnerContainer = styled.div`
  background-color: ${(props) => props.theme.colors.light5};
  border-radius: 8px;
  padding: 20px;
`

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
`

const backgroundColor = (props) =>
  props.type === 'Event'
    ? props.theme.colors.lightGrey
    : props.theme.colors.reddish

const color = (props) =>
  props.type === 'Event' ? props.theme.colors.black : props.theme.colors.white

const Type = styled.div`
  font-size: 12px;
  background-color: ${backgroundColor};
  color: ${color};
  padding: 5px;
  border-radius: 4px;
  font-weight: 600;
`

const Time = styled.div`
  font-size: 14px;
`

export default forwardRef(({ index, style, data: { items, hasNext } }, ref) => {
  if (!isItemLoaded({ hasNext, items })) return 'Loading'

  const {
    item: { __typename, ...itemRest },
    ...rest
  } = items[index]

  return (
    <Container ref={ref} style={style}>
      <InnerContainer {...rest}>
        <TopContainer>
          <Time>
            <Moment fromNow>{itemRest.timestamp}</Moment>
          </Time>
          <Type type={__typename}>{__typename}</Type>
        </TopContainer>

        {__typename === 'Event' ? (
          <Event event={itemRest} />
        ) : (
          <Trigger trigger={itemRest} />
        )}
      </InnerContainer>
    </Container>
  )
})
