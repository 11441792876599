import namePrefix from './namePrefix'
import isInvertedConfig from './isInvertedConfig'

const REGULAR = ({ formikProps }) => ({
  lower: `${namePrefix({
    formikProps,
  })}condition.termOne.condition.termTwo.value`,
  upper: `${namePrefix({
    formikProps,
  })}condition.termTwo.condition.termTwo.value`,
})

const INVERTED = ({ formikProps }) => ({
  lower: `${namePrefix({
    formikProps,
  })}condition.termTwo.condition.termOne.condition.termTwo.condition.termTwo.value`,
  upper: `${namePrefix({
    formikProps,
  })}condition.termTwo.condition.termTwo.condition.termOne.condition.termTwo.value`,
})

export default ({ formikProps, thresholdName }) => {
  if (isInvertedConfig({ formikProps })) {
    return INVERTED({ formikProps })[thresholdName]
  }

  return REGULAR({ formikProps })[thresholdName]
}
