import React from 'react'
import { Formik, Form } from 'formik'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import TextInput from '../../Input/Text'
import TimezoneInput from '../../Input/Timezone'
import SystemOfUnitsInput from '../../Input/SystemOfUnits'
import Roles from './Roles'
import validationSchema from './lib/validationSchema'

export default ({ children, ...rest }) => (
  <Formik validationSchema={validationSchema} {...rest}>
    {(formikProps) => (
      <Form>
        <FormContainer>
          <TextInput label='Name' name='name' $maxWidth='300px' />
          <TextInput
            label='Email'
            name='email'
            type='email'
            $maxWidth='300px'
          />
          <TextInput
            label='Password'
            name='password'
            type='password'
            $maxWidth='300px'
          />
          <TimezoneInput name='timezone' $maxWidth='500px' />
          <SystemOfUnitsInput name='systemOfUnits' $maxWidth='300px' />
          {console.log({ formikProps })}

          <Roles formikProps={formikProps} />

          {children}

          <FormButton />
        </FormContainer>
      </Form>
    )}
  </Formik>
)
