import { gql } from 'graphql.macro'
import SiteFragment from '../../lib/SiteFragment'

export default gql`
  mutation CreateSite($data: SiteDataInput!) {
    createSite(data: $data) {
      ...SiteFragment
    }
  }

  ${SiteFragment}
`
