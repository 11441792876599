import React from 'react'
import _ from 'lodash'
import Select from 'react-select'
import Creatable from 'react-select/creatable'
import { Field } from 'formik'
import { withTheme } from 'styled-components'
import Container from './Container'
import LabelContainer from './LabelContainer'
import Error from './Error'

const optionBackgroundColor = (theme, { isSelected, isFocused }) => {
  if (isSelected) return theme.colors.black
  if (isFocused) return theme.colors.dark6

  return theme.colors.white
}

export const styles = ({ theme, $maxWidth, backgroundColor, border }) => ({
  container: (provided) => ({
    ...provided,
    maxWidth: $maxWidth,
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: theme.colors[backgroundColor] || backgroundColor,
    border: _.isFunction(border) ? border({ theme }) : border,
    lineHeight: '20px',
  }),
  menu: (provided) => ({
    ...provided,
    maxWidth: $maxWidth,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: optionBackgroundColor(theme, state),
  }),
})

const defaultOnChange = ({ form, field }) => (option) => {
  form.setFieldValue(field.name, option.value)
}

const defaultGetNewOptionData = (option) => ({
  id: option,
  name: option,
  __isNew__: true,
})

const defaultValue = ({ options, getOptionValue, field }) => {
  if (_.isArray(field.value)) return field.value
  if (!options) return ''

  return _.find(
    _.compact(_.concat(options, _.flatMap(options, 'options'))),
    (option) => {
      const fieldValue = field.value && getOptionValue(field.value)
      return getOptionValue(option) === (fieldValue || field.value)
    }
  )
}

const defaultGetOptionValue = ({ value }) => value
const defaultGetOptions = ({ options }) => options

const orderOptions = ({ options }) =>
  _.orderBy(
    options,
    [(option) => _.isEmpty(_.trim(_.get(option, 'name'))), 'name'],
    ['desc', 'asc']
  )

export default withTheme(
  ({
    $maxWidth,
    $minWidth = 'inherit',
    theme,
    label,
    options,
    isCreatable,
    getOptions = defaultGetOptions,
    onChange = defaultOnChange,
    getNewOptionData = defaultGetNewOptionData,
    value = defaultValue,
    getOptionValue = defaultGetOptionValue,
    component = isCreatable ? Creatable : Select,
    paddingBottom,
    paddingRight,
    backgroundColor = 'light5',
    labelColor = 'initial',
    border = 0,
    ...rest
  }) => {
    const Component = component

    return (
      <Field {...rest}>
        {(formikProps) => (
          <Container
            $paddingBottom={paddingBottom}
            $paddingRight={paddingRight}
          >
            {label && (
              <LabelContainer color={labelColor}>{label}</LabelContainer>
            )}
            <Component
              styles={styles({ theme, $maxWidth, backgroundColor, border })}
              options={orderOptions({
                options: getOptions({ options, ...formikProps }),
              })}
              value={value({ options, getOptionValue, ...formikProps })}
              onChange={onChange(formikProps)}
              getNewOptionData={getNewOptionData}
              getOptionValue={getOptionValue}
              {...rest}
            />
            <Error name={formikProps.field.name} />
          </Container>
        )}
      </Field>
    )
  }
)
