import _ from 'lodash'
import query from './query'

export default (cache, {
  data: {
    deleteHub: {
      id,
    },
  },
}) => {
  const { hubs } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { hubs: _.reject(hubs, { id }) },
  })
}
