import { gql } from 'graphql.macro'
import FeedItemWithSensorLastEventFragment from '../../../../../FeedItems/lib/FeedItemWithSensorLastEventFragment'

export default gql`
  subscription onFeedItemAdded {
    feedItemAdded {
      ...FeedItemWithSensorLastEventFragment
    }
  }

  ${FeedItemWithSensorLastEventFragment}
`
