import React, { useState } from 'react'
import styled from 'styled-components'
import Groups from './Groups'
import Select from './Select'

const Container = styled.div`
  padding: 20px 0;
`

const Heading = styled.div`
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 5px;
`

export default ({ formikProps, readingTypesData, eventTypesData }) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Container>
      <Heading>Select a preset</Heading>

      {isOpen ? (
        <Groups
          readingTypesData={readingTypesData}
          eventTypesData={eventTypesData}
          formikProps={formikProps}
          setIsOpen={setIsOpen}
        />
      ) : (
        <Select
          readingTypesData={readingTypesData}
          eventTypesData={eventTypesData}
          formikProps={formikProps}
          setIsOpen={setIsOpen}
        />
      )}
    </Container>
  )
}
