import React from 'react'
import { Formik } from 'formik'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Header from './Header'
import Chart from './Chart'
import initialValues from './lib/initialValues'

const Container = styled.div`
  padding: 0 10px;

  ${breakpoint('md')`
    padding: 0 40px 150px;
  `}
`

export default ({ sensor, readingTypes, eventTypes }) => (
  <Formik initialValues={initialValues({ readingTypes, eventTypes, sensor })}>
    {(formikProps) => (
      <Container>
        <Header
          formikProps={formikProps}
          readingTypes={readingTypes}
          eventTypes={eventTypes}
        />
        <Chart formikProps={formikProps} sensor={sensor} />
      </Container>
    )}
  </Formik>
)
