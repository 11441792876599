import sensor from './sensor'
import eventType from './eventType'
import area from './area'
import readingType from './readingType'
import status from './status'

export default (opts) => {
  if (!sensor(opts)) return false
  if (!eventType(opts)) return false
  if (!area(opts)) return false
  if (!readingType(opts)) return false
  if (!status(opts)) return false

  return true
}
