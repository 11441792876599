import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import Loading from '../../../Loading'
import Error from '../../../Error'
import readingTypesQuery from '../../../ReadingTypes/List/Items/lib/query'
import Group from '../../../Rules/Form/Group'
import Description from '../../../Input/Description'
import RelationListInput from '../../../Input/RelationList'
import Label from './Label'
import onNew from './lib/onNew'

export default ({
  formikProps: {
    values: { modelId },
  },
  formikProps,
}) => {
  if (!modelId) return null

  const { loading, error, data } = useQuery(readingTypesQuery)

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Group label='Reading ranges'>
      <Description>
        Add a reading range that is normal for this sensor.
      </Description>

      <RelationListInput
        path='readingRanges'
        query={readingTypesQuery}
        variables={{
          filter: {
            type: 'Float',
            modelId,
          },
        }}
        queryPath='readingTypes'
        formikProps={formikProps}
        header='Reading type'
        onNew={onNew}
        getKey={({ item: { readingTypeId } }) => readingTypeId}
        onDeleteProps={{ style: { marginTop: '10px' } }}
        isSelected={({ item: { id }, values }) =>
          _.some(values, { readingTypeId: id })
        }
        label={({ item, index }) => (
          <Label
            item={item}
            index={index}
            data={data}
            formikProps={formikProps}
          />
        )}
      />
    </Group>
  )
}
