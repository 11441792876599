import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import Loading from '../../../Loading'
import Error from '../../../Error'
import ListItems from '../../../List/Items'
import query from './lib/query'
import deleteMutation from './lib/deleteMutation'
import deleteUpdate from './lib/deleteUpdate'

export default () => {
  const [onDelete, mutationProps] = useMutation(deleteMutation, {
    update: deleteUpdate,
  })

  const { loading, error, data } = useQuery(query)

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <>
      <ListItems
        items={data.models}
        onDelete={onDelete}
        editUrl={({ id }) => `/models/edit/${id}`}
      />
      {mutationProps.error && <Error />}
    </>
  )
}
