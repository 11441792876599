import React from 'react'
import TextInput from '../../../Input/Text'
import Checkbox from '../../../Input/Checkbox'
import Select from '../../../Input/Select'
import HeaderInputs from './HeaderInputs'
import validate from './lib/validate'
import webhookMethodOptions from './lib/webhookMethodOptions'

export default ({ values: { webhookAuth }, values }) => (
  <>
    <TextInput
      label='URL'
      name='url'
      validate={validate('url')}
      $maxWidth='500px'
    />

    <Select
      label='Method'
      name='webhookMethod'
      options={webhookMethodOptions}
      $maxWidth='150px'
    />

    <HeaderInputs values={values} />

    <Checkbox label='HTTP Basic auth' name='webhookAuth' />

    {webhookAuth && (
      <>
        <TextInput
          label='Username'
          name='webhookAuthUsername'
          $maxWidth='300px'
        />

        <TextInput
          label='Password'
          name='webhookAuthPassword'
          $maxWidth='300px'
        />
      </>
    )}
  </>
)
