import _ from 'lodash'
import query from '../../List/Items/lib/query'

export default (cache, {
  data: {
    createAccount,
  },
}) => {
  const { accounts } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { accounts: _.concat(accounts, createAccount) },
  })
}
