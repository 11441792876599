import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import Loading from '../../../Loading'
import Error from '../../../Error'
import useSensorFilters from '../../../SensorFilters/useSensorFilters'
import query from './lib/query'

export default ({ children }) => {
  const { filters } = useSensorFilters()

  const { loading, error, data } = useQuery(query, {
    variables: {
      filters,
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  if (loading && _.isEmpty(data)) return <Loading />
  if (error) return <Error />

  return children({
    sensors: data.sensors,
    gateways: _.uniqBy(_.flatMap(data.sensors, 'gateways'), 'id'),
  })
}
