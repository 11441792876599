import { gql } from 'graphql.macro'
import EventFragment from '../../Events/lib/EventFragment'
import RuleFragment from '../../Rules/lib/RuleFragment'

export default gql`
  fragment TriggerFragment on Trigger {
    id
    createdAt
    timestamp: createdAt

    event {
      ...EventFragment
    }

    rule {
      ...RuleFragment
    }
  }

  ${EventFragment}
  ${RuleFragment}
`
