import { gql } from 'graphql.macro'
import SensorFragment from '../../../../../../Sensors/lib/SensorFragment'

export default gql`
  query Sensor($id: ID!, $scope: ScopeInput!, $startDate: String!, $endDate: String!, $resolution: String!) {
    sensor(id: $id) {
      ...SensorFragment

      aggregatedReadings(scope: $scope, startDate: $startDate, endDate: $endDate, resolution: $resolution) {
        timestamp
        type
        value
      }
    }
  }

  ${SensorFragment}
`
