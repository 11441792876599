import disabled from './disabled'

export default ({ mutate, loading }) => () => {
  if (disabled({ loading })) return
  if (
    !window.confirm(
      'Are you sure?\nNote: Keep in mind that full deletion might take some time.'
    )
  )
    return

  mutate()
}
