import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Heading from '../../../Heading'
import ListHeader from '../../../List/Header'
import TypeNav from './TypeNav'

const Content = styled.div`
  padding: 0 10px;

  ${breakpoint('lg')`
    padding: 0 40px;
  `}
`

export default (props) => (
  <>
    <ListHeader>
      <Heading compact>Dashboard</Heading>
      <TypeNav />
    </ListHeader>

    <Content {...props} />
  </>
)
