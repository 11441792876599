import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const Container = styled.div`
  display: flex;
  font-size: 14px;
  padding: 5px 0;
  flex-direction: column;
  flex-grow: 0;

  ${breakpoint('md')`
    align-items: center;
    flex-direction: row;
  `}
`

const Title = styled.div`
  padding-right: 10px;
  min-width: 130px;
  font-weight: 600;
`

const Value = styled.div`
  font-family: monospace;
  padding-top: 5px;

  ${breakpoint('md')`
    padding-top: 0;
  `}
`

export default ({ title, ...rest }) => (
  <Container>
    <Title>{title}</Title>
    <Value {...rest} />
  </Container>
)
