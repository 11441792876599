import { gql } from 'graphql.macro'
import ActionFragment from '../../lib/ActionFragment'

export default gql`
  mutation CreateAction($data: ActionDataInput!) {
    createAction(data: $data) {
      ...ActionFragment
    }
  }

  ${ActionFragment}
`
