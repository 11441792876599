import _ from 'lodash'
import query from './query'

export default (cache, {
  data: {
    deleteUser: {
      id,
    },
  },
}) => {
  const { users } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { users: _.reject(users, { id }) },
  })
}
