import React from 'react'
import EditList from '../Edit/List'

export default ({ header = 'Sensors', sensors }) => (
  <EditList
    header={header}
    label={({ item: { name, externalId } }) =>
      `${name} (${externalId || 'no external id'})`
    }
    items={sensors}
  />
)
