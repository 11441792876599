import _ from 'lodash'
import query from '../../List/Items/lib/query'

export default (cache, {
  data: {
    createUser,
  },
}) => {
  const { users } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { users: _.concat(users, createUser) },
  })
}
