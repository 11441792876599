import { gql } from 'graphql.macro'
import SensorFragment from '../../lib/SensorFragment'

export default gql`
  mutation CreateSensor($data: SensorDataInput!) {
    createSensor(data: $data) {
      ...SensorFragment
    }
  }

  ${SensorFragment}
`
