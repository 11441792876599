import _ from 'lodash'
import query from './query'

export default (cache, {
  data: {
    deleteGateway: {
      id,
    },
  },
}) => {
  const { gateways } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { gateways: _.reject(gateways, { id }) },
  })
}
