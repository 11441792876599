import _ from 'lodash'

export default ({ availableFilters }) =>
  _.pickBy(
    {
      areaId: '',
      gatewayId: '',
      eventTypeId: '',
      readingTypeId: '',
      sensorId: '',
      status: '',
      modelId: '',
      manufacturerId: '',
      siteId: '',
    },
    (val, key) => _.includes(availableFilters, key)
  )
