import React, { useRef } from 'react'
import styled from 'styled-components'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'
import modifiers from './lib/modifiers'
import onDayChange from './lib/onDayChange'

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.colors.light5};
  padding: 5px;
  border-radius: 4px;
`

const Seperator = styled.div`
  padding: 0 5px;
  font-weight: 600;
  color: ${props => props.theme.colors.transparentGrey};
`


export default ({
  formikProps: {
    values: {
      startDate,
      endDate,
    },
  },
  formikProps,
}) => {
  const endDateDayPickerInputRef = useRef(null)

  return (
    <InputContainer>
      <DayPickerInput
        value={startDate}
        placeholder='From'
        format='YYYY-MM-DD'
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          selectedDays: [startDate, { startDate, endDate }],
          disabledDays: { after: endDate },
          toMonth: endDate,
          modifiers: modifiers({ formikProps }),
          onDayClick: () => endDateDayPickerInputRef.current.getInput().focus(),
        }}
        onDayChange={onDayChange({ name: 'startDate', formikProps })}
      />

      <Seperator>
        -
      </Seperator>

      <DayPickerInput
        ref={endDateDayPickerInputRef}
        value={endDate}
        placeholder='To'
        format='YYYY-MM-DD'
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          selectedDays: [startDate, { startDate, endDate }],
          disabledDays: { before: startDate },
          modifiers: modifiers({ formikProps }),
          month: startDate,
          fromMonth: startDate,
        }}
        onDayChange={onDayChange({ name: 'endDate', formikProps })}
      />
    </InputContainer>
  )
}
