import _ from 'lodash'
import arrayMove from 'array-move'
import insert from './insert'
import order from './order'

export default ({ reorderMutationName, query, queryName, gatewayIdKey }) => (
  cache,
  {
    data: {
      [reorderMutationName]: { id, index, [gatewayIdKey]: gatewayId },
      [reorderMutationName]: item,
    },
  }
) => {
  const { [queryName]: items } = cache.readQuery({ query })
  const gatewayItems = order(_.filter(items, { [gatewayIdKey]: gatewayId }))

  const sourceIndex = _.findIndex(gatewayItems, { id })
  let updatedRecords

  if (sourceIndex === -1) {
    if (_.isEmpty(gatewayItems)) {
      updatedRecords = [item]
    } else {
      updatedRecords = insert(gatewayItems, item, index)
    }
  } else {
    updatedRecords = arrayMove(gatewayItems, sourceIndex, index)
  }

  // const result = _.map(updatedRecords, (record, newIndex) => ({
  //   ...record,
  //   index: newIndex,
  // }))
  //
  const otherGatewayItems = _.reject(items, { [gatewayIdKey]: gatewayId })

  cache.writeQuery({
    query,
    data: {
      [queryName]: _.concat(updatedRecords, otherGatewayItems),
    },
  })
}
