import _ from 'lodash'

export default ({
  sensor: {
    model: { modelReadingTypes },
  },
  reading: {
    value,
    readingType: { id },
  },
}) =>
  _.find(modelReadingTypes, {
    readingTypeId: id,
  })
