import React from 'react'
import TextInput from '../../../Input/Text'
import TextArea from '../../../Input/TextArea'
import Checkbox from '../../../Input/Checkbox'
import MultiSelect from '../../../Input/Relation/MultiSelect'
import validate from './lib/validate'

const getNewOptionData = (email) => ({
  email,
  name: email,
  __isNew__: true,
})

const getOptionValue = ({ email }) => email

export default () => (
  <>
    <TextInput
      label='SparkPost API key'
      name='sparkPostApiKey'
      validate={validate('sparkPostApiKey')}
      $maxWidth='400px'
    />
    <TextInput
      label='From'
      name='from'
      validate={validate('from')}
      $maxWidth='400px'
    />
    <MultiSelect
      isCreatable
      label='Recipients'
      name='recipients'
      getNewOptionData={getNewOptionData}
      getOptionValue={getOptionValue}
    />
    <TextInput label='Subject' name='subject' validate={validate('subject')} />
    <TextArea label='Text' name='text' validate={validate('text')} />
    <Checkbox label='Sandbox' name='sandbox' />
  </>
)
