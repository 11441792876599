import React from 'react'
import { Formik, Form } from 'formik'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import TextInput from '../../Input/Text'
import validationSchema from './lib/validationSchema'

export default ({ children, ...rest }) => (
  <Formik validationSchema={validationSchema} {...rest}>
    {() => (
      <Form>
        <FormContainer>
          <TextInput label='Name' name='name' $maxWidth='300px' />
          <TextInput label='Code' name='code' $maxWidth='150px' />

          {children}

          <FormButton />
        </FormContainer>
      </Form>
    )}
  </Formik>
)
