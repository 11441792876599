import { gql } from 'graphql.macro'
import RuleFragment from '../../../../lib/RuleFragment'

export default gql`
  mutation DeleteRule($id: ID!) {
    deleteRule(id: $id) {
      ...RuleFragment
    }
  }

  ${RuleFragment}
`
