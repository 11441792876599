import React from 'react'
import Layout from '../Layout'
import Heading from '../Heading'
import ListHeader from '../List/Header'
import Form from './Form'

export default () => (
  <Layout>
    <ListHeader>
      <Heading>
        Debugger
      </Heading>
    </ListHeader>

    <Form />
  </Layout>
)
