import React from 'react'
import styled from 'styled-components'
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/checkmark.svg'

const InputContainer = styled.div`
  position: relative;
  cursor: pointer;
`

const Checkmark = styled(CheckmarkIcon)`
  position: absolute;
  left: 2px;
  top: 3px;
  fill: ${(props) =>
    props.$dark ? props.theme.colors.white : props.theme.colors.black};
`

export default ({ checkmarkVisible, children, $dark, ...rest }) => (
  <InputContainer {...rest}>
    {children}
    {checkmarkVisible && <Checkmark $dark={$dark} />}
  </InputContainer>
)
