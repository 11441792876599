import React from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import Item from './Item'

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.light5};
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  flex-grow: 0;
`

export default ({
  aggregatedReading: { timestamp, type, value },
  isTimestampUtc,
  currentUser,
}) => (
  <Container>
    <Item
      title={`Timestamp (${isTimestampUtc ? 'UTC' : currentUser?.timezone})`}
    >
      {isTimestampUtc
        ? moment.utc(timestamp).format()
        : moment(timestamp).format()}
    </Item>
    <Item title='Type'>{type}</Item>
    <Item title='Value'>{value || 'None'}</Item>
  </Container>
)
