export default ({
  formikProps: {
    values: {
      startDate,
      endDate,
    },
  },
}) => ({
  start: startDate,
  end: endDate,
})
