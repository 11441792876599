import { gql } from 'graphql.macro'
import UserFragment from '../../lib/UserFragment'

export default gql`
  mutation UpdateUser($id: ID!, $data: UserDataInput!) {
    updateUser(id: $id, data: $data) {
      ...UserFragment
    }
  }

  ${UserFragment}
`
