import _ from 'lodash'

export default ({
  data: { eventTypeDebounces, readingRanges, readingOffsets, ...dataRest },
  ...rest
}) => ({
  data: {
    eventTypeDebounces: _.map(
      eventTypeDebounces,
      ({ eventTypeId, periodInSeconds }) => ({
        eventTypeId,
        periodInSeconds: parseFloat(periodInSeconds),
      })
    ),
    readingRanges: _.map(
      readingRanges,
      ({ readingTypeId, minValue, maxValue }) => ({
        readingTypeId,
        minValue: parseFloat(minValue),
        maxValue: parseFloat(maxValue),
      })
    ),
    readingOffsets: _.map(
      readingOffsets,
      ({ readingTypeId, unitId, offset }) => ({
        readingTypeId,
        offset: parseFloat(offset),
        ...(unitId ? { unitId } : {}),
      })
    ),
    ...dataRest,
  },
  ...rest,
})
