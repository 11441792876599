import merge from '../../../../../../lib/merge'

export default ({
  fetchMore,
  feedItems: {
    cursors: { after },
  },
  loading,
}) => () => {
  if (loading) return

  return fetchMore({
    variables: {
      cursor: after,
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      const {
        feedItems: { results, cursors },
      } = fetchMoreResult

      return merge({}, prev, {
        feedItems: {
          results: [...prev.feedItems.results, ...results],
          cursors,
        },
      })
    },
  })
}
