import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useToasts } from 'react-toast-notifications'
import Loading from '../../../Loading'
import Error from '../../../Error'
import ListItems from '../../../List/Items'
import query from './lib/query'
import testMutation from './lib/test/mutation'
import deleteMutation from './lib/delete/mutation'
import deleteUpdate from './lib/delete/update'
import duplicateMutation from './lib/duplicate/mutation'
import duplicateUpdate from './lib/duplicate/update'
import onTest from './lib/onTest'
import onTestError from './lib/onTestError'

export default () => {
  const { addToast } = useToasts()

  const [onDelete, deleteMutationProps] = useMutation(deleteMutation, {
    update: deleteUpdate,
  })

  const [onDuplicate, duplicateMutationProps] = useMutation(duplicateMutation, {
    update: duplicateUpdate,
  })

  const [testMutate] = useMutation(testMutation, {
    onError: onTestError({ addToast }),
  })

  const { loading, error, data } = useQuery(query)
  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <>
      <ListItems
        items={data.actions}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        onTest={onTest({ testMutate })}
        editUrl={({ id }) => `/actions/edit/${id}`}
      />
      {deleteMutationProps.error && <Error />}
      {duplicateMutationProps.error && <Error />}
    </>
  )
}
