import _ from 'lodash'
import query from './query'

export default (cache, {
  data: {
    deleteArea: {
      id,
    },
  },
}) => {
  const { areas } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { areas: _.reject(areas, { id }) },
  })
}
