import { gql } from 'graphql.macro'
import ReadingTypeFragment from '../../../lib/ReadingTypeFragment'

export default gql`
  query ReadingTypesQuery($filter: ReadingTypesFilterInput) {
    readingTypes(filter: $filter) {
      ...ReadingTypeFragment
    }
  }

  ${ReadingTypeFragment}
`
