import { gql } from 'graphql.macro'
import ModelFragment from '../../lib/ModelFragment'

export default gql`
  mutation UpdateModel($id: ID!, $data: ModelDataInput!) {
    updateModel(id: $id, data: $data) {
      ...ModelFragment
    }
  }

  ${ModelFragment}
`
