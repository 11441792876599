import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import conditionInitialValueDefaults from '../lib/conditionInitialValueDefaults'

const Container = styled.div`
  border: 2px solid ${(props) => props.theme.colors.light5};
  padding: 10px;
  padding-bottom: 0;
  margin-bottom: 20px;
  border-radius: 8px;

  ${breakpoint('md')`
    padding: 20px;
  `}
`

const Heading = styled.div`
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
`

const Arrow = styled.span`
  color: ${(props) => props.theme.colors.light3};
`

export default ({
  label,
  value,
  setFieldValue,
  isToggleAvailable = value,
  name,
  children,
}) => (
  <Container>
    <Heading
      onClick={(e) => {
        if (!isToggleAvailable) return

        const newValue = value ? null : conditionInitialValueDefaults
        setFieldValue(name, newValue)
      }}
    >
      {label} {isToggleAvailable && <Arrow>{value ? '▼' : '▲'}</Arrow>}
    </Heading>

    {(value || !isToggleAvailable) && children}
  </Container>
)
