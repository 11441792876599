import React from 'react'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import Layout from '../../Layout'
import Loading from '../../Loading'
import Error from '../../Error'
import EditHeader from '../../Edit/Header'
import readingTypesQuery from '../../ReadingTypes/List/Items/lib/query'
import eventTypesQuery from '../../EventTypes/List/Items/lib/query'
import Form from '../Form'
import initialValueDefaults from '../lib/initialValueDefaults'
import mutation from './lib/mutation'
import update from './lib/update'

export default () => {
  const [mutate, mutationProps] = useMutation(mutation, {
    update,
  })

  const readingTypesQueryProps = useQuery(readingTypesQuery)
  const eventTypesQueryProps = useQuery(eventTypesQuery)

  if (_.some([readingTypesQueryProps, eventTypesQueryProps], 'loading')) {
    return <Loading />
  }

  if (_.some([readingTypesQueryProps, eventTypesQueryProps], 'error')) {
    return <Error />
  }

  return (
    <Layout>
      <EditHeader>New rule</EditHeader>

      <Form
        initialValues={initialValueDefaults({
          readingTypesQueryProps,
          eventTypesQueryProps,
        })}
        onSubmit={(data) => mutate({ variables: { data } })}
      />
      {mutationProps.error && <Error leftAligned />}
      {mutationProps.data && <Redirect to='/rules' />}
    </Layout>
  )
}
