import React from 'react'
import { useQuery } from '@apollo/client'
import Loading from '../Loading'
import Error from '../Error'
import baseQuery from '../Units/List/Items/lib/query'
import RelationSelectInput from './Relation/Select'

export default ({ query = baseQuery, variables = {}, ...rest }) => {
  const { loading, error, data } = useQuery(query, { variables })

  if (loading) return <Loading />
  if (error) return <Error />

  return <RelationSelectInput name='unitId' options={data.units} {...rest} />
}
