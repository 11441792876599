import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import Note from '../../Note'

const Container = styled.div`
  padding: 20px 0;
  border-top: ${props => props.theme.borders.light4};
`

export default ({
  header,
}) => (
  <Container>
    <Note>
      No {_.lowerCase(header)} connected yet.
    </Note>
  </Container>
)
