import React, { useState } from 'react'
import Moment from 'moment-timezone'
import styled from 'styled-components'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { formatDate, parseDate } from 'react-day-picker/moment'
import { extendMoment } from 'moment-range'
import modifiers from './lib/modifiers'
import onDayChange from './lib/onDayChange'
import handleDayEnter from './lib/handleDayEnter'

const moment = extendMoment(Moment)

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.light5};
  padding: 5px;
  border-radius: 4px;
`

export default ({
  formikProps: {
    values: { startDate, endDate },
  },
  formikProps,
}) => {
  const [hoverRange, setHoverRange] = useState(null)

  return (
    <InputContainer>
      <DayPickerInput
        value={startDate}
        placeholder='From'
        format='YYYY-MM-DD'
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          selectedDays: [startDate, { startDate, endDate }],
          disabledDays: { after: moment().toDate() },
          toMonth: moment().toDate(),
          modifiers: modifiers({ formikProps, hoverRange }),
          onDayMouseEnter: handleDayEnter({ setHoverRange }),
        }}
        onDayChange={onDayChange({ name: 'startDate', formikProps })}
      />
    </InputContainer>
  )
}
