import _ from 'lodash'

export default ({
  currentUser: {
    sites,
  },
}) => ({
  item: {
    siteId,
  },
}) => {
  if (siteId === null) return false

  return !_.some(sites, { id: siteId })
}
