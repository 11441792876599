import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import TextInput from '../../../../../Input/Text'
import value from './lib/value'
import formikKey from './lib/formikKey'
import onChange from './lib/onChange'
import validate from './lib/validate'

const Container = styled.div`
  display: flex;
  padding-top: 10px;

  ${breakpoint('md')`
    padding-top: 0;
    justify-content: flex-end;
    align-items: center;
    padding-right: 40px;
  `}
`

export default ({ name, index, formikProps, systemOfUnits, ...rest }) => (
  <Container>
    <TextInput
      name={formikKey({ name, index })}
      value={value({ formikProps, name, index, systemOfUnits })}
      onChange={onChange({ formikProps, name, index, systemOfUnits })}
      validate={validate}
      $maxWidth='100px'
      {...rest}
    />
  </Container>
)
