import _ from 'lodash'

export default ({
  reading: {
    readingType: { id },
    value,
  },
  sensor: { readingRanges },
}) => {
  const readingRange = _.find(readingRanges, { readingTypeId: id })
  if (!readingRange) return 'black'

  const { minValue, maxValue } = readingRange

  const normalizedValue = _.toNumber(value)

  if (normalizedValue > maxValue) return 'reddish'
  if (normalizedValue < minValue) return 'blue'

  return 'black'
}
