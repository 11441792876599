import isInvertedConfig from './isInvertedConfig'

const REGULAR = {
  lower: 'condition.termTwo.condition.termOne.condition.termTwo.value',
  upper: 'condition.termTwo.condition.termTwo.condition.termTwo.value',
}

const INVERTED = {
  lower:
    'condition.termTwo.condition.termOne.condition.termTwo.condition.termTwo.value',
  upper:
    'condition.termTwo.condition.termTwo.condition.termOne.condition.termTwo.value',

  // lower: 'condition.termTwo.condition.termOne.condition.termTwo.condition.termTwo.value',
  // upper: 'condition.termTwo.condition.termTwo.condition.termOne.condition.termTwo.value',
}

export default ({ formikProps, thresholdName }) => {
  if (isInvertedConfig({ formikProps })) {
    return INVERTED[thresholdName]
  }

  return REGULAR[thresholdName]
}
