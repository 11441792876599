import { gql } from 'graphql.macro'

export default gql`
  fragment ReadingOffsetFragment on ReadingOffset {
    id
    readingTypeId
    unitId
    offset
  }
`
