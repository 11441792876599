import React from 'react'
import styled from 'styled-components'
import EditList from '../Edit/List'

const Code = styled.div`
  wrap: pre-wrap;
  font-size: 14px;
  font-family: monospace;
  line-height: 20px;
`

export default ({ keys }) => (
  <EditList
    header='Keys'
    label={({ item: { key, secret } }) => (
      <Code>
        CONSUMER_KEY={key} CONSUMER_SECRET={secret}
      </Code>
    )}
    items={keys}
  />
)
