import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export default styled.h1`
  font-size: ${(props) => (props.compact ? '20px' : '25px')};
  font-weight: 600;
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : 'initial'};
  display: flex;
  align-items: center;
  padding: ${(props) => props.padding};

  ${breakpoint('md')`
    font-size: ${(props) => (props.compact ? '26px' : '35px')};
  `}
`
