import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import Note from '../../../Note'
import SensorsQuery from '../SensorsQuery'
import WithTypeHeader from '../WithTypeHeader'
import Area from './Area'
import groupedByArea from './lib/groupedByArea'

const Container = styled.div``

export default () => (
  <WithTypeHeader>
    <SensorsQuery>
      {({ sensors }) => (
        <Container>
          {_.isEmpty(sensors) && <Note>No sensors.</Note>}
          {_.map(groupedByArea({ sensors }), (group) => (
            <Area key={group.area?.id || 'not-set'} group={group} />
          ))}
        </Container>
      )}
    </SensorsQuery>
  </WithTypeHeader>
)
