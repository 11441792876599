import _ from 'lodash'

export default ({ sensorFiltersProps: { filters }, feedItemAdded }) => {
  const filter = _.find(filters, { type: 'ReadingType' })

  if (!filter) return true
  if (!feedItemAdded.item.readings) return true

  return _.find(feedItemAdded.item.readings, {
    readingType: {
      id: filter.id,
    },
  })
}
