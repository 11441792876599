import { gql } from 'graphql.macro'
import TermThirdLayerFragment from '../../Terms/lib/TermThirdLayerFragment'
import PeriodFragment from '../../Periods/lib/PeriodFragment'

export default gql`
  fragment ConditionThirdLayerFragment on Condition {
    id
    comparison

    termOne {
      ...TermThirdLayerFragment
    }

    termTwo {
      ...TermThirdLayerFragment
    }

    period {
      ...PeriodFragment
    }
  }

  ${TermThirdLayerFragment}
  ${PeriodFragment}
`
