export default ({
  name,
  active,
  color,
  mapImageUrl,
  siteId,
}) => ({
  name: name || '',
  active,
  color,
  mapImageUrl,
  siteId,
})
