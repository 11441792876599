import _ from 'lodash'

export default ({
  item: { unitId },
  unitsQueryProps: {
    data: { units },
  },
}) => {
  if (!unitId) return ''

  const unit = _.find(units, { id: unitId })
  if (!unit) return ''

  return ` (${unit.symbol})`
}
