import React, { useState } from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Content from './Content'
import Button from './Button'

const Container = styled.div`
  padding-bottom: 0;

  ${breakpoint('md')`
    padding-bottom: ${(props) => (props.isOpen ? 0 : '10px')};
  `}
`

export default (props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container isOpen={isOpen}>
      <Button isOpen={isOpen} setIsOpen={setIsOpen} />
      {isOpen && <Content {...props} />}
    </Container>
  )
}
