import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 10px;
`

const Label = styled.div`
  font-weight: 600;
  font-size: 16px;
`

const Value = styled.div`
  font-family: monospace;
  font-size: 13px;
  text-align: right;
  text-decoration: ${(props) => props.href && 'underline'};
`

export default ({ label, value, href }) => (
  <Container>
    <Label>{label}</Label>

    <Value as={href ? 'a' : 'div'} href={href}>
      {value}
    </Value>
  </Container>
)
