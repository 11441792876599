import _ from 'lodash'
import query from './query'

export default (cache, {
  data: {
    deleteAccount: {
      id,
    },
  },
}) => {
  const { accounts } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { accounts: _.reject(accounts, { id }) },
  })
}
