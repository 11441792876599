import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export default styled.div`
  padding: 10px;

  ${breakpoint('md')`
    padding: ${(props) => (props.$type === 'tiny' ? '0 40px' : '40px')};
  `}
`
