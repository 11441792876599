import React, { useState } from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import useCurrentUser from '../../../../../../../hooks/useCurrentUser'
import Button from '../../../../../../Button'
import Data from './Data'

const Container = styled.div`
  padding-top: 50px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoint('md')`
    align-items: center;
    flex-direction: row;
  `}
`

const TimestampContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-left: 0;

  ${breakpoint('md')`
    padding-top: 0;
    padding-left: 20px;
  `}
`

const TimestampValue = styled.div`
  font-size: 14px;
  padding-right: 10px;
`

export default ({ data }) => {
  const { currentUser } = useCurrentUser()
  const [isOpen, setIsOpen] = useState(false)
  const [isTimestampUtc, setIsTimestampUtc] = useState(false)

  return (
    <Container>
      <ButtonsContainer>
        <Button size='small' onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? 'Hide' : 'Raw data'}
        </Button>
        {isOpen && (
          <TimestampContainer>
            <TimestampValue>
              Current timestamp type:{' '}
              {isTimestampUtc ? 'UTC' : currentUser?.timezone}
            </TimestampValue>
            <Button
              size='small'
              onClick={() => setIsTimestampUtc(!isTimestampUtc)}
            >
              Change to {isTimestampUtc ? currentUser?.timezone : 'UTC'}
            </Button>
          </TimestampContainer>
        )}
      </ButtonsContainer>

      {isOpen && (
        <Data
          data={data}
          isTimestampUtc={isTimestampUtc}
          currentUser={currentUser}
        />
      )}
    </Container>
  )
}
