import React from 'react'
import { Formik, Form } from 'formik'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import TextInput from '../../Input/Text'
import Select from '../../Input/Select'
import Checkbox from '../../Input/Checkbox'
import CurrentUser from '../../CurrentUser'
import AccountInput from '../../Input/Account'
import Group from '../../Rules/Form/Group'
import Webhook from './Webhook'
import SparkpostEmail from './SparkpostEmail'
import Email from './Email'
import Pushover from './Pushover'
import InterpolationNote from './InterpolationNote'
import validationSchema from './lib/validationSchema'
import serialize from './lib/serialize'

const typeOptions = [
  { label: 'Webhook', value: 'webhook' },
  { label: 'Email', value: 'email' },
  { label: 'Pushover', value: 'pushover' },
  { label: 'SparkPost email', value: 'sparkpost-email' },
]

export default ({ children, onSubmit, ...rest }) => (
  <Formik
    validationSchema={validationSchema}
    onSubmit={(data) => onSubmit(serialize(data))}
    {...rest}
  >
    {({ values, errors }) => (
      <Form>
        <FormContainer>
          <TextInput label='Name' name='name' $maxWidth='300px' />

          <CurrentUser>
            {({ currentUser: { isGlobalAdmin } }) =>
              isGlobalAdmin && <AccountInput includeEmpty={false} />
            }
          </CurrentUser>

          <Checkbox label='Active' name='active' />
          <Select
            label='Type'
            name='type'
            options={typeOptions}
            $maxWidth='300px'
          />

          <Group label='Type configuration'>
            {values.type === 'webhook' && <Webhook values={values} />}
            {values.type === 'sparkpost-email' && (
              <SparkpostEmail values={values} />
            )}
            {values.type === 'email' && <Email values={values} />}
            {values.type === 'pushover' && <Pushover values={values} />}

            <InterpolationNote />
          </Group>

          {children}

          <FormButton />
        </FormContainer>
      </Form>
    )}
  </Formik>
)
