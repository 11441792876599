import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import 'react-day-picker/lib/style.css'
import ResolutionButton from './ResolutionButton'
import Input from './Input'

const Container = styled.div`
  padding-top: 10px;

  ${breakpoint('md')`
    padding-top: 0;
  `}
`

const InnerContainer = styled.div`
  display: flex;
  align-items: center;

  ${breakpoint('md')`
    justify-content: flex-end;
  `}
`

const ResolutionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  ${breakpoint('md')`
    justify-content: flex-end;
  `}
`

export default ({ formikProps }) => (
  <Container>
    <ResolutionContainer>
      <ResolutionButton type='weekly' formikProps={formikProps}>
        7 days
      </ResolutionButton>
      <ResolutionButton type='monthly' formikProps={formikProps}>
        Month
      </ResolutionButton>
    </ResolutionContainer>
    <InnerContainer>
      <Input formikProps={formikProps} />
    </InnerContainer>
  </Container>
)
