export default {
  name: '',
  manufacturerLabel: '',
  active: true,
  color: 0,
  externalId: '',
  gateways: [],
  manufacturerId: '',
  primaryGatewayId: '',
  modelId: '',
  photoImageUrl: null,
  eventTypeDebounces: [],
  readingRanges: [],
  readingOffsets: [],
  location: { x: 200, y: 100 },
}
