import { gql } from 'graphql.macro'
import SensorFragment from '../../Sensors/lib/SensorFragment'
import EventFragment from './EventFragment'

export default gql`
  fragment EventFullFragment on Event {
    ...EventFragment

    sensor {
      ...SensorFragment
    }
  }

  ${EventFragment}
  ${SensorFragment}
`
