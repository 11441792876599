import { gql } from 'graphql.macro'

export default gql`
  fragment AreaFragment on Area {
    id
    name
    active
    color
    mapImageUrl
    index
    siteId
  }
`
