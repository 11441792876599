import { gql } from 'graphql.macro'
import HubFragment from '../../lib/HubFragment'

export default gql`
  mutation CreateHub($data: HubDataInput!) {
    createHub(data: $data) {
      ...HubFragment
    }
  }

  ${HubFragment}
`
