import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import Loading from '../../../Loading'
import Error from '../../../Error'
import ListItems from '../../../List/Items'
import query from './lib/query'
import deleteMutation from './lib/delete/mutation'
import deleteUpdate from './lib/delete/update'
import duplicateMutation from './lib/duplicate/mutation'
import duplicateUpdate from './lib/duplicate/update'

export default () => {
  const [onDelete, deleteMutationProps] = useMutation(deleteMutation, {
    update: deleteUpdate,
  })

  const [onDuplicate, duplicateMutationProps] = useMutation(duplicateMutation, {
    update: duplicateUpdate,
  })

  const { loading, error, data } = useQuery(query)

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <>
      <ListItems
        items={data.rules}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        editUrl={({ id }) => `/rules/edit/${id}`}
      />
      {deleteMutationProps.error && <Error />}
      {duplicateMutationProps.error && <Error />}
    </>
  )
}
