import { gql } from 'graphql.macro'

export default gql`
  fragment UnitFragment on Unit {
    id
    name
    symbol
    slug
  }
`
