import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  color: ${props => props.theme.colors.reddish};
  font-weight: 600;
  text-align: ${props => props.leftAligned ? 'left' : 'center'};
  width: 100%;
  padding: ${props => props.leftAligned ? '40px' : 0};
  padding-top: 40px;
  padding-bottom: 40px;
`

export default ({ message = 'There was an error.', leftAligned }) => (
  <Container leftAligned={leftAligned}>
    {message}
  </Container>
)
