import { gql } from 'graphql.macro'
import SiteFragment from '../../../lib/SiteFragment'

export default gql`
  mutation DeleteSite($id: ID!) {
    deleteSite(id: $id) {
      ...SiteFragment
    }
  }

  ${SiteFragment}
`
