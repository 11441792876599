import React from 'react'
import _ from 'lodash'
import { WidthSensor } from 'libreact/lib/WidthSensor'
import styled from 'styled-components'
import { Route } from 'react-router-dom'
import Note from '../../../Note'
import useSensorFilters from '../../../SensorFilters/useSensorFilters'
import ImageContainer from '../../../ImageContainer'
import Item from '../Grid/Area/Item'
import WithTypeHeader from '../WithTypeHeader'
import Gateway from './Gateway'
import GatewayTooltip from './Gateway/Tooltip'
import Sensor from './Sensor'
import SensorsQuery from '../SensorsQuery'
import AreaQuery from './AreaQuery'

const Container = styled.div`
  width: 100%;
`

const TooltipContainer = styled.div`
  position: absolute;
  left: calc(50% - 200px / 2);
  top: calc(50% - 350px / 2);
  z-index: 4;
`

export default () => {
  const { filters } = useSensorFilters()
  const areaFilters = _.filter(filters, { type: 'Area' })
  const areaId = _.get(areaFilters, '[0].id')

  if (areaFilters.length !== 1 || !areaId) {
    return (
      <WithTypeHeader>
        <Note>Filter one area first.</Note>
      </WithTypeHeader>
    )
  }

  return (
    <WithTypeHeader>
      <AreaQuery areaId={areaId}>
        {({ area: { mapImageUrl } }) => (
          <SensorsQuery>
            {({ sensors, gateways }) => (
              <WidthSensor>
                {({ width }) => (
                  <Container>
                    <ImageContainer
                      center={_.isEmpty(gateways) && _.isEmpty(sensors)}
                      url={mapImageUrl}
                      width={`${width}px`}
                      maxWidth={`${width}px`}
                    >
                      {_.map(gateways, (gateway) => (
                        <Gateway key={gateway.id} gateway={gateway} />
                      ))}

                      {_.map(sensors, (sensor) => (
                        <Sensor key={sensor.id} sensor={sensor} />
                      ))}

                      {_.isEmpty(gateways) && _.isEmpty(sensors) && (
                        <Note withBackground>No gateways or sensors.</Note>
                      )}
                      <Route
                        path='/dashboard/map/gateways/:id'
                        render={({
                          match: {
                            params: { id },
                          },
                        }) => (
                          <TooltipContainer>
                            <GatewayTooltip
                              closeLink={`/dashboard/map`}
                              gateway={_.find(gateways, { id })}
                            />
                          </TooltipContainer>
                        )}
                      />
                      <Route
                        path='/dashboard/map/sensors/:id'
                        render={({
                          match: {
                            params: { id },
                          },
                        }) => (
                          <TooltipContainer>
                            <Item
                              closeLink={`/dashboard/map`}
                              sensor={_.find(sensors, { id })}
                            />
                          </TooltipContainer>
                        )}
                      />
                    </ImageContainer>
                  </Container>
                )}
              </WidthSensor>
            )}
          </SensorsQuery>
        )}
      </AreaQuery>
    </WithTypeHeader>
  )
}
