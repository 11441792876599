import { gql } from 'graphql.macro'
import ManufacturerFragment from '../../lib/ManufacturerFragment'

export default gql`
  query Manufacturer($id: ID!) {
    manufacturer(id: $id) {
      ...ManufacturerFragment
    }
  }

  ${ManufacturerFragment}
`
