import { gql } from 'graphql.macro'
import GatewayBaseFragment from '../../Gateways/lib/GatewayBaseFragment'
import LocationFragment from '../../lib/LocationFragment'
import ManufacturerFragment from '../../Manufacturers/lib/ManufacturerFragment'
import ModelFragment from '../../Models/lib/ModelFragment'
import AreaFragment from '../../Areas/lib/AreaFragment'
import SensorBaseFragment from './SensorBaseFragment'

export default gql`
  fragment SensorFragment on Sensor {
    ...SensorBaseFragment
    siteId
    primaryGatewayId

    gateways {
      ...GatewayBaseFragment
    }

    location {
      ...LocationFragment
    }

    manufacturer {
      ...ManufacturerFragment
    }

    model {
      ...ModelFragment
    }

    area {
      ...AreaFragment
    }
  }

  ${SensorBaseFragment}
  ${GatewayBaseFragment}
  ${LocationFragment}
  ${ManufacturerFragment}
  ${ModelFragment}
  ${AreaFragment}
`
