import { gql } from 'graphql.macro'
import ConditionFragment from '../../Conditions/lib/ConditionFragment'
import ActionFragment from '../../Actions/lib/ActionFragment'
import ScopeFragment from '../../Scopes/lib/ScopeFragment'

export default gql`
  fragment RuleFragment on Rule {
    id
    name
    active
    slug
    accountId

    debouncePeriodInSeconds
    debouncePeriodPerSensor

    scopes {
      ...ScopeFragment
    }

    condition {
      ...ConditionFragment
    }

    actions {
      ...ActionFragment
    }
  }

  ${ConditionFragment}
  ${ActionFragment}
  ${ScopeFragment}
`
