import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { FiFilter } from 'react-icons/fi'
import useSensorFilters from '../../useSensorFilters'

const Container = styled.div`
  color: ${(props) => props.theme.colors.grey};
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
`

const Text = styled.div`
  padding-left: 10px;
`

export default ({ isOpen, setIsOpen }) => {
  const { filters } = useSensorFilters()

  return (
    <Container onClick={() => setIsOpen(!isOpen)}>
      <FiFilter />
      <Text>Filter {!_.isEmpty(filters) && `(${filters.length})`}</Text>
    </Container>
  )
}
