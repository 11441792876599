import _ from 'lodash'

const defaultTypes = [
  {
    name: 'iBS01G/H',
    type: 'eventTypes',
    typeName: 'Motion',
  },
  {
    name: 'iBS01T',
    type: 'readingTypes',
    typeName: 'Temperature',
  },
  {
    name: 'Cookie',
    type: 'readingTypes',
    typeName: 'Temperature',
  },
  {
    name: 'iBS01G',
    type: 'eventTypes',
    typeName: 'Motion',
  },
  {
    name: 'iBS03T',
    type: 'readingTypes',
    typeName: 'Temperature',
  },
  {
    name: 'iBS03G',
    type: 'eventTypes',
    typeName: 'Motion',
  },
  {
    name: 'DS18B20',
    type: 'readingTypes',
    typeName: 'Temperature',
  },
]

const fallback = ({
  readingTypes,
  eventTypes,
}) => {
  const record = readingTypes[0] || eventTypes[0]

  return {
    scopableId: record.id,
    scopable: record.__typename,
    name: record.name,
  }
}

export default ({
  sensor: {
    model,
  },
  eventTypes,
  readingTypes,
}) => {
  const type = _.find(defaultTypes, { name: model.name })

  if (!type) {
    return fallback({ readingTypes, eventTypes })
  }

  const all = {
    readingTypes,
    eventTypes,
  }

  const typeOptions = all[type.type]

  const result = _.find(typeOptions, { name: type.typeName })

  if (!result) return fallback({ readingTypes, eventTypes })

  const {
    __typename,
    id,
    name,
  } = result

  return {
    scopableId: id,
    scopable: __typename,
    name,
  }
}
