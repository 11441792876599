import _ from 'lodash'

const convertBoolean = (value) => {
  if (value === 'true') return true
  if (value === 'false') return false

  return value
}

const convertBooleans = (values) => (
  _.map(values, ({ value, ...rest }) => ({
    ...rest,
    value: convertBoolean(value),
  }))
)

export default (values) => (
  convertBooleans(values)
)
