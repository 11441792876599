import React from 'react'
import Content from './Content'
import Menu from './Menu'

export default ({ isOpen, setIsOpen }) => {
  if (isOpen) {
    return <Content setIsOpen={setIsOpen} />
  }

  return <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
}
