import { gql } from 'graphql.macro'
import AreaWithGatewaysFragment from '../../../lib/AreaWithGatewaysFragment'

export default gql`
  mutation DeleteArea($id: ID!) {
    deleteArea(id: $id) {
      ...AreaWithGatewaysFragment
    }
  }

  ${AreaWithGatewaysFragment}
`
