import {
  FiThermometer,
  FiBattery,
  FiTrendingUp,
  FiTrendingDown,
  FiCircle,
  FiWifi,
  FiRadio,
  FiEye,
  FiEyeOff,
  FiClock,
  FiRepeat,
  FiCrop,
  FiServer,
  FiX,
} from 'react-icons/fi'
import { FaMagnet } from 'react-icons/fa'
import { IoMdExpand } from 'react-icons/io'
import { fahrenheitToCelsius } from 'temperature'
import { ReactComponent as Motion } from '../../../../assets/icons/motion.svg'
import colors from '../../../../theme/colors'
import readingType from './readingType'
import eventType from './eventType'

export default ({ readingTypesData, eventTypesData }) => [
  {
    name: 'Temperature',
    types: [
      {
        title: 'Temperature above threshold',
        leftIcon: FiThermometer,
        rightIcon: FiTrendingUp,
        sensorSelect: {
          variables: {
            filters: [
              {
                type: 'ReadingType',
                id: readingType({ readingTypesData, name: 'Temperature' }).id,
              },
            ],
          },
        },
        color: colors.types.temperature,
        values: {
          slug: 'temperature-above-threshold',
          scopes: [],
          condition: {
            termOne: {
              type: 'readingValue',
              value: readingType({ readingTypesData, name: 'Temperature' }).id,
              readingType: readingType({
                readingTypesData,
                name: 'Temperature',
              }),
            },
            comparison: 'gt',
            termTwo: {
              type: 'plain',
              value: '90',
            },
          },
        },
      },
      {
        title: 'Temperature below threshold',
        leftIcon: FiThermometer,
        rightIcon: FiTrendingDown,
        sensorSelect: {
          variables: {
            filters: [
              {
                type: 'ReadingType',
                id: readingType({ readingTypesData, name: 'Temperature' }).id,
              },
            ],
          },
        },
        color: '#e6ffff',
        values: {
          slug: 'temperature-below-threshold',
          scopes: [],
          condition: {
            termOne: {
              type: 'readingValue',
              value: readingType({ readingTypesData, name: 'Temperature' }).id,
              readingType: readingType({
                readingTypesData,
                name: 'Temperature',
              }),
            },
            comparison: 'lt',
            termTwo: {
              type: 'plain',
              value: '40',
            },
          },
        },
      },
      {
        title: 'Temperature outside range',
        leftIcon: FiThermometer,
        rightIcon: IoMdExpand,
        sensorSelect: {
          variables: {
            filters: [
              {
                type: 'ReadingType',
                id: readingType({ readingTypesData, name: 'Temperature' }).id,
              },
            ],
          },
        },
        color: colors.types.temperature,
        values: {
          slug: 'temperature-outside-range',
          scopes: [],
          condition: {
            termOne: {
              type: 'boolean',
              condition: {
                termOne: {
                  type: 'readingValue',
                  value: readingType({ readingTypesData, name: 'Temperature' })
                    .id,
                  readingType: readingType({
                    readingTypesData,
                    name: 'Temperature',
                  }),
                },
                comparison: 'lt',
                termTwo: {
                  type: 'plain',
                  value: `${fahrenheitToCelsius(40)}`,
                },
              },
            },
            comparison: 'or',
            termTwo: {
              type: 'boolean',
              condition: {
                termOne: {
                  type: 'readingValue',
                  value: readingType({ readingTypesData, name: 'Temperature' })
                    .id,
                  readingType: readingType({
                    readingTypesData,
                    name: 'Temperature',
                  }),
                },
                comparison: 'gt',
                termTwo: {
                  type: 'plain',
                  value: `${fahrenheitToCelsius(90)}`,
                },
              },
            },
          },
        },
      },
      {
        title: 'Temperature outside predefined range',
        leftIcon: FiThermometer,
        rightIcon: FiCrop,
        sensorSelect: {
          variables: {
            filters: [
              {
                type: 'ReadingType',
                id: readingType({ readingTypesData, name: 'Temperature' }).id,
              },
              {
                type: 'ReadingRange',
                readingTypeId: readingType({
                  readingTypesData,
                  name: 'Temperature',
                }).id,
              },
            ],
          },
        },
        color: colors.rules.temperatureOutsideReadingRange,
        values: {
          slug: 'temperature-outside-reading-range',
          scopes: [],
          condition: null,
        },
      },
    ],
  },
  {
    name: 'Voltage',
    types: [
      {
        title: 'Voltage above threshold',
        leftIcon: FiBattery,
        rightIcon: FiTrendingUp,
        sensorSelect: {
          variables: {
            filters: [
              {
                type: 'ReadingType',
                id: readingType({ readingTypesData, name: 'Voltage' }).id,
              },
            ],
          },
        },
        color: colors.types.voltage,
        values: {
          slug: 'voltage-above-threshold',
          scopes: [],
          condition: {
            termOne: {
              type: 'readingValue',
              value: readingType({ readingTypesData, name: 'Voltage' }).id,
              readingType: readingType({ readingTypesData, name: 'Voltage' }),
            },
            comparison: 'gt',
            termTwo: {
              type: 'plain',
              value: '3.5',
            },
          },
        },
      },
      {
        title: 'Voltage below threshold',
        leftIcon: FiBattery,
        rightIcon: FiTrendingDown,
        sensorSelect: {
          variables: {
            filters: [
              {
                type: 'ReadingType',
                id: readingType({ readingTypesData, name: 'Voltage' }).id,
              },
            ],
          },
        },
        color: '#fff5cc',
        values: {
          slug: 'voltage-below-threshold',
          scopes: [],
          condition: {
            termOne: {
              type: 'readingValue',
              value: readingType({ readingTypesData, name: 'Voltage' }).id,
              readingType: readingType({ readingTypesData, name: 'Voltage' }),
            },
            comparison: 'lt',
            termTwo: {
              type: 'plain',
              value: '2.0',
            },
          },
        },
      },
    ],
  },
  {
    name: 'Detection',
    types: [
      {
        title: 'Button pressed',
        leftIcon: FiCircle,
        sensorSelect: {
          variables: {
            filters: [
              {
                type: 'EventType',
                id: eventType({ eventTypesData, name: 'Button' }).id,
              },
            ],
          },
        },
        color: colors.types.button,
        values: {
          slug: 'button-pressed',
          scopes: [
            {
              scopable: 'EventType',
              scopableId: eventType({ eventTypesData, name: 'Button' }).id,
              name: eventType({ eventTypesData, name: 'Button' }).name,
            },
          ],
          condition: null,
        },
      },
      {
        title: 'Motion detected',
        leftIcon: Motion,
        sensorSelect: {
          variables: {
            filters: [
              {
                type: 'EventType',
                id: eventType({ eventTypesData, name: 'Motion' }).id,
              },
            ],
          },
        },
        color: colors.types.motion,
        values: {
          slug: 'motion-detected',
          scopes: [
            {
              scopable: 'EventType',
              scopableId: eventType({ eventTypesData, name: 'Motion' }).id,
              name: eventType({ eventTypesData, name: 'Motion' }).name,
            },
          ],
          condition: null,
        },
      },
      {
        title: 'Magnet opened',
        leftIcon: FaMagnet,
        sensorSelect: {
          variables: {
            filters: [
              {
                type: 'EventType',
                id: eventType({ eventTypesData, name: 'Magnet Open' }).id,
              },
            ],
          },
        },
        color: colors.types.magnetOpened,
        values: {
          slug: 'magnet-opened',
          scopes: [
            {
              scopable: 'EventType',
              scopableId: eventType({ eventTypesData, name: 'Magnet Open' }).id,
              name: eventType({ eventTypesData, name: 'Magnet Open' }).name,
            },
          ],
          condition: null,
        },
      },
    ],
  },
  {
    name: 'Signal',
    types: [
      {
        title: 'Signal Strength above threshold',
        leftIcon: FiWifi,
        rightIcon: FiTrendingUp,
        sensorSelect: {
          variables: {
            filters: [
              {
                type: 'ReadingType',
                id: readingType({ readingTypesData, name: 'Signal Strength' })
                  .id,
              },
            ],
          },
        },
        color: colors.types.signalStrength,
        values: {
          slug: 'signal-strength-above-threshold',
          scopes: [],
          condition: {
            termOne: {
              type: 'readingValue',
              value: readingType({ readingTypesData, name: 'Signal Strength' })
                .id,
              readingType: readingType({
                readingTypesData,
                name: 'Signal Strength',
              }),
            },
            comparison: 'gt',
            termTwo: {
              type: 'plain',
              value: '',
            },
          },
        },
      },
      {
        title: 'Signal Strength below threshold',
        leftIcon: FiWifi,
        rightIcon: FiTrendingDown,
        sensorSelect: {
          variables: {
            filters: [
              {
                type: 'ReadingType',
                id: readingType({ readingTypesData, name: 'Signal Strength' })
                  .id,
              },
            ],
          },
        },
        color: '#f0f0f5',
        values: {
          slug: 'signal-strength-below-threshold',
          scopes: [],
          condition: {
            termOne: {
              type: 'readingValue',
              value: readingType({ readingTypesData, name: 'Signal Strength' })
                .id,
              readingType: readingType({
                readingTypesData,
                name: 'Signal Strength',
              }),
            },
            comparison: 'lt',
            termTwo: {
              type: 'plain',
              value: '',
            },
          },
        },
      },
    ],
  },
  {
    name: 'Presence',
    types: [
      {
        title: 'Absence from Gateway',
        leftIcon: FiRadio,
        rightIcon: FiEyeOff,
        gatewaySelect: true,
        color: colors.types.absence,
        values: {
          slug: 'absence-from-gateway',
          scopes: [
            {
              scopable: 'EventType',
              scopableId: eventType({ eventTypesData, name: 'Absence' }).id,
              name: eventType({ eventTypesData, name: 'Absence' }).name,
            },
          ],
          condition: null,
        },
      },
      {
        title: 'Presence with Gateway',
        leftIcon: FiRadio,
        rightIcon: FiEye,
        gatewaySelect: true,
        color: colors.types.presence,
        values: {
          slug: 'presence-with-gateway',
          scopes: [
            {
              scopable: 'EventType',
              scopableId: eventType({ eventTypesData, name: 'Presence' }).id,
              name: eventType({ eventTypesData, name: 'Presence' }).name,
            },
          ],
          condition: null,
        },
      },
    ],
  },
  {
    name: 'Time',
    types: [
      {
        title: 'Time interval',
        leftIcon: FiClock,
        rightIcon: FiRepeat,
        color: colors.types.interval,
        sensorSelect: {
          variables: {
            filters: [],
          },
        },
        values: {
          slug: 'time-interval',
          scopes: [],
          condition: {
            termOne: {
              type: 'timestamp-minute-of-day',
            },
            comparison: 'divisible',
            termTwo: {
              type: 'plain',
              value: '5',
            },
          },
        },
      },
    ],
  },
  {
    name: 'Hub',
    types: [
      {
        title: 'Hub offline',
        leftIcon: FiServer,
        rightIcon: FiX,
        color: colors.types.hub,
        hubSelect: true,
        values: {
          slug: 'hub-offline',
          scopes: [],
          condition: null,
        },
      },
    ],
  },
]
