import React from 'react'
import Weekly from './Weekly'
import Monthly from './Monthly'

const inputs = {
  weekly: Weekly,
  monthly: Monthly,
}

export default ({
  formikProps: {
    values: {
      resolution,
    },
  },
  formikProps,
  ...rest
}) => {
  const Component = inputs[resolution]

  return (
    <Component {...rest} formikProps={formikProps} />
  )
}
