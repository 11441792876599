import _ from 'lodash'

export default ({
  formikProps: {
    setFieldValue,
    values: { eventTypeDebounces },
  },
  eventType: { id },
  eventTypeDebounceIndex,
}) => ({ target: { value } }) => {
  const newEventTypeDebounces = _.cloneDeep(eventTypeDebounces)
  newEventTypeDebounces[eventTypeDebounceIndex] = {
    eventTypeId: id,
    periodInSeconds: value,
  }

  const result = _.filter(newEventTypeDebounces, ({ periodInSeconds }) =>
    _.isFinite(parseFloat(periodInSeconds))
  )

  setFieldValue('eventTypeDebounces', result)
}
