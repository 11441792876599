import { gql } from 'graphql.macro'
import UserFragment from '../../lib/UserFragment'

export default gql`
  mutation CreateUser($data: UserDataInput!) {
    createUser(data: $data) {
      ...UserFragment
    }
  }

  ${UserFragment}
`
