import formikKey from '../formikKey'
import positionToValue from '../positionToValue'
import handleInversion from './handleInversion'
import getIsInverted from './isInverted'

export default ({
  formikProps,
  formikProps: { setFieldValue },
  lowerValue,
  upperValue,
}) => ({ width, thresholdName }) => (event, { x }) => {
  const isInverted = getIsInverted({
    lowerValue,
    upperValue,
  })

  const newValue = positionToValue({
    position: x,
    width,
  })

  // TODO support inverted range
  if (thresholdName === 'lower') {
    if (newValue >= upperValue) return
  } else {
    if (lowerValue >= newValue) return
  }

  setFieldValue(formikKey({ formikProps, thresholdName }), newValue)

  handleInversion({
    isInverted,
    formikProps,
    thresholdName,
    newValue,
  })
}
