import React from 'react'
import _ from 'lodash'
import {
  FieldArray,
} from 'formik'
import styled from 'styled-components'
import Value from './Value'
import Group from '../../../Rules/Form/Group'
import Button from '../../../Button'

const Container = styled.div`
  padding-bottom: 30px;
`

export default ({ values, data }) => (
  <Group label='Values'>
    <Container>
      <FieldArray
        name='values'
        render={(arrayHelpers) => (
          <>
            {_.map(values.values, (value, index) => (
              <Value
                key={index}
                index={index}
                value={value}
                data={data}
                arrayHelpers={arrayHelpers}
              />
            ))}

            <Button onClick={() => arrayHelpers.push({ name: '', value: '' })}>
              Add
            </Button>
          </>
        )}
      />
    </Container>
  </Group>
)
