import React from 'react'
import ExtendedLink from '../../../ExtendedLink'
import Dot from '../../../../ImageContainer/Dot'

export default ({
  gateway: {
    id,
    color,
    location,
  },
}) => {
  return (
    <Dot
      transparency={0}
      size={40}
      color='black'
      as={ExtendedLink}
      to={`/dashboard/map/gateways/${id}`}
      {...location}
    />
  )
}
