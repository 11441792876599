import styled from 'styled-components'

export default styled.input`
  background-color: ${(props) => props.theme.colors.light5};
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  flex-grow: 1;

  max-width: ${(props) => props.$maxWidth || 'inherit'};
  min-width: ${(props) => props.$minWidth || 'inherit'};
`
