import React from 'react'
import { Formik, Form } from 'formik'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import TextInput from '../../Input/Text'
import TextArea from '../../Input/TextArea'
import ImageInput from '../../Input/Image'
import AccountInput from '../../Input/Account'
import CurrentUser from '../../CurrentUser'
import validationSchema from './lib/validationSchema'

export default ({
  children,
  photoImageUrlMutate = () => null,
  photoImageUrlMutationProps = {},
  ...rest
}) => (
  <Formik validationSchema={validationSchema} {...rest}>
    {() => (
      <Form>
        <FormContainer>
          <TextInput label='Name' name='name' $maxWidth='300px' />
          <TextArea label='Address' name='address' $maxWidth='400px' />
          <ImageInput
            label='Photo'
            name='photoImageUrl'
            mutate={photoImageUrlMutate}
            mutationProps={photoImageUrlMutationProps}
          />

          <CurrentUser>
            {({ currentUser: { isGlobalAdmin } }) =>
              isGlobalAdmin && <AccountInput includeEmpty={false} />
            }
          </CurrentUser>

          {children}

          <FormButton />
        </FormContainer>
      </Form>
    )}
  </Formik>
)
