import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import Loading from '../../../../../Loading'
import Error from '../../../../../Error'
import Debugger from './Debugger'
import query from './lib/query'
import variables from './lib/variables'
import charts from './lib/charts'

const Container = styled.div`
  width: 100%;
`

export default ({
  formikProps: {
    values: {
      startDate,
      endDate,
      scope: { scopable },
    },
  },
  formikProps,
  sensor,
}) => {
  const { loading, error, data } = useQuery(query, {
    variables: variables({ sensor, formikProps }),
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const Component = charts[scopable]

  return (
    <Container>
      <Component data={data} formikProps={formikProps} />
      <Debugger data={data} />
    </Container>
  )
}
