import _ from 'lodash'

export default _.map([
  'get',
  'post',
  'delete',
  'head',
  'options',
  'put',
  'patch',
], (method) => ({
  label: _.upperCase(method),
  value: method,
}))
