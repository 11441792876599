import _ from 'lodash'

export default ({
  formikProps: {
    values: {
      userRoles,
    },
    setFieldValue,
  },
  item: {
    __typename,
    id,
  },
}) => (defaultGetOptionValue) => () => (role) => (
  setFieldValue('userRoles', _.compact(_.concat(_.reject(userRoles, {
    objectable: __typename,
    objectableId: id,
  }), [{
    roleId: role.id,
    objectable: __typename,
    objectableId: id,
  }])))
)
