import { gql } from 'graphql.macro'
import PeriodFragment from '../../Periods/lib/PeriodFragment'
import ReadingTypeFragment from '../../ReadingTypes/lib/ReadingTypeFragment'

export default gql`
  fragment TermFifthLayerFragment on Term {
    id
    type
    value

    period {
      ...PeriodFragment
    }

    readingType {
      ...ReadingTypeFragment
    }
  }

  ${PeriodFragment}
  ${ReadingTypeFragment}
`
