import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { Link } from 'react-router-dom'
import Button from './Button'

const Container = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  padding-top: 10px;

  ${breakpoint('md')`
    align-self: inherit;
    padding-top: 0;
  `}
`

export default ({
  item: { id },
  item,
  onDelete,
  onDeleteAvailable = () => true,
  onTest,
  onDuplicate,
  chartUrl,
  editUrl,
}) => (
  <Container>
    {chartUrl && (
      <Button size='small' as={Link} to={chartUrl({ id })}>
        Chart
      </Button>
    )}
    {onTest && (
      <Button size='small' onClick={onTest({ id })}>
        Test
      </Button>
    )}
    <Button size='small' as={Link} to={editUrl({ id })}>
      Edit
    </Button>
    {onDuplicate && (
      <Button
        size='small'
        onClick={() =>
          onDuplicate({
            variables: {
              id,
            },
          })
        }
      >
        Copy
      </Button>
    )}
    {onDeleteAvailable({ item }) && (
      <Button
        size='small'
        onClick={() => {
          if (
            !window.confirm(
              'Are you sure?\nNote: Keep in mind that full deletion might take some time.'
            )
          )
            return

          onDelete({ variables: { id } })
        }}
      >
        Delete
      </Button>
    )}
  </Container>
)
