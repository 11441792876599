import _ from 'lodash'
import nullifyNotSet from './nullifyNotSet'

export default ({ reorderMutationName, reorder, items }) => ({
  draggableId: id,
  destination: {
    index,
    droppableId: gatewayId,
  },
  destination,
  ...rest
}) => (
  reorder({
    variables: {
      id,
      data: {
        index,
        siteId: nullifyNotSet(gatewayId),
      },
    },
    optimisticResponse: {
      __typename: 'Mutation',
      [reorderMutationName]: {
        ..._.find(items, { id }),
        siteId: nullifyNotSet(gatewayId),
        index,
      },
    },
  })
)
