import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { darken } from 'polished'

const Container = styled.div`
  cursor: pointer;
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  border: 2px solid
    ${(props) => props.theme.colors[props.active ? 'black' : 'transparent']};
  background-color: ${(props) => props.color};

  &:hover {
    background-color: ${(props) => darken(0.05, props.color)};
  }

  ${breakpoint('md')`
    width: 110px;
    height: 110px;
  `}
`

const InnerContainer = styled.div`
  width: 100%;
`

const IconsContainer = styled.div`
  padding-bottom: 10px;
`

const Icon = styled.div`
  font-size: 30px;
  max-height: 30px;
  max-width: 30px;
  margin: 0 2.5px;
`

const Title = styled.div`
  font-size: 13px;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
`

export default ({
  formikProps: {
    values: { slug: selectedSlug },
  },
  type: {
    leftIcon,
    rightIcon,
    title,
    itemName,
    color,
    values: { slug },
  },
  ...rest
}) => (
  <Container active={slug === selectedSlug} color={color} {...rest}>
    <InnerContainer>
      <IconsContainer>
        <Icon as={leftIcon} />
        <Icon as={rightIcon} />
      </IconsContainer>

      <Title active={slug === selectedSlug}>{title}</Title>
    </InnerContainer>
  </Container>
)
