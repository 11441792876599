import { gql } from 'graphql.macro'
import ReadingTypeFragment from '../../ReadingTypes/lib/ReadingTypeFragment'
import EventTypeBaseFragment from './EventTypeBaseFragment'

export default gql`
  fragment EventTypeFragment on EventType {
    ...EventTypeBaseFragment

    readingTypes {
      ...ReadingTypeFragment
    }
  }

  ${EventTypeBaseFragment}
  ${ReadingTypeFragment}
`
