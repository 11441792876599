import { gql } from 'graphql.macro'
import SiteFragment from '../../lib/SiteFragment'

export default gql`
  query Site($id: ID!) {
    site(id: $id) {
      ...SiteFragment
    }
  }

  ${SiteFragment}
`
