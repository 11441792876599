import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import Loading from '../Loading'
import Error from '../Error'
import query from './lib/query'
import configureMoment from '../../lib/configureMoment'

export default ({ children, ...props }) => {
  const { loading, error, data, ...rest } = useQuery(query, props)

  useEffect(() => {
    configureMoment({
      currentUser: data?.currentUser,
    })
  }, [data])

  if (loading) return <Loading />

  if (error?.message === 'Not Authorised!') {
    return children({
      currentUser: null,
    })
  }

  if (error) return <Error message={error.message} />

  return children({
    ...data,
    ...rest,
    ...props,
  })
}
