import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import _ from 'lodash'
import { transparentize } from 'polished'
import selectableColors from '../../theme/selectableColors'

const convert = (key) => (props) =>
  'calc(' + props[key] * 100 + '% - ' + props.size / 2 + 'px)'

const backgroundColor = ({
  transparency = 0.7,
  color,
  colorIndex,
  theme: {
    colors: { transparentViolet },
  },
}) => {
  if (color) return transparentize(0.7, color)
  if (_.isUndefined(colorIndex)) return transparentViolet

  return transparentize(transparency, selectableColors[colorIndex])
}

const borderColor = ({
  color,
  colorIndex,
  theme: {
    colors: { violet },
  },
}) => {
  if (color) return color
  if (_.isUndefined(colorIndex)) return violet

  return selectableColors[colorIndex]
}

const Container = styled.div`
  position: absolute;
  background-color: ${backgroundColor};
  height: ${(props) => props.size + 'px'};
  width: ${(props) => props.size + 'px'};
  left: ${convert('x')};
  top: ${convert('y')};
  border-radius: 9999px;
  z-index: 3;
  border: 1px solid ${borderColor};

  ${breakpoint('md')`
    border: 3px solid ${borderColor};
  `}
`

export default ({ size = 20, ...rest }) => <Container size={size} {...rest} />
