import { gql } from 'graphql.macro'
import ActionFragment from '../../../lib/ActionFragment'

export default gql`
  {
    actions {
      ...ActionFragment
    }
  }

  ${ActionFragment}
`
