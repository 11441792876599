import { fahrenheitToCelsius } from 'temperature'
import isNumber from 'is-number'
import formikKey from './formikKey'

const newValue = ({ value, systemOfUnits }) => {
  if (systemOfUnits === 'metric') return value

  return isNumber(value) ? fahrenheitToCelsius(value) : value
}

export default ({ formikProps, name, index, systemOfUnits }) => ({
  target: { value },
}) =>
  formikProps.setFieldValue(
    formikKey({ name, index }),
    newValue({ value, systemOfUnits })
  )
