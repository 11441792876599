import getModelReadingType from '../modelReadingType'
import formatters from './formatters'
import fallbackFormatter from './formatters/fallback'

export default ({ reading, sensor }) => {
  const modelReadingType = getModelReadingType({
    sensor,
    reading,
  })

  if (!modelReadingType) return fallbackFormatter

  const { unit } = modelReadingType

  if (!unit) {
    return fallbackFormatter
  }

  return formatters[unit.slug]
}
