import * as yup from 'yup'

const schema = yup.object().shape({
  pushoverToken: yup.string(),
  pushoverUser: yup.string(),
  pushoverMessage: yup.string(),
})

export default (key) => (value) => {
  let error = ''

  try {
    schema.validateSyncAt(key, value)
  } catch(e) {
    error = e.message
  }

  return error
}
