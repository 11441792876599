import React from 'react'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import breakpoint from 'styled-components-breakpoint'
import RelationSelectInput from '../../../../Input/Relation/Select'
import Loading from '../../../../Loading'
import Error from '../../../../Error'
import query from './lib/query'
import value from './lib/value'
import emptyOption from './lib/emptyOption'
import onChange from './lib/onChange'

const Container = styled.div`
  flex-grow: 1;

  ${breakpoint('md')`
    max-width: 200px;
  `}
`

export default ({ item, formikProps }) => {
  const { loading, error, data } = useQuery(query)

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Container>
      <RelationSelectInput
        name='roleId'
        options={data.roles}
        value={value({ item, formikProps, data })}
        onChange={onChange({ item, formikProps })}
        emptyOption={[emptyOption]}
        paddingBottom={0}
      />
    </Container>
  )
}
