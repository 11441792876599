import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import Type from './Type'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export default ({ types, ...rest }) => (
  <Container>
    {_.map(types, (type) => (
      <Type key={type.id} type={type} {...rest} />
    ))}
  </Container>
)
