import React from 'react'
import _ from 'lodash'
import Hub from '../../../../../../Input/Hub'
import onChange from './lib/onChange'

const emptyOption = { id: '', name: 'Any hub' }

export default ({
  formikProps,
  formikProps: {
    values: { scopes },
  },
}) => (
  <Hub
    value={() => _.find(scopes, { scopable: 'Hub' }) || emptyOption}
    onChange={onChange({ formikProps })}
    emptyOption={emptyOption}
  />
)
