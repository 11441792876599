import React, { Fragment } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Moment from 'moment-timezone'
import { extendMoment } from 'moment-range'
import Chart from './Chart'

const moment = extendMoment(Moment)

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  ${breakpoint('md')`
    grid-template-columns: repeat(7, 1fr);
  `}
`

const DateTag = styled.div`
  font-size: 14px;
`

const InsideDay = styled.div`
  height: 100px;
  margin: 5px;
`

export default ({
  data,
  formikProps: {
    values: { startDate, endDate },
  },
}) => {
  const month = moment.range(startDate, endDate)

  return (
    <Container>
      {_.map(Array.from(month.by('week')), (week) => (
        <Fragment key={week}>
          {_.map(
            Array.from(
              moment
                .range(
                  week.clone().startOf('week').format(),
                  week.clone().endOf('week').format()
                )
                .by('day')
            ),
            (date) => (
              <InsideDay key={date}>
                <DateTag>{moment(date).format('ddd D')}</DateTag>

                <Chart date={date} data={data} />
              </InsideDay>
            )
          )}
        </Fragment>
      ))}
    </Container>
  )
}
