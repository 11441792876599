import { gql } from 'graphql.macro'
import SiteFragment from '../../lib/SiteFragment'

export default gql`
  mutation UpdateSite($id: ID!, $data: SiteDataInput!) {
    updateSite(id: $id, data: $data) {
      ...SiteFragment
    }
  }

  ${SiteFragment}
`
