import { gql } from 'graphql.macro'
import UnitFragment from '../../Units/lib/UnitFragment'
import ReadingTypeFragment from '../../ReadingTypes/lib/ReadingTypeFragment'

export default gql`
  fragment ModelReadingTypeFragment on ModelReadingType {
    id
    unitId
    readingTypeId

    unit {
      ...UnitFragment
    }

    readingType {
      ...ReadingTypeFragment
    }
  }

  ${UnitFragment}
  ${ReadingTypeFragment}
`
