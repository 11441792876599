export default ({
  formikProps: {
    values: {
      startDate,
      endDate,
    },
  },
  hoverRange,
}) => ({
  hoverRange,
  selectedRange: {
    from: startDate,
    to: endDate,
  },
  selectedRangeStart: startDate,
  selectedRangeEnd: endDate,
  hoverRangeStart: hoverRange && hoverRange.from,
  hoverRangeEnd: hoverRange && hoverRange.to,
})
