import React from 'react'
import styled from 'styled-components'
import {
  FiGrid,
  FiMap,
} from 'react-icons/fi'
import Button from './Button'

const Container = styled.div`
`

export default () => (
  <Container>
    <Button icon={FiGrid} type='grid' left>
      Grid
    </Button>
    <Button icon={FiMap} type='map' right>
      Map
    </Button>
  </Container>
)
