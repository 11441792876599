import React from 'react'
import formattedTimestamp from '../lib/formattedTimestamp'
import Row from '../Row'

export default ({
  trigger: {
    event,
    rule: { name },
    timestamp,
  },
}) => (
  <>
    {event && <Row label='Event ID' value={event.id} />}
    <Row label='Rule' value={name} />
    <Row label='Timestamp' value={formattedTimestamp({ timestamp })} />
  </>
)
