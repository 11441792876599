import _ from 'lodash'
import initialValues from './initialValues'

export default ({
  path,
  arrayHelpers: { push },
  data: { readingTypes },
  data,
}) => ({ itemId }, { resetForm }) => {
  const { id } = _.find(readingTypes, { id: itemId })

  push({
    unitId: null,
    readingTypeId: id,
  })

  resetForm({ values: initialValues })
}
