import React, { Children, cloneElement, useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import Button from '../Button'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: ${(props) => props.theme.colors.white};
`

const MenuContainer = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: ${(props) => props.theme.colors.dark8Transparent} 0px 3px 3px;
  padding: 10px 5px;
  border: 1px solid ${(props) => props.theme.colors.light4};
  z-index: 2;
`

export default ({ title, children, onClick = () => null, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Container>
      <Button onClick={() => setIsOpen(!isOpen)} {...rest}>
        {title}
      </Button>

      {isOpen && (
        <MenuContainer>
          {Children.map(_.compact(children), (child) =>
            cloneElement(child, {
              onClick: () => {
                setIsOpen(false)
                onClick()
              },
            })
          )}
        </MenuContainer>
      )}
    </Container>
  )
}
