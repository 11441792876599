import React from 'react'
import _ from 'lodash'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import Note from '../../Note'
import Item from './Item'

const Container = styled.div`
  width: 100%;
`

export default ({
  items,
  onReorder,
  ...rest
}) => {
  if (_.isEmpty(items)) {
    return (
      <Note>No records yet.</Note>
    )
  }

  if (!onReorder) {
    return (
      <>
        {_.map(_.sortBy(items, 'name'), (item, index) => (
          <Item
            key={item.id}
            item={item}
            {...rest}
          />
        ))}
      </>
    )
  }

  return (
    <DragDropContext onDragEnd={onReorder}>
      <Droppable droppableId='list'>
        {(provided, snapshot) => (
          <Container ref={provided.innerRef}>
            {_.map(items, (item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <Item
                    key={item.id}
                    innerRef={provided.innerRef}
                    provided={provided}
                    onReorder={onReorder}
                    item={item}
                    {...rest}
                  />
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </Container>
        )}
      </Droppable>
    </DragDropContext>
  )
}
