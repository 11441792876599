import React from 'react'
import { withTheme } from 'styled-components'
import Note from './Note'

export default withTheme(({
  theme: {
    colors: {
      green,
    },
  },
  ...rest
}) => (
  <Note color={green} {...rest} />
))
