import readingTokens from './readingTokens'

export default ({ data }) => [
  {
    token: 'timestamp',
    description: 'Timestamp of the Event.',
  },
  {
    token: 'sensorName',
    description: 'Name of the Sensor that emitted the Event.',
  },
  {
    token: 'sensorExternalId',
    description: 'External ID of the Sensor that emitted the Event.',
  },
  {
    token: 'sensorManufacturerLabel',
    description: 'Manufacturer Label of the Sensor that emitted the Event.',
  },
  {
    token: 'gatewayName',
    description: 'Name of the Gateway that received the Event.',
  },
  {
    token: 'ruleName',
    description: 'Name of the Rule that triggered this Action.',
  },
  ...readingTokens({ data }),
  {
    token: 'ruleSlug',
    description: 'Unique slug of the Rule that triggered this Action.',
  },
  {
    token: 'eventData',
    description: 'JSON of Event data received.',
  },
]
