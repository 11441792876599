import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const paddingXs = ({ size }) =>
  ({
    small: '5px',
    big: '15px 60px',
  }[size] || '10px 15px')

const paddingMd = ({ size }) =>
  ({
    small: '5px 10px',
    big: '15px 60px',
  }[size] || '10px 15px')

const fontSize = ({ size }) =>
  ({
    big: '20px',
  }[size] || '12px')

const backgroundColor = (props) => {
  if (props.$primary) {
    return props.theme.colors.black
  }

  if (props.danger) {
    return props.theme.colors.reddish
  }

  return props.theme.colors.light2
}

export default styled.a`
  background-color: ${backgroundColor};
  font-weight: 600;
  color: white;
  border: 0;
  border-radius: 4px;
  text-align: center;
  font-size: ${fontSize};
  text-decoration: none;
  cursor: pointer;
  padding: ${paddingXs};
  display: inline-block;

  ${breakpoint('md')`
    padding: ${paddingMd};
  `}
`
