import _ from 'lodash'
import query from '../../List/Items/lib/query'

export default (cache, {
  data: {
    createManufacturer,
  },
}) => {
  const { manufacturers } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { manufacturers: _.concat(manufacturers, createManufacturer) },
  })
}
