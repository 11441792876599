import React from 'react'
import styled from 'styled-components'
import Type from '../Type'
import Other from './Other'
import type from './lib/type'

const Container = styled.div`
  display: flex;
  margin: -10px;
  align-items: center;
`

export default ({
  formikProps,
  readingTypesData,
  eventTypesData,
  setIsOpen,
}) => (
  <Container>
    <Type
      type={type({ formikProps, readingTypesData, eventTypesData })}
      formikProps={formikProps}
    />
    <Other setIsOpen={setIsOpen} />
  </Container>
)
