import _ from 'lodash'
import { useQuery } from '@apollo/client'
import query from '../../components/CurrentUser/lib/query'

export default (props) => {
  const queryProps = useQuery(query, props)

  return {
    currentUser: _.get(queryProps, 'data.currentUser', null),
    ...queryProps,
  }
}
