import _ from 'lodash'
import groups from './groups'

export default ({ readingTypesQueryProps, eventTypesQueryProps }) => {
  const type = _.first(
    _.first(
      groups({
        readingTypesData: readingTypesQueryProps.data,
        eventTypesData: eventTypesQueryProps.data,
      })
    ).types
  )

  return {
    name: '',
    active: true,
    debouncePeriodInSeconds: 10,
    debouncePeriodPerSensor: true,
    accountId: '',
    actions: [],
    ...type.values,
  }
}
