import { gql } from 'graphql.macro'
import GatewayBaseFragment from '../../Gateways/lib/GatewayBaseFragment'
import AreaFragment from './AreaFragment'

export default gql`
  fragment AreaWithGatewaysFragment on Area {
    ...AreaFragment

    gateways {
      ...GatewayBaseFragment
    }
  }

  ${AreaFragment}
  ${GatewayBaseFragment}
`
