import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { darken } from 'polished'
import { FiMoreHorizontal } from 'react-icons/fi'

const Container = styled.div`
  cursor: pointer;
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  padding: 10px;
  margin: 10px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  border: 2px solid transparent;
  background-color: ${(props) => props.theme.colors.grey2};

  &:hover {
    background-color: ${(props) => darken(0.02, props.theme.colors.grey2)};
  }

  ${breakpoint('md')`
    width: 110px;
    height: 110px;
  `}
`

const InnerContainer = styled.div`
  width: 100%;
`

const IconsContainer = styled.div`
  padding-bottom: 10px;
`

const Icon = styled.div`
  font-size: 30px;
  max-height: 30px;
  max-width: 30px;
  margin: 0 2.5px;
`

const Title = styled.div`
  font-size: 13px;
`

export default ({ setIsOpen }) => (
  <Container onClick={() => setIsOpen(true)}>
    <InnerContainer>
      <IconsContainer>
        <Icon as={FiMoreHorizontal} />
      </IconsContainer>

      <Title>Select other preset</Title>
    </InnerContainer>
  </Container>
)
