import _ from 'lodash'

export default ({
  types,
  slug,
}) => (
  _.find(types, {
    values: {
      slug,
    },
  })
)
