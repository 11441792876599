import _ from 'lodash'
import convertValues from './convertValues'

const externalId = (records, id) => {
  const record = _.find(records, { id })
  if (!record) return id

  return record.externalId
}

const serializeManufacturer = ({
  name = '',
  code = '456',
}) => ({
  name,
  code,
})

const serializeModel = ({
  name = '',
  code = '123',
  capabilities = ['Interval'],
}) => ({
  name,
  code,
  capabilities,
})

const mfg = (manufacturers, id) => (
  serializeManufacturer(_.find(manufacturers, { id }) || { name: id })
)

const model = (models, id) => (
  serializeModel(_.find(models, { id }) || { name: id })
)

const event = (eventTypes = []) => (
  _.map(eventTypes, 'name')
)

export default ({
  values: {
    sensorId,
    gatewayId,
    manufacturerId,
    modelId,
    eventTypes,
    timestamp,
    db,
    ipAddress,
    values,
  },
  sensorsData: {
    sensors,
  },
  gatewaysData: {
    gateways,
  },
  manufacturersData: {
    manufacturers,
  },
  modelsData: {
    models,
  },
}) => (
  JSON.stringify([
    _.pickBy({
      gateway: externalId(gateways, gatewayId),
      tag: externalId(sensors, sensorId),
      mfg: mfg(manufacturers, manufacturerId),
      model: model(models, modelId),
      event: event(eventTypes),
      db,
      ipAddress,
      values: convertValues(values),
      timestamp,
    })
  ])
)
