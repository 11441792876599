import { gql } from 'graphql.macro'
import TermFifthLayerFragment from '../../Terms/lib/TermFifthLayerFragment'
import PeriodFragment from '../../Periods/lib/PeriodFragment'

export default gql`
  fragment ConditionFifthLayerFragment on Condition {
    id
    comparison

    termOne {
      ...TermFifthLayerFragment
    }

    termTwo {
      ...TermFifthLayerFragment
    }

    period {
      ...PeriodFragment
    }
  }

  ${TermFifthLayerFragment}
  ${PeriodFragment}
`
