import _ from 'lodash'

const extras = [
  {
    name: 'Temperature',
    value: ' (in Fahrenheit)',
  },
  {
    name: 'Ambient Temperature',
    value: ' (in Fahrenheit)',
  },
  {
    name: 'Humidity',
    value: ' (in Percent)',
  },
]

const extra = ({ name }) => _.find(extras, { name })?.value || ''

export default ({ data: { readingTypes } }) =>
  _.map(readingTypes, ({ name }) => ({
    token: _.camelCase(`reading ${name}`),
    description: `Reading value of ${name}${extra({ name })}.`,
  }))
