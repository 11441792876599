export default ({
  name,
  localIpAddress,
  publicIpAddress,
  accountId,
}) => ({
  name,
  localIpAddress,
  publicIpAddress,
  accountId,
})
