import _ from 'lodash'
export default ({
  sensors,
}) => (
  _.reduce(sensors, (result, sensor) => {
    let group = _.find(result, { area: sensor.area })

    if (!group) {
      result.push({
        area: sensor.area,
        sensors: [],
      })

      group = _.find(result, { area: sensor.area })
    }

    group.sensors.push(sensor)
    return result
  }, [])
)
