import React from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import ExtendedNavLink from '../../../ExtendedNavLink'
import colors from '../../../../../theme/colors'

const Container = styled.div`
  padding: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-top-right-radius: ${(props) => props.right && '4px'};
  border-bottom-right-radius: ${(props) => props.right && '4px'};
  border-top-left-radius: ${(props) => props.left && '4px'};
  border-bottom-left-radius: ${(props) => props.left && '4px'};
  font-weight: 600;
  color: ${(props) => props.theme.colors.dark7};
  background-color: ${(props) => props.theme.colors.light5};
  font-size: 14px;

  :hover {
    color: ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.transparentGrey};
  }

  ${breakpoint('lg')`
    min-width: 100px;
    font-size: 16px;
  `}
`

const Icon = styled.div`
  padding-right: 10px;
`

export default ({ type, icon, children, ...rest }) => (
  <Route
    path='/dashboard/:areaId'
    render={({
      match: {
        params: { areaId },
      },
    }) => (
      <Container
        as={ExtendedNavLink}
        to={`/dashboard/${type}`}
        activeStyle={{
          color: colors.white,
          backgroundColor: colors.dark7,
        }}
        {...rest}
      >
        <Icon as={icon} /> {children}
      </Container>
    )}
  />
)
