import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../../assets/logo-full.svg'
import Layout from '../Layout'
import Heading from '../Heading'
import ListHeader from '../List/Header'
import Form from './Form'

const LogoContainer = styled.div`
  padding: 40px 40px 0;
`

export default () => (
  <Layout maxWidth='295px' margin='100px auto 0'>
    <LogoContainer>
      <Logo />
    </LogoContainer>
    <ListHeader>
      <Heading>Login</Heading>
    </ListHeader>

    <Form />
  </Layout>
)
