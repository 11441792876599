import React from 'react'
import CurrentUser from '../../../../../../../../CurrentUser'
import Icon from '../../Icon'
import note from './lib/note'

export default ({ reading: { readingType }, reading, sensor }) => (
  <CurrentUser>
    {({ currentUser }) => (
      <Icon
        type={readingType}
        collection='readingTypes'
        note={note({ reading, sensor, currentUser })}
      />
    )}
  </CurrentUser>
)
