export default {
  name: '',
  active: true,
  areaId: '',
  manufacturerId: '',
  modelId: '',
  externalId: '',
  photoImageUrl: null,
  location: { x: 200, y: 100 },
}
