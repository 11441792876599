export default ({
  name,
  code,
  type,
  imageUrl,
  eventTypes,
  modelReadingTypes,
}) => ({
  name: name || '',
  code,
  type,
  imageUrl,
  eventTypes,
  modelReadingTypes,
})
