import { gql } from 'graphql.macro'
import ManufacturerFragment from '../../../lib/ManufacturerFragment'

export default gql`
  {
    manufacturers {
      ...ManufacturerFragment
    }
  }

  ${ManufacturerFragment}
`
