import moment from 'moment-timezone'
import value from '../../value'
import term from './term'

export default ({
  formikProps: {
    values: { condition },
  },
  formikProps,
  thresholdName,
  newValue,
}) => ({
  ...condition,
  termTwo: {
    type: 'boolean',
    condition: {
      termOne: term({
        lowerThreshold: moment.utc().hour(0).minute(0).toISOString(),
        upperThreshold:
          thresholdName === 'lower'
            ? newValue
            : value({ formikProps, thresholdName: 'lower' }),
      }),
      comparison: 'or',
      termTwo: term({
        lowerThreshold:
          thresholdName === 'upper'
            ? newValue
            : value({ formikProps, thresholdName: 'upper' }),
        upperThreshold: moment.utc().hour(23).minute(59).toISOString(),
      }),
    },
  },
})
