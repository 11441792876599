import { gql } from 'graphql.macro'
import ManufacturerFragment from '../../lib/ManufacturerFragment'

export default gql`
  mutation UpdateManufacturer($id: ID!, $data: ManufacturerDataInput!) {
    updateManufacturer(id: $id, data: $data) {
      ...ManufacturerFragment
    }
  }

  ${ManufacturerFragment}
`
