import _ from 'lodash'
import merge from '../../../../../lib/merge'
import feedQuery from '../../../Feed/Content/List/lib/query'
import passesFilters from './passesFilters'

export default ({
  sensorFiltersProps,
  sensorFiltersProps: { filters },
  client,
  feedItemAdded,
}) => {
  if (!passesFilters({ sensorFiltersProps, feedItemAdded })) return

  let prev

  try {
    prev = client.readQuery({
      query: feedQuery,
      variables: { filters },
    })
  } catch (e) {
    client.writeQuery({
      query: feedQuery,
      variables: { filters },
      data: merge(
        {},
        {
          feedItems: {
            __typename: 'FeedItemConnection',
            results: [],
            cursors: {
              __typename: 'Cursor',
              after: null,
              before: null,
              hasNext: false,
              hasPrevious: false,
            },
          },
        }
      ),
    })

    prev = client.readQuery({
      query: feedQuery,
      variables: { filters },
    })
  }

  if (!prev) return

  client.writeQuery({
    query: feedQuery,
    variables: { filters },
    data: merge({}, prev, {
      feedItems: {
        results: _.takeRight([...prev.feedItems.results, feedItemAdded], 100),
      },
    }),
  })
}
