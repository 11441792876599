import React from 'react'
import isOutdated from '../lib/isOutdated'
import Time from '../Time'

export default ({ event }) => {
  if (!isOutdated({ event })) {
    return null
  }

  return (
    <Time
      event={event}
      tooltipContentLabel='Last reading:'
      tooltipContentFromNow
      color='reddish'
    />
  )
}
