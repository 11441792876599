import _ from 'lodash'
import moment from 'moment-timezone'

const namesWithoutEtc = _.reject(moment.tz.names(), (name) =>
  name.match(/^Etc\//)
)

export default _.sortBy(
  _.map(namesWithoutEtc, (value) => ({
    value,
    label: `GMT${moment.tz(value).format('Z')} ${value}`,
  })),
  [
    ({ value }) => {
      const tz = moment
        .tz(value)
        .format('Z')
        .replace(':00', '')
        .replace(':30', '.5')
      return parseFloat(tz)
    },
  ]
)
