import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { FiArrowDown } from 'react-icons/fi'

const DefaultContainer = styled.div`
  padding: 20px 10px;
  font-weight: 600;
  display: flex;
  align-items: center;

  ${breakpoint('md')`
    padding: 20px 40px;
  `}
`

const Pill = styled.div`
  display: inline-block;
  font-size: 12px;
  border-radius: 4px;
  margin-right: 10px;
  font-weight: 400;
  background-color: ${(props) => props.theme.colors.lightGrey};
  color: ${(props) => props.theme.colors.black};
  padding: 5px;
`

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.orange5};
  color: black;
  display: flex;
  align-items: center;
`

const InnerContainer = styled.div`
  margin: 20px 40px;
  font-weight: 600;
  display: flex;
  align-items: center;
`

const Icon = styled.div`
  margin-left: 5px;
`

export default (props) => {
  const {
    group: {
      site: { id, name },
    },
  } = props

  if (id !== null) {
    return (
      <DefaultContainer>
        <Pill>Site</Pill> {name}
      </DefaultContainer>
    )
  }

  return (
    <Container>
      <InnerContainer>
        <Pill>Site</Pill> Not set <Icon as={FiArrowDown} />
      </InnerContainer>
    </Container>
  )
}
