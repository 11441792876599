import { gql } from 'graphql.macro'
import SensorBaseFragment from './SensorBaseFragment'

export default gql`
  {
    sensors {
      ...SensorBaseFragment
    }
  }

  ${SensorBaseFragment}
`
