import { gql } from 'graphql.macro'
import RoleFragment from '../../../../../Roles/lib/RoleFragment'

export default gql`
  {
    roles {
      ...RoleFragment
    }
  }

  ${RoleFragment}
`
