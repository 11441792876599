import colors from './colors'

export default {
  none: 'none',
  light2: `1px solid ${colors.light2}`,
  light4: `0.5px solid ${colors.light4}`,
  active: `3px solid ${colors.light2}`,
  inactive: `3px solid ${colors.transparent}`,
  dark8: `1px solid ${colors.dark8}`,
}
