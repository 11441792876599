import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import Layout from '../../Layout'
import Loading from '../../Loading'
import Error from '../../Error'
import EditHeader from '../../Edit/Header'
import Form from '../Form'
import normalizeData from '../lib/normalizeData'
import query from './lib/query'
import mutation from './lib/mutation'
import initialValues from './lib/initialValues'

export default ({
  match: {
    params: { id },
  },
}) => {
  const { loading, error, data } = useQuery(query, {
    variables: {
      id,
    },
  })

  const [mutate, mutationProps] = useMutation(mutation)
  const [imageUrlMutate, imageUrlMutationProps] = useMutation(mutation, {
    variables: {
      id,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <Layout>
      <EditHeader>Edit model</EditHeader>

      <Form
        initialValues={initialValues(data.model)}
        onSubmit={(data) =>
          mutate({ variables: { id, data: normalizeData(data) } })
        }
        imageUrlMutate={imageUrlMutate}
        imageUrlMutationProps={imageUrlMutationProps}
      />
      {mutationProps.error && <Error leftAligned />}
      {mutationProps.data && <Redirect to='/models' />}
    </Layout>
  )
}
