import { gql } from 'graphql.macro'
import ManufacturerFragment from '../../../lib/ManufacturerFragment'

export default gql`
  mutation DeleteManufacturer($id: ID!) {
    deleteManufacturer(id: $id) {
      ...ManufacturerFragment
    }
  }

  ${ManufacturerFragment}
`
