import _ from 'lodash'

export default ({
  actions,
}) => (
  _.map(actions, ({
    id,
    name,
  }) => ({
    id,
    name,
  }))
)
