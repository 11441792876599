import { gql } from 'graphql.macro'

export default gql`
  fragment SiteFragment on Site {
    id
    name
    address
    photoImageUrl
    accountId

    gateways {
      id
      name
      externalId
    }
  }
`
