import moment from 'moment-timezone'
import scope from './scope'

export default ({ readingTypes, eventTypes, sensor }) => ({
  scope: scope({
    sensor,
    readingTypes,
    eventTypes,
  }),
  resolution: 'weekly',
  startDate: moment.utc().local().startOf('day').subtract(6, 'days').toDate(),
  endDate: moment.utc().local().startOf('day').toDate(),
})
