import defaultFormatter from './defaultFormatter'
import modelReadingType from './modelReadingType'

export default ({ reading, sensor, formatter, currentUser }) => {
  if (!reading?.value) return null
  if (!formatter) {
    formatter = defaultFormatter({
      reading,
      sensor,
    })
  }

  return formatter({
    reading,
    currentUser,
    modelReadingType: modelReadingType({
      reading,
      sensor,
    }),
  })
}
