import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../Layout'
import Heading from '../../Heading'
import Button from '../../Button'
import ListHeader from '../../List/Header'
import Items from './Items'

export default () => (
  <Layout>
    <ListHeader>
      <Heading>Manufacturers</Heading>
      <Button $primary as={Link} to='/manufacturers/new'>
        New Manufacturer
      </Button>
    </ListHeader>

    <Items />
  </Layout>
)
