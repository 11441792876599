import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import readingTypesQuery from '../../../ReadingTypes/List/Items/lib/query'
import eventTypesQuery from '../../../EventTypes/List/Items/lib/query'
import Loading from '../../../Loading'
import Error from '../../../Error'
import Inner from './Inner'
import Advanced from './Advanced'

export default ({ formikProps }) => {
  const readingTypesQueryProps = useQuery(readingTypesQuery)
  const eventTypesQueryProps = useQuery(eventTypesQuery)

  if (_.some([readingTypesQueryProps, eventTypesQueryProps], 'loading')) {
    return <Loading />
  }

  if (_.some([readingTypesQueryProps, eventTypesQueryProps], 'error')) {
    return <Error />
  }

  return (
    <>
      <Inner
        formikProps={formikProps}
        readingTypesData={readingTypesQueryProps.data}
        eventTypesData={eventTypesQueryProps.data}
      />
      {false && <Advanced formikProps={formikProps} />}
    </>
  )
}
