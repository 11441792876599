import _ from 'lodash'

export default ({
  formikProps: {
    values: { scopes },
    setFieldValue,
  },
}) => (getOptionValue) => () => (
  options,
  { action, option, removedValue },
  b
) => {
  if (action === 'select-option') {
    setFieldValue(
      'scopes',
      _.concat(scopes, {
        scopable: 'Sensor',
        scopableId: option.id,
        name: option.name,
      })
    )
  } else if (action === 'remove-value') {
    setFieldValue(
      'scopes',
      _.reject(scopes, {
        scopableId: removedValue.scopableId,
      })
    )
  } else if (action === 'clear') {
    setFieldValue(
      'scopes',
      _.reject(scopes, {
        scopable: 'Sensor',
      })
    )
  } else {
    console.log('Action not supported.', { action })
  }
}
