import React from 'react'
import RelationListInput from '../../../Input/RelationList'
import eventTypesQuery from '../../../EventTypes/List/Items/lib/query'

export default ({
  formikProps,
}) => (
  <RelationListInput
    query={eventTypesQuery}
    path='eventTypes'
    formikProps={formikProps}
    header='Event types'
  />
)
