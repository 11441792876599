import Moment from 'react-moment'
import moment from 'moment-timezone'

let isConfigured = false

export default ({ currentUser }) => {
  if (isConfigured) return
  if (!currentUser) return

  const { timezone } = currentUser

  isConfigured = true

  moment.tz.setDefault(timezone)
  Moment.globalMoment = moment
  Moment.globalTimezone = timezone

  moment.defineLocale('en-short', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'now',
      ss: 'now',
      m: '%d min',
      mm: '%d min',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d days',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years',
    },
  })

  moment.locale('en')
  Moment.startPooledTimer()
}
