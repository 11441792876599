import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import Loading from '../../../Loading'
import Error from '../../../Error'
import query from '../../../Models/Edit/lib/query'
import Group from '../../../Rules/Form/Group'
import Description from '../../../Input/Description'
import EventTypeDebounce from './EventTypeDebounce'

const ItemsContainer = styled.div`
  padding-top: 20px;
`

export default ({
  formikProps: {
    values: { modelId },
  },
  formikProps,
}) => {
  if (!modelId) return null

  const { loading, error, data } = useQuery(query, {
    variables: {
      id: modelId,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const {
    model: { eventTypes },
  } = data

  return (
    <Group label='Debounce periods'>
      <Description>
        Debounce period in case two or more gateways try to send the same
        identical event.
      </Description>

      <ItemsContainer>
        {_.map(_.sortBy(eventTypes, 'name'), (eventType) => (
          <EventTypeDebounce
            key={eventType.id}
            eventType={eventType}
            formikProps={formikProps}
          />
        ))}
      </ItemsContainer>
    </Group>
  )
}
