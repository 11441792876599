import { gql } from 'graphql.macro'
import AccountFragment from '../../../lib/AccountFragment'

export default gql`
  mutation DeleteAccount($id: ID!) {
    deleteAccount(id: $id) {
      ...AccountFragment
    }
  }

  ${AccountFragment}
`
