import React from 'react'
import TextInput from '../Text'
import Float from './Float'
import Boolean from './Boolean'

const inputs = {
  Float,
  Boolean,
}

export default ({
  readingType: {
    type,
  } = {},
  ...rest
}) => {
  const Component = inputs[type] || TextInput

  return <Component {...rest} />
}
