import React from 'react'
import { Toggle } from 'libreact/lib/Toggle'
import Modal from 'react-modal'
import styled from 'styled-components'
import Button from '../../../../../../../Button'
import Inner from './Inner'

const Container = styled.div``

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: 5px;
`

const InnerContainer = styled.div`
  padding: 10px;
  font-family: monospace;
`

const style = {
  content: {
    maxWidth: '500px',
    margin: '0 auto',
  },
}

export default ({ eventId }) => (
  <Toggle>
    {({ on, toggle }) => (
      <Container>
        <ButtonContainer>
          <Button size='small' onClick={toggle}>
            {on ? 'Hide' : 'Performance'}
          </Button>
        </ButtonContainer>
        <Modal isOpen={on} onRequestClose={toggle} style={style}>
          {on && (
            <InnerContainer>
              <Inner eventId={eventId} />
            </InnerContainer>
          )}
        </Modal>
      </Container>
    )}
  </Toggle>
)
