import React from 'react'
import styled from 'styled-components'
import Icon from './Icon'
import isActive from './lib/isActive'
import onClick from './lib/onClick'
import backgroundColor from './lib/backgroundColor'

const Container = styled.div`
  padding: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.theme.colors.black};
  border: 2px solid black;
  border-color: ${(props) =>
    props.active ? props.theme.colors.black : props.backgroundColor};
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
`

export default ({ type: { name }, type, formikProps }) => (
  <Container
    active={isActive({ type, formikProps })}
    onClick={onClick({ type, formikProps })}
    backgroundColor={backgroundColor({ type })}
  >
    <Icon type={type} /> {name}
  </Container>
)
