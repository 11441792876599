import React from 'react'
import styled from 'styled-components'
import TextInput from '../../../../../Input/Text'
import Button from '../../../../../Button'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  max-width: 300px;
`

const InputContainer = styled.div``

export default ({ header: { key, value }, index, arrayHelpers }) => (
  <Container>
    <InputContainer>
      <TextInput label='Key' name={`webhookHeaders[${index}].key`} />
    </InputContainer>

    <InputContainer>
      <TextInput label='Value' name={`webhookHeaders[${index}].value`} />
    </InputContainer>

    <Button onClick={() => arrayHelpers.remove(index)}>Remove</Button>
  </Container>
)
