import React from 'react'
import styled from 'styled-components'
import { Route } from 'react-router-dom'
import Layout from '../../Layout'
import Grid from './Grid'
import Map from './Map'
import Sensor from './Sensor'

const Container = styled.div`
  flex-grow: 1;
`

export default () => (
  <Container>
    <Layout maxWidth='initial' outerPadding={0}>
      <Route path='/dashboard/sensors/:sensorId' component={Sensor} />
      <Route path='/dashboard/grid' component={Grid} />
      <Route path='/dashboard/map' component={Map} />
    </Layout>
  </Container>
)
