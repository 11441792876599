import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import { FieldArray } from 'formik'
import Loading from '../../../Loading'
import Error from '../../../Error'
import EditList from '../../../Edit/List'
import actionsQuery from '../../../Actions/List/Items/lib/query'
import AddButton from './AddButton'
import onDelete from './lib/onDelete'

export default ({
  formikProps: {
    values: { actions },
    values,
  },
}) => {
  const { loading, error, data } = useQuery(actionsQuery)

  if (loading) return <Loading />
  if (error) return <Error />

  const items = _.map(actions, ({ id }) => _.find(data.actions, { id }))

  return (
    <FieldArray
      name='actions'
      render={(arrayHelpers) => (
        <EditList
          header='Actions'
          addButton={
            <AddButton
              data={data}
              values={values}
              arrayHelpers={arrayHelpers}
            />
          }
          items={items}
          onDelete={onDelete({ arrayHelpers, values })}
        />
      )}
    />
  )
}
