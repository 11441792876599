import { gql } from 'graphql.macro'
import RuleFragment from '../../lib/RuleFragment'

export default gql`
  query Rule($id: ID!) {
    rule(id: $id) {
      ...RuleFragment
    }
  }

  ${RuleFragment}
`
