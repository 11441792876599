import React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import AreaInput from '../../../Input/Area'
import SensorInput from '../../../Input/Sensor'
import SiteInput from '../../../Input/Site'
import GatewayInput from '../../../Input/Gateway'
import EventTypeInput from '../../../Input/EventType'
import ReadingTypeInput from '../../../Input/ReadingType'
import ModelInput from '../../../Input/Model'
import ManufacturerInput from '../../../Input/Manufacturer'
import useSensorFilters from '../../useSensorFilters'
import StatusInput from './StatusInput'

const Container = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
  flex-wrap: wrap;
  max-width: 900px;

  ${breakpoint('sm')`
    grid-template-columns: 1fr 1fr;
  `}

  ${breakpoint('md')`
    grid-template-columns: 1fr 1fr 1fr;
  `}
`

const InputContainer = styled.div`
  display: flex;
  min-width: 200px;
  flex-grow: 1;
`

export default ({ ...rest }) => {
  const {
    formikProps: { values },
  } = useSensorFilters()

  return (
    <Container>
      {_.has(values, 'areaId') && (
        <InputContainer>
          <AreaInput includeEmpty={{ id: '', name: 'All areas' }} {...rest} />
        </InputContainer>
      )}

      {_.has(values, 'sensorId') && (
        <InputContainer>
          <SensorInput
            includeEmpty={{ id: '', name: 'All sensors' }}
            {...rest}
          />
        </InputContainer>
      )}

      {_.has(values, 'siteId') && (
        <InputContainer>
          <SiteInput includeEmpty={{ id: '', name: 'All sites' }} {...rest} />
        </InputContainer>
      )}

      {_.has(values, 'gatewayId') && (
        <InputContainer>
          <GatewayInput
            includeEmpty={{ id: '', name: 'All gateways' }}
            {...rest}
          />
        </InputContainer>
      )}

      {_.has(values, 'eventTypeId') && (
        <InputContainer>
          <EventTypeInput
            includeEmpty={{ id: '', name: 'All event types' }}
            {...rest}
          />
        </InputContainer>
      )}

      {_.has(values, 'readingTypeId') && (
        <InputContainer>
          <ReadingTypeInput
            includeEmpty={{ id: '', name: 'All reading types' }}
            {...rest}
          />
        </InputContainer>
      )}

      {_.has(values, 'modelId') && (
        <InputContainer>
          <ModelInput
            type='sensor'
            includeEmpty={{ id: '', name: 'All models' }}
            {...rest}
          />
        </InputContainer>
      )}

      {_.has(values, 'manufacturerId') && (
        <InputContainer>
          <ManufacturerInput
            includeEmpty={{ id: '', name: 'All manufacturers' }}
            {...rest}
          />
        </InputContainer>
      )}

      {_.has(values, 'status') && (
        <InputContainer>
          <StatusInput {...rest} />
        </InputContainer>
      )}
    </Container>
  )
}
