import { gql } from 'graphql.macro'
import EventFullFragment from '../../Events/lib/EventFullFragment'
import TriggerFragment from '../../Triggers/lib/TriggerFragment'

export default gql`
  fragment FeedItemFragment on FeedItem {
    id
    createdAt

    item {
      ...EventFullFragment
      ...TriggerFragment
    }
  }

  ${EventFullFragment}
  ${TriggerFragment}
`
