import { gql } from 'graphql.macro'
import GatewayFragment from '../../lib/GatewayFragment'

export default gql`
  query Gateway($id: ID!) {
    gateway(id: $id) {
      ...GatewayFragment
    }
  }

  ${GatewayFragment}
`
