import _ from 'lodash'
import query from '../../List/Items/lib/query'

export default (cache, {
  data: {
    createSensor,
  },
}) => {
  const { sensors } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { sensors: _.concat(sensors, createSensor) },
  })
}
