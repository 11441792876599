import { gql } from 'graphql.macro'
import AccountFragment from '../../lib/AccountFragment'

export default gql`
  query Account($id: ID!) {
    account(id: $id) {
      ...AccountFragment
    }
  }

  ${AccountFragment}
`
