import React from 'react'
import { ErrorMessage } from 'formik'
import styled from 'styled-components'

const Container = styled.div`
  color: ${props => props.theme.colors.reddish};
  font-size: 14px;
  padding-top: 5px;
`

export default (props) => (
  <ErrorMessage component={Container} {...props} />
)
