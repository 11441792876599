import { FiClock, FiCircle, FiEye, FiEyeOff } from 'react-icons/fi'
import { ReactComponent as Motion } from '../assets/icons/motion.svg'
import { FaMagnet } from 'react-icons/fa'
import colors from './colors'

export default [
  {
    name: 'Interval',
    icon: FiClock,
    backgroundColor: colors.types.interval,
  },
  {
    name: 'Button',
    icon: FiCircle,
    backgroundColor: colors.types.button,
  },
  {
    name: 'Presence',
    icon: FiEye,
    backgroundColor: colors.types.presence,
  },
  {
    name: 'Absence',
    icon: FiEyeOff,
    backgroundColor: colors.types.absence,
  },
  {
    name: 'Motion',
    icon: Motion,
    backgroundColor: colors.types.motion,
  },
  {
    name: 'Magnet Open',
    icon: FaMagnet,
    backgroundColor: colors.types.magnetOpened,
  },
]
