import React from 'react'
import {
  Link,
  withRouter,
} from 'react-router-dom'

export default withRouter(({
  to,
  location: {
    search,
  },
  staticContext,
  colorIndex,
  ...rest
}) => (
  <Link
    to={{
      pathname: to,
      search,
    }}
    {...rest}
  />
))
