import React from 'react'
import styled from 'styled-components'
import {
  FiSidebar,
  FiHome,
  FiRadio,
  FiGitMerge,
  FiZap,
  FiBox,
} from 'react-icons/fi'
import breakpoint from 'styled-components-breakpoint'
import CurrentUser from '../../CurrentUser'
import canAccessAnyAccount from '../../lib/canAccessAnyAccount'
import Button from './Button'
import Admin from './Admin'
import Profile from './Profile'

const Container = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-gap: 16px;
  padding-top: 20px;

  ${breakpoint('md')`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}

  ${breakpoint('lg')`
    display: flex;
    padding-top: 0;
  `}
`

export default ({ closeTopMenu }) => (
  <CurrentUser>
    {({ currentUser: { isGlobalAdmin }, currentUser }) => (
      <Container>
        <Button icon={FiSidebar} to='/dashboard/grid' onClick={closeTopMenu}>
          Dashboard
        </Button>
        <Button icon={FiHome} to='/areas' onClick={closeTopMenu}>
          Areas
        </Button>
        <Button icon={FiRadio} to='/gateways' onClick={closeTopMenu}>
          Gateways
        </Button>
        <Button icon={FiBox} to='/sensors' onClick={closeTopMenu}>
          Sensors
        </Button>
        {(isGlobalAdmin || canAccessAnyAccount({ currentUser })) && (
          <>
            <Button icon={FiGitMerge} to='/rules' onClick={closeTopMenu}>
              Rules
            </Button>
            <Button icon={FiZap} to='/actions' onClick={closeTopMenu}>
              Actions
            </Button>
          </>
        )}
        <Admin
          isGlobalAdmin={isGlobalAdmin}
          currentUser={currentUser}
          closeTopMenu={closeTopMenu}
        />
        <Profile currentUser={currentUser} closeTopMenu={closeTopMenu} />
      </Container>
    )}
  </CurrentUser>
)
