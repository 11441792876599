import _ from 'lodash'
import { celsiusToFahrenheit } from 'temperature'

export default ({ currentUser: { systemOfUnits } }) => ({ value }) => {
  if (systemOfUnits === 'imperial') {
    return `${celsiusToFahrenheit(value)} °F`
  }

  return `${_.round(value)} °C`
}
