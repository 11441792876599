import React from 'react'
import styled from 'styled-components'
import { SizeSensor } from 'libreact/lib/SizeSensor'

const height = ({ $maxWidth }) => `calc(${$maxWidth} / 2)`

const Container = styled.div`
  display: flex;
  min-height: ${height};
  max-width: ${(props) => props.$maxWidth};
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.light5};
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
  font-size: 16px;
  cursor: ${(props) => props.cursor};
  overflow: hidden;
  background-image: ${(props) => props.$backgroundImage};
`

const ChildrenContainer = styled.div`
  position: ${(props) => (props.center ? 'absolute' : 'static')};
`

export default ({
  url,
  children,
  maxWidth = '400px',
  onClick,
  center,
  ...rest
}) => (
  <SizeSensor>
    {({ width: totalWidth, height: totalHeight }) => (
      <Container
        $maxWidth={maxWidth}
        $backgroundImage={url ? `url(${url})` : 'none'}
        onClick={onClick && onClick({ totalWidth, totalHeight })}
        {...rest}
      >
        <ChildrenContainer center={center}>{children}</ChildrenContainer>
      </Container>
    )}
  </SizeSensor>
)
