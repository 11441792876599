import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Period from './Period'
import ScopeSelect from './ScopeSelect'

const Container = styled.div`
  display: flex;
  padding-bottom: 100px;
  flex-direction: column;

  ${breakpoint('md')`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  `}
`

export default (props) => (
  <Container>
    <ScopeSelect {...props} />
    <Period {...props} />
  </Container>
)
