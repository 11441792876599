import { gql } from 'graphql.macro'
import JobFragment from '../../Jobs/lib/JobFragment'
import SensorEventTypeDebounceFragment from '../../SensorEventTypeDebounces/lib/SensorEventTypeDebounceFragment'
import ReadingRangeFragment from '../../ReadingRanges/lib/ReadingRangeFragment'
import ReadingOffsetFragment from '../../ReadingOffsets/lib/ReadingOffsetFragment'
import SensorFragment from './SensorFragment'

export default gql`
  fragment SensorEditFragment on Sensor {
    ...SensorFragment

    eventTypeDebounces {
      ...SensorEventTypeDebounceFragment
    }

    deleteJob {
      ...JobFragment
    }

    deleteHistoryJob {
      ...JobFragment
    }

    readingRanges {
      ...ReadingRangeFragment
    }

    readingOffsets {
      ...ReadingOffsetFragment
    }
  }

  ${SensorFragment}
  ${SensorEventTypeDebounceFragment}
  ${JobFragment}
  ${ReadingRangeFragment}
  ${ReadingOffsetFragment}
`
