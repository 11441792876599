import { gql } from 'graphql.macro'
import ActionFragment from '../../../../lib/ActionFragment'

export default gql`
  mutation DuplicateAction($id: ID!) {
    duplicateAction(id: $id) {
      ...ActionFragment
    }
  }

  ${ActionFragment}
`
