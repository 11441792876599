import React from 'react'
import colors from '../../../../../../../../../theme/colors'

const RADIAN = Math.PI / 180

export default ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  index,
  payload: {
    payload: {
      datetime,
    },
  },
}) => {
  let x, y

  if (datetime.hour() === 0) {
    x = cx
    y = cy - outerRadius * Math.sin(midAngle * RADIAN) - 20
  } else if (datetime.hour() === 3) {
    x = cx + outerRadius * Math.cos(midAngle * RADIAN) + 10
    y = cy - outerRadius * Math.cos(midAngle * RADIAN)
  } else if (datetime.hour() === 6) {
    x = cx + outerRadius * Math.cos(midAngle * RADIAN) + 30
    y = cy
  } else if (datetime.hour() === 9) {
    x = cx + outerRadius * Math.cos(midAngle * RADIAN) + 40
    y = cy - outerRadius * Math.sin(midAngle * RADIAN)
  } else if (datetime.hour() === 12) {
    x = cx
    y = cy - outerRadius * Math.sin(midAngle * RADIAN) + 20
  } else if (datetime.hour() === 15) {
    x = cx + outerRadius * Math.cos(midAngle * RADIAN) - 10
    y = cy - outerRadius * Math.cos(midAngle * RADIAN)
  } else if (datetime.hour() === 18) {
    x = cx + outerRadius * Math.cos(midAngle * RADIAN) - 30
    y = cy
  } else if (datetime.hour() === 21) {
    x = cx + outerRadius * Math.cos(midAngle * RADIAN) - 40
    y = cy + outerRadius * Math.cos(midAngle * RADIAN) - 30
  }

  if (!x || !y) return

  return (
    <text
      x={x}
      y={y}
      fill={colors.black}
      fontSize='12px'
      fontWeight={600}
      textAnchor='middle'
      dominantBaseline='central'
    >
      {datetime.format('h A')}
    </text>
  );
};
