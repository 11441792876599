import formikKey from '../formikKey'
import positionToValue from '../positionToValue'
import handleInversion from './handleInversion'
import getIsInverted from './isInverted'

export default ({
  formikProps,
  formikProps: { setFieldValue },
  lowerValue,
  upperValue,
}) => ({ width, thresholdName }) => (event, { x }) => {
  const isInverted = getIsInverted({
    lowerValue,
    upperValue,
  })

  const newValue = positionToValue({
    position: x,
    width,
  })

  setFieldValue(formikKey({ formikProps, thresholdName }), newValue)

  handleInversion({
    isInverted,
    formikProps,
    thresholdName,
    newValue,
  })
}
