import { gql } from 'graphql.macro'
import AreaWithGatewaysFragment from '../../lib/AreaWithGatewaysFragment'

export default gql`
  mutation UpdateArea($id: ID!, $data: AreaDataInput!) {
    updateArea(id: $id, data: $data) {
      ...AreaWithGatewaysFragment
    }
  }

  ${AreaWithGatewaysFragment}
`
