import React from 'react'
import ExtendedLink from '../../../ExtendedLink'
import Dot from '../../../../ImageContainer/Dot'

export default ({
  sensor: {
    id,
    color,
    location,
  },
  sensor,
}) => (
  <Dot
    as={ExtendedLink}
    to={`/dashboard/map/sensors/${id}`}
    colorIndex={color}
    {...location}
  />
)
