import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import Loading from '../../../Loading'
import Error from '../../../Error'
import useSensorFilters from '../../../SensorFilters/useSensorFilters'
import ListGroupedByGatewayItems from '../../../List/GroupedByGatewayItems'
import reorderMutation from '../../Edit/lib/mutation'
import Label from './Label'
import query from './lib/query'
import deleteMutation from './lib/deleteMutation'

export default () => {
  const { filters } = useSensorFilters()
  const { loading, error, data } = useQuery(query, {
    variables: {
      filters,
    },
  })

  const [onDelete, mutationProps] = useMutation(deleteMutation)

  if (loading) return <Loading />
  if (error) return <Error />

  const { sensors } = data

  return (
    <>
      <ListGroupedByGatewayItems
        items={sensors}
        query={query}
        queryName='sensors'
        variables={{ filters: [] }}
        gatewayIdKey='primaryGatewayId'
        reorderMutation={reorderMutation}
        reorderMutationName='updateSensor'
        itemLabel={Label}
        onDelete={onDelete}
        onDeleteAvailable={({ item: { deleteHistoryJob, deleteJob } }) =>
          !deleteJob && !deleteHistoryJob
        }
        chartUrl={({ id }) => `/dashboard/sensors/${id}`}
        editUrl={({ id }) => `/sensors/edit/${id}`}
        includeGatewayNull
        isDroppableBetweenGroups={false}
      />
      {mutationProps.error && <Error />}
    </>
  )
}
