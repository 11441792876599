import React from 'react'
import { Formik, Form } from 'formik'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import TextInput from '../../Input/Text'
import Checkbox from '../../Input/Checkbox'
import ColorInput from '../../Input/Color'
import ImageInput from '../../Input/Image'
import SiteInput from '../../Input/Site'
import validationSchema from './lib/validationSchema'

export default ({
  children,
  mapImageUrlMutate = () => null,
  mapImageUrlMutationProps = () => null,
  ...rest
}) => (
  <Formik validationSchema={validationSchema} {...rest}>
    {() => (
      <Form>
        <FormContainer>
          <TextInput label='Name' name='name' $maxWidth='300px' />
          <Checkbox label='Active' name='active' />
          <ColorInput label='Color' name='color' />
          <SiteInput />
          <ImageInput
            label='Map'
            name='mapImageUrl'
            mutate={mapImageUrlMutate}
            mutationProps={mapImageUrlMutationProps}
          />

          {children}

          <FormButton />
        </FormContainer>
      </Form>
    )}
  </Formik>
)
