import React, { useRef } from 'react'
import useComponentSize from '@rehooks/component-size'
import 'resize-observer-polyfill'
import styled from 'styled-components'
import _ from 'lodash'
import { ResponsiveContainer, PieChart, Pie, Tooltip, Cell } from 'recharts'
import colors from '../../../../../../../../theme/colors'
import CustomTooltip from '../CustomTooltip'
import serialize from './lib/serialize'
import innerRadius from './lib/innerRadius'
import outerRadius from './lib/outerRadius'
import renderCustomizedLabel from './lib/renderCustomizedLabel'
import fill from './lib/fill'
import calculateMaxEvents from './lib/calculateMaxEvents'

const Container = styled.div`
  display: flex;
`

const ChildContainer = styled.div`
  width: 0;
  flex-grow: 1;
`

export default ({
  data,
  formikProps: {
    values: { startDate, endDate },
  },
}) => {
  const serializedDatas = _.map(_.times(7), (day) =>
    serialize({ day, data, startDate })
  )

  const maxEventsCount = calculateMaxEvents({ serializedDatas, type: 'event' })
  const maxTriggersCount = calculateMaxEvents({
    serializedDatas,
    type: 'trigger',
  })

  const ref = useRef(null)
  const { width } = useComponentSize(ref)
  const isSmall = width < 500

  return (
    <Container ref={ref}>
      <ChildContainer>
        <ResponsiveContainer width='100%' height={isSmall ? 250 : 500}>
          <PieChart>
            {_.map(serializedDatas, (serializedData) => (
              <Pie
                key={`grid-${serializedData.day}`}
                data={serializedData.data}
                dataKey='hour'
                nameKey='datetime'
                label={serializedData.day === 6 && renderCustomizedLabel}
                labelLine={false}
                cx='50%'
                cy='50%'
                innerRadius={innerRadius({ day: serializedData.day, isSmall })}
                outerRadius={outerRadius({ day: serializedData.day, isSmall })}
                fill={colors.grey}
                isAnimationActive={false}
                startAngle={90}
                endAngle={450}
              >
                {_.map(serializedData.data, (entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={fill({ entry, maxEventsCount, maxTriggersCount })}
                  />
                ))}
              </Pie>
            ))}

            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      </ChildContainer>
    </Container>
  )
}
