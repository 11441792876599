import * as yup from 'yup'

const schema = yup.object().shape({
  url: yup.string().nullable().url('This must be a valid URL.'),
})

export default (key) => (value) => {
  let error = ''

  try {
    schema.validateSyncAt(key, value)
  } catch(e) {
    error = e.message
  }

  return error
}
