import { gql } from 'graphql.macro'
import ReadingFragment from '../../Readings/lib/ReadingFragment'
import EventBaseFragment from '../../Events/lib/EventBaseFragment'
import SensorFragment from './SensorFragment'
import ReadingRangeFragment from '../../ReadingRanges/lib/ReadingRangeFragment'

export default gql`
  fragment SensorFullFragment on Sensor {
    ...SensorFragment

    status

    lastReadings {
      ...ReadingFragment

      event {
        timestamp
      }
    }

    readingRanges {
      ...ReadingRangeFragment
    }

    lastEvents {
      ...EventBaseFragment

      eventTypes {
        ...EventTypeFragment
      }
    }
  }

  ${SensorFragment}
  ${ReadingFragment}
  ${EventBaseFragment}
  ${ReadingRangeFragment}
`
