import merge from '../../../../../lib/merge'
import SensorFullFragment from '../../../../Sensors/lib/SensorFullFragment'

const fragment = ({ id }) => ({
  id: `Sensor:${id}`,
  fragment: SensorFullFragment,
  fragmentName: 'SensorFullFragment',
})

export default ({
  client,
  feedItemAdded: {
    item: { sensor },
    item,
  },
  feedItemAdded,
}) => {
  if (!sensor) return

  const { id } = sensor

  let prev

  try {
    prev = client.readFragment(fragment({ id }))
  } catch (e) {}

  if (!prev) return

  client.writeFragment({
    ...fragment({ id }),
    data: merge({}, prev, sensor),
  })
}
