import React from 'react'
import { FiActivity, FiChevronsLeft } from 'react-icons/fi'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

const Container = styled.div`
  color: ${(props) => props.theme.colors.grey7};
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    color: ${(props) => props.theme.colors.grey9};
  }

  ${breakpoint('md')`
    padding: 20px;
    flex-direction: column;
  `}
`

const ChevronContainer = styled.div`
  display: none;

  ${breakpoint('md')`
    display: flex;
  `}
`

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
`

const Icon = styled.div`
  font-size: 20px;
`

const Label = styled.div`
  font-size: 14px;
  padding-left: 10px;

  ${breakpoint('md')`
    padding-left: 0;
    padding-top: 10px;
    text-align: center;
  `}
`

export default ({ setIsOpen, isOpen }) => (
  <Container onClick={() => setIsOpen(!isOpen)}>
    <IconsContainer>
      <ChevronContainer>
        <Icon as={FiChevronsLeft} />
      </ChevronContainer>
      <Icon as={FiActivity} />
    </IconsContainer>

    <Label>Activity feed</Label>
  </Container>
)
