import { gql } from 'graphql.macro'
import SensorEditFragment from '../../../lib/SensorEditFragment'

export default gql`
  query SensorsList($filters: [FilterInput!]!) {
    sensors(filters: $filters) {
      ...SensorEditFragment
    }
  }

  ${SensorEditFragment}
`
