import React from 'react'
import styled from 'styled-components'
import TypesGroup from './TypesGroup'

const Container = styled.div`
  border: 1px solid ${props => props.theme.colors.light4};
  padding: 10px;
  padding-right: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
`

const LabelContainer = styled.div`
  margin-top: -20px;
  padding-bottom: 10px;
`

const Label = styled.div`
  font-size: 16px;
  font-weight: 500;
  background-color: white;
  display: inline-flex;
  padding: 0px 10px;
`

export default ({
  eventTypes,
  readingTypes,
  ...rest
}) => (
  <Container>
    <LabelContainer>
      <Label>
        Measure
      </Label>
    </LabelContainer>

    <TypesGroup types={eventTypes} {...rest} />
    <TypesGroup types={readingTypes} {...rest} />
  </Container>
)
