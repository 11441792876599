import { gql } from 'graphql.macro'
import GatewayFragment from '../../../lib/GatewayFragment'

export default gql`
  {
    gateways {
      ...GatewayFragment
    }
  }

  ${GatewayFragment}
`
