import React from 'react'
import isSenseCookie from '../../../../../../../../../lib/isSenseCookie'
import SenseCookieImage from './SenseCookieImage'
import Container from './Container'

export default ({
  sensor: {
    model: { imageUrl },
  },
  sensor,
}) => {
  if (!imageUrl) return null
  if (isSenseCookie({ sensor })) {
    return <SenseCookieImage sensor={sensor} />
  }

  return <Container $imageUrl={imageUrl} />
}
