import _ from 'lodash'
import query from '../../List/Items/lib/query'

export default (cache, { data: { createRule } }) => {
  let prev

  try {
    prev = cache.readQuery({ query })
  } catch (e) {}

  if (!prev) return

  const { rules } = prev

  cache.writeQuery({
    query,
    data: { rules: _.concat(rules, createRule) },
  })
}
