import React from 'react'
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import Draggable from 'react-draggable'

const Container = styled.div`
  position: absolute;
  cursor: pointer;
`

const Label = styled.div`
  position: absolute;
  left: 0;
  top: calc(-20px - 4px - (16px - 10px) / 2);
  background-color: ${(props) => props.theme.colors[props.$backgroundColor]};
  color: ${(props) => props.theme.colors.grey1};
  height: 20px;
  width: 34px;
  margin-left: calc(-17px + 16px / 2);
  font-size: 10px;
  border-radius: 4px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoint('md')`
    font-weight: 500;
    height: 30px;
    top: calc(-30px - 4px - (16px - 10px) / 2);
    width: 50px;
    margin-left: calc(-25px + 16px / 2);
    font-size: 14px;
  `}
`

const Handle = styled.div`
  position: absolute;
  left: 0;
  top: -3px;
  height: 16px;
  width: 16px;
  background-color: ${(props) => props.theme.colors[props.$backgroundColor]};
  border-radius: 9999px;
`

export default ({
  backgroundColor,
  width,
  thresholdName,
  position,
  value,
  lowerValue,
  upperValue,
  formatValue,
  onDrag,
  grid,
}) => (
  <Draggable
    axis='x'
    grid={grid({ width })}
    position={{
      x: position,
      y: 0,
    }}
    bounds='parent'
    onDrag={onDrag({
      width,
      thresholdName,
    })}
  >
    <Container>
      <Label $backgroundColor={backgroundColor}>{formatValue({ value })}</Label>
      <Handle $backgroundColor={backgroundColor} />
    </Container>
  </Draggable>
)
