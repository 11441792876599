import moment from 'moment-timezone'
import dayStartTime from './dayStartTime'

export default (time) => {
  const { hours, minutes } = moment(time).local().toObject()

  return moment
    .utc(dayStartTime)
    .local()
    .add(hours, 'hours')
    .add(minutes, 'minutes')
}
