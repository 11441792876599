import colors from './colors'
import borders from './borders'
import readingTypes from './readingTypes'
import eventTypes from './eventTypes'
import breakpoints from './breakpoints'

export default {
  borders,
  colors,
  readingTypes,
  eventTypes,
  breakpoints,
}
