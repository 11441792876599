import { gql } from 'graphql.macro'
import ManufacturerFragment from '../../lib/ManufacturerFragment'

export default gql`
  mutation CreateManufacturer($data: ManufacturerDataInput!) {
    createManufacturer(data: $data) {
      ...ManufacturerFragment
    }
  }

  ${ManufacturerFragment}
`
