import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ReactComponent as Icon } from '../../../assets/logo-full-name-only.svg'

const Container = styled(Link)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin: 0 20px 0 5px;
  adding: 5px;
`

const Logo = styled(Icon)`
  height: 20px;
`

export default () => (
  <Container to='/dashboard/grid'>
    <Logo />
  </Container>
)
