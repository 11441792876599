import { gql } from 'graphql.macro'
import HubFragment from '../../lib/HubFragment'

export default gql`
  mutation UpdateHub($id: ID!, $data: HubDataInput!) {
    updateHub(id: $id, data: $data) {
      ...HubFragment
    }
  }

  ${HubFragment}
`
