import _ from 'lodash'

const descriptions = {
  'temperature-below-threshold': {
    imperial: 'Threshold (°F)',
    metric: 'Threshold (°C)',
  },
  'temperature-above-threshold': {
    imperial: 'Threshold (°F)',
    metric: 'Threshold (°C)',
  },
  'voltage-below-threshold': 'Threshold (V)',
  'voltage-above-threshold': 'Threshold (V)',
  'signal-strength-below-threshold': 'Threshold (db)',
  'signal-strength-above-threshold': 'Threshold (db)',
}

export default ({
  formikProps: {
    values: { slug, condition },
  },
  systemOfUnits,
}) => {
  const label = descriptions[slug]

  if (_.isPlainObject(label)) {
    return label[systemOfUnits]
  }

  return label || 'Threshold'
}
