import _ from 'lodash'
import groups from '../../../../../../lib/groups'

export default ({
  formikProps: {
    values: { slug },
  },
  readingTypesData,
  eventTypesData,
}) =>
  _.find(_.flatMap(groups({ readingTypesData, eventTypesData }), 'types'), {
    values: { slug },
  })
