import React from 'react'
import _ from 'lodash'
import { FieldArray } from 'formik'
import styled from 'styled-components'
import Group from '../../../../Rules/Form/Group'
import Button from '../../../../Button'
import Header from './Header'

const Container = styled.div`
  padding-bottom: 30px;
`

export default ({ values: { webhookHeaders } }) => (
  <Group label='Headers'>
    <FieldArray
      name='webhookHeaders'
      render={(arrayHelpers) => (
        <Container>
          {_.map(webhookHeaders, (header, index) => (
            <Header
              key={index}
              index={index}
              header={header}
              arrayHelpers={arrayHelpers}
            />
          ))}

          <Button onClick={() => arrayHelpers.push({ key: '', value: '' })}>
            Add
          </Button>
        </Container>
      )}
    />
  </Group>
)
