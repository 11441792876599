import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Tooltip from 'react-tooltip-lite'
import typeConfig from './lib/typeConfig'

const TooltipContent = styled.div`
  font-size: 12px;
  background-color: ${(props) => props.theme.colors.white};
`

const Container = styled.div`
  font-size: 25px;
  max-height: 25px;
  max-width: 25px;
`

export default ({ type, type: { name }, note, collection }) => {
  const theme = useContext(ThemeContext)

  return (
    <Tooltip
      content={
        <TooltipContent>
          {name}
          {note && ` (${note})`}
        </TooltipContent>
      }
    >
      <Container
        as={
          typeConfig({
            theme,
            type,
            collection,
          }).icon
        }
      />
    </Tooltip>
  )
}
