import React from 'react'
import {
  FiCodesandbox,
  FiCode,
  FiGlobe,
  FiBriefcase,
  FiShield,
  FiUsers,
  FiCompass,
  FiServer,
} from 'react-icons/fi'
import isAdminOfAnyAccount from '../../../lib/isAdminOfAnyAccount'
import isAdminOfAnySite from '../../../lib/isAdminOfAnySite'
import Button from '../Button'
import Menu from '../Menu'

export default ({ isGlobalAdmin, currentUser, closeTopMenu }) => {
  if (!isGlobalAdmin && !isAdminOfAnyAccount({ currentUser })) {
    return null
  }

  return (
    <Menu icon={FiShield} title='Admin' onClick={closeTopMenu}>
      {(isGlobalAdmin || isAdminOfAnyAccount({ currentUser })) && (
        <Button icon={FiServer} to='/hubs'>
          Hubs
        </Button>
      )}
      {(isGlobalAdmin ||
        isAdminOfAnySite({ currentUser }) ||
        isAdminOfAnyAccount({ currentUser })) && (
        <Button icon={FiCompass} to='/sites'>
          Sites
        </Button>
      )}
      {(isGlobalAdmin || isAdminOfAnyAccount({ currentUser })) && (
        <Button icon={FiUsers} to='/users'>
          Users
        </Button>
      )}
      {isGlobalAdmin && (
        <Button icon={FiBriefcase} to='/accounts'>
          Accounts
        </Button>
      )}
      {isGlobalAdmin && (
        <Button icon={FiCodesandbox} to='/models'>
          Models
        </Button>
      )}
      {isGlobalAdmin && (
        <Button icon={FiGlobe} to='/manufacturers'>
          Manufacturers
        </Button>
      )}
      {isGlobalAdmin && (
        <Button icon={FiCode} to='/debugger'>
          Debugger
        </Button>
      )}
    </Menu>
  )
}
