import React from 'react'
import _ from 'lodash'
import Sensor from '../../../../../../Input/Sensor'
import query from '../../../../../../Dashboard/Content/SensorsQuery/lib/query'
import onChange from './lib/onChange'

export default ({
  formikProps,
  formikProps: {
    values: { scopes },
  },
  sensorSelect,
}) => {
  const value = _.filter(scopes, { scopable: 'Sensor' })

  return (
    <Sensor
      label='Sensors'
      placeholder='Any sensor'
      value={() => value}
      onChange={onChange({ formikProps })}
      filterOption={({ value: id }) => !_.some(value, { scopableId: id })}
      includeEmpty={false}
      query={query}
      isMulti
      {...(_.isPlainObject(sensorSelect) ? sensorSelect : {})}
    />
  )
}
