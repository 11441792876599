import _ from 'lodash'

export default ({ eventTypes, modelReadingTypes, ...rest }) => ({
  eventTypes: _.map(eventTypes, ({ id }) => ({ id })),
  modelReadingTypes: _.map(modelReadingTypes, ({ unitId, readingTypeId }) => ({
    unitId,
    readingTypeId,
  })),
  ...rest,
})
