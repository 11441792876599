import React from 'react'
import { Formik, Form } from 'formik'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import TextInput from '../../Input/Text'
import Select from '../../Input/Select'
import ImageInput from '../../Input/Image'
import EventTypes from './EventTypes'
import ModelReadingTypes from './ModelReadingTypes'
import validationSchema from './lib/validationSchema'
import typeOptions from './lib/typeOptions'

export default ({
  children,
  imageUrlMutate = () => null,
  imageUrlMutationProps = {},
  ...rest
}) => (
  <Formik validationSchema={validationSchema} {...rest}>
    {(formikProps) => (
      <Form>
        <FormContainer>
          <TextInput label='Name' name='name' $maxWidth='300px' />
          <TextInput label='Code' name='code' $maxWidth='150px' />
          <Select
            label='Type'
            name='type'
            options={typeOptions}
            $maxWidth='200px'
          />
          <ImageInput
            label='Image'
            name='imageUrl'
            mutate={imageUrlMutate}
            mutationProps={imageUrlMutationProps}
          />
          <EventTypes formikProps={formikProps} />
          <ModelReadingTypes formikProps={formikProps} />

          {children}

          <FormButton />
        </FormContainer>
      </Form>
    )}
  </Formik>
)
