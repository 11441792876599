import condition from './condition'

export default ({
  formikProps: { values, setValues },
  formikProps,
  thresholdName,
  newValue,
}) =>
  setValues({
    ...values,
    condition: condition({
      formikProps,
      thresholdName,
      newValue,
    }),
  })
