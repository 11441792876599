import { gql } from 'graphql.macro'
import AreaWithGatewaysFragment from '../../lib/AreaWithGatewaysFragment'

export default gql`
  mutation CreateArea($data: AreaDataInput!) {
    createArea(data: $data) {
      ...AreaWithGatewaysFragment
    }
  }

  ${AreaWithGatewaysFragment}
`
