import React, { Component } from 'react'
import styled from 'styled-components'
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import { ToastProvider } from 'react-toast-notifications'
import AuthenticatedRoute from './AuthenticatedRoute'
import Dashboard from './Dashboard'
import Debugger from './Debugger'
import AreasList from './Areas/List'
import NewArea from './Areas/New'
import EditArea from './Areas/Edit'
import ModelsList from './Models/List'
import NewModel from './Models/New'
import EditModel from './Models/Edit'
import ManufacturersList from './Manufacturers/List'
import NewManufacturer from './Manufacturers/New'
import EditManufacturer from './Manufacturers/Edit'
import GatewaysList from './Gateways/List'
import NewGateway from './Gateways/New'
import EditGateway from './Gateways/Edit'
import SensorsList from './Sensors/List'
import NewSensor from './Sensors/New'
import EditSensor from './Sensors/Edit'
import RulesList from './Rules/List'
import NewRule from './Rules/New'
import EditRule from './Rules/Edit'
import ActionsList from './Actions/List'
import NewAction from './Actions/New'
import EditAction from './Actions/Edit'
import Login from './Login'
import Logout from './Logout'
import AccountsList from './Accounts/List'
import NewAccount from './Accounts/New'
import EditAccount from './Accounts/Edit'
import UsersList from './Users/List'
import NewUser from './Users/New'
import EditUser from './Users/Edit'
import SitesList from './Sites/List'
import NewSite from './Sites/New'
import EditSite from './Sites/Edit'
import HubsList from './Hubs/List'
import NewHub from './Hubs/New'
import EditHub from './Hubs/Edit'

const Container = styled.div``

export default class App extends Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)

      console.log('There was an error', error)
    })
  }

  render() {
    return (
      <Router>
        <Container>
          <ToastProvider>
            <Switch>
              <AuthenticatedRoute path='/dashboard' component={Dashboard} />
              <AuthenticatedRoute path='/debugger' exact component={Debugger} />
              <AuthenticatedRoute path='/areas' exact component={AreasList} />
              <AuthenticatedRoute path='/areas/new' exact component={NewArea} />
              <AuthenticatedRoute
                path='/areas/edit/:id'
                exact
                component={EditArea}
              />
              <AuthenticatedRoute path='/models' exact component={ModelsList} />
              <AuthenticatedRoute
                path='/models/new'
                exact
                component={NewModel}
              />
              <AuthenticatedRoute
                path='/models/edit/:id'
                exact
                component={EditModel}
              />
              <AuthenticatedRoute
                path='/manufacturers'
                exact
                component={ManufacturersList}
              />
              <AuthenticatedRoute
                path='/manufacturers/new'
                exact
                component={NewManufacturer}
              />
              <AuthenticatedRoute
                path='/manufacturers/edit/:id'
                exact
                component={EditManufacturer}
              />
              <AuthenticatedRoute
                path='/gateways'
                exact
                component={GatewaysList}
              />
              <AuthenticatedRoute
                path='/gateways/new'
                exact
                component={NewGateway}
              />
              <AuthenticatedRoute
                path='/gateways/edit/:id'
                exact
                component={EditGateway}
              />
              <AuthenticatedRoute
                path='/sensors'
                exact
                component={SensorsList}
              />
              <AuthenticatedRoute
                path='/sensors/new'
                exact
                component={NewSensor}
              />
              <AuthenticatedRoute
                path='/sensors/edit/:id'
                exact
                component={EditSensor}
              />
              <AuthenticatedRoute path='/rules' exact component={RulesList} />
              <AuthenticatedRoute path='/rules/new' component={NewRule} />
              <AuthenticatedRoute path='/rules/edit/:id' component={EditRule} />
              <AuthenticatedRoute
                path='/actions'
                exact
                component={ActionsList}
              />
              <AuthenticatedRoute
                path='/actions/new'
                exact
                component={NewAction}
              />
              <AuthenticatedRoute
                path='/actions/edit/:id'
                exact
                component={EditAction}
              />
              <AuthenticatedRoute path='/logout' exact component={Logout} />
              <AuthenticatedRoute
                path='/accounts'
                exact
                component={AccountsList}
              />
              <AuthenticatedRoute
                path='/accounts/new'
                exact
                component={NewAccount}
              />
              <AuthenticatedRoute
                path='/accounts/edit/:id'
                exact
                component={EditAccount}
              />
              <AuthenticatedRoute path='/users' exact component={UsersList} />
              <AuthenticatedRoute path='/users/new' exact component={NewUser} />
              <AuthenticatedRoute
                path='/users/edit/:id'
                exact
                component={EditUser}
              />
              <AuthenticatedRoute path='/sites' exact component={SitesList} />
              <AuthenticatedRoute path='/sites/new' exact component={NewSite} />
              <AuthenticatedRoute
                path='/sites/edit/:id'
                exact
                component={EditSite}
              />
              <AuthenticatedRoute path='/hubs' exact component={HubsList} />
              <AuthenticatedRoute path='/hubs/new' exact component={NewHub} />
              <AuthenticatedRoute
                path='/hubs/edit/:id'
                exact
                component={EditHub}
              />
              <Route path='/login' exact component={Login} />

              <Redirect to='/dashboard/grid' exact />
            </Switch>
          </ToastProvider>
        </Container>
      </Router>
    )
  }
}
