import { gql } from 'graphql.macro'
import AccountFragment from '../../Accounts/lib/AccountFragment'
import SiteFragment from '../../Sites/lib/SiteFragment'
import UserRoleFragment from '../../UserRoles/lib/UserRoleFragment'
import GatewayBaseFragment from '../../Gateways/lib/GatewayBaseFragment'

export default gql`
  fragment UserFragment on User {
    id
    name
    email
    timezone
    systemOfUnits
    isGlobalAdmin

    accounts {
      ...AccountFragment
    }

    sites {
      ...SiteFragment
    }

    gateways {
      ...GatewayBaseFragment
    }

    userRoles {
      ...UserRoleFragment
    }
  }

  ${AccountFragment}
  ${SiteFragment}
  ${GatewayBaseFragment}
  ${UserRoleFragment}
`
