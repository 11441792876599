import React from 'react'
import _ from 'lodash'
import { Formik, Form } from 'formik'
import { useQuery } from '@apollo/client'
import useAxios from 'axios-hooks'
import Loading from '../../Loading'
import Success from '../../Success'
import Error from '../../Error'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import RelationSelectInput from '../../Input/Relation/Select'
import ReadingValueInput from '../../Input/Reading/Value'
import TextInput from '../../Input/Text'
import sensorsQuery from '../../Sensors/List/Items/lib/query'
import gatewaysQuery from '../../Gateways/List/Items/lib/query'
import manufacturersQuery from '../../Manufacturers/List/Items/lib/query'
import modelsQuery from '../../Models/List/Items/lib/query'
import eventTypesQuery from '../../EventTypes/List/Items/lib/query'
import Request from './Request'
import ValuesInputs from './ValuesInputs'
import initialValues from './lib/initialValues'
import requestBody from './lib/requestBody'
import validationSchema from './lib/validationSchema'
import readingType from './ValuesInputs/Value/lib/readingType'

const url = process.env.REACT_APP_CREATE_EVENT_URL

const onChangeSensor = (sensors) => (getOptionValue) => ({ form, field }) => (
  option
) => {
  form.setFieldValue(field.name, getOptionValue(option))

  const sensor = _.find(sensors, { id: getOptionValue(option) })
  if (!sensor) return

  const firstGateway = _.first(sensor.gateways)
  if (!firstGateway) return

  form.setFieldValue('gatewayId', firstGateway.id)
}

export default ({ children, ...rest }) => {
  const eventTypesQueryProps = useQuery(eventTypesQuery)
  const modelsQueryProps = useQuery(modelsQuery)
  const manufacturersQueryProps = useQuery(manufacturersQuery)
  const gatewaysQueryProps = useQuery(gatewaysQuery)
  const sensorsQueryProps = useQuery(sensorsQuery, {
    variables: {
      filters: [],
    },
  })

  const queryProps = [
    eventTypesQueryProps,
    modelsQueryProps,
    manufacturersQueryProps,
    gatewaysQueryProps,
    sensorsQueryProps,
  ]

  const [axiosProps, fetch] = useAxios(
    {
      url,
      method: 'post',
    },
    {
      manual: true,
      useCache: false,
    }
  )

  if (_.some(queryProps, 'loading')) return <Loading />
  if (_.some(queryProps, 'error')) return <Error />

  return (
    <Formik
      initialValues={initialValues()}
      onSubmit={({ consumerKey, consumerSecret, ...values }) =>
        fetch({
          headers: {
            'Content-Type': 'application/json',
            'Consumer-Key': consumerKey,
            'Consumer-Secret': consumerSecret,
          },
          data: requestBody({
            values,
            sensorsData: sensorsQueryProps.data,
            gatewaysData: gatewaysQueryProps.data,
            manufacturersData: manufacturersQueryProps.data,
            modelsData: modelsQueryProps.data,
            eventTypesData: eventTypesQueryProps.data,
          }),
        })
      }
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <Form>
          <FormContainer>
            <RelationSelectInput
              label='Sensor'
              name='sensorId'
              isCreatable
              onChange={onChangeSensor(sensorsQueryProps.data.sensors)}
              options={sensorsQueryProps.data.sensors}
            />

            <RelationSelectInput
              label='Gateway'
              name='gatewayId'
              isCreatable
              options={gatewaysQueryProps.data.gateways}
            />

            <RelationSelectInput
              label='Manufacturer'
              name='manufacturerId'
              isCreatable
              options={manufacturersQueryProps.data.manufacturers}
            />

            <RelationSelectInput
              label='Model'
              name='modelId'
              isCreatable
              options={modelsQueryProps.data.models}
            />

            <RelationSelectInput
              label='Event'
              name='eventTypes'
              includeEmpty={false}
              isCreatable
              isMulti
              options={eventTypesQueryProps.data.eventTypes}
            />

            <ValuesInputs values={values} data={sensorsQueryProps.data} />

            <ReadingValueInput
              label='Signal Strength (db)'
              name='db'
              readingType={readingType({
                data: sensorsQueryProps.data,
                name: 'Signal Strength',
              })}
            />

            <ReadingValueInput
              label='IP Address'
              name='ipAddress'
              readingType={readingType({
                data: sensorsQueryProps.data,
                name: 'IP Address',
              })}
            />

            {children}

            <TextInput
              label='URL'
              name='url'
              value={url}
              disabled
              minWidth='300px'
            />
            <TextInput
              label='Consumer Key'
              name='consumerKey'
              minWidth='300px'
            />
            <TextInput
              label='Consumer Secret'
              name='consumerSecret'
              minWidth='300px'
            />

            <Request
              values={values}
              sensorsData={sensorsQueryProps.data}
              gatewaysData={gatewaysQueryProps.data}
              manufacturersData={manufacturersQueryProps.data}
              modelsData={modelsQueryProps.data}
              eventTypesData={eventTypesQueryProps.data}
              url={url}
            />

            <FormButton>Send fake event</FormButton>

            {axiosProps.data && !axiosProps.error && (
              <Success leftAligned>Fake event sent!</Success>
            )}
            {axiosProps.error && <Error leftAligned />}
          </FormContainer>
        </Form>
      )}
    </Formik>
  )
}
