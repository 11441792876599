import React, { useRef } from 'react'
import styled from 'styled-components'
import 'resize-observer-polyfill'
import useComponentSize from '@rehooks/component-size'
import Handles from './Handles'
import SelectedZone from './SelectedZone'

const Container = styled.div`
  padding: 40px 20px;
  user-select: none;
`

const Track = styled.div`
  position: relative;
  height: 10px;
  background-color: ${(props) => props.theme.colors.grey2};
  border-radius: 4px;
`

export default ({
  lowerValue,
  upperValue,
  valueToPosition,
  formatValue,
  onDrag,
  grid,
}) => {
  const ref = useRef(null)
  const { width } = useComponentSize(ref)
  const lowerPosition = valueToPosition({
    value: lowerValue,
    width,
  })
  const upperPosition = valueToPosition({
    value: upperValue,
    width,
  })

  return (
    <Container>
      <Track ref={ref}>
        {width > 0 && (
          <SelectedZone
            lowerPosition={lowerPosition}
            upperPosition={upperPosition}
            width={width}
          />
        )}
        {width > 0 && (
          <Handles
            lowerValue={lowerValue}
            upperValue={upperValue}
            lowerPosition={lowerPosition}
            upperPosition={upperPosition}
            width={width}
            formatValue={formatValue}
            onDrag={onDrag}
            grid={grid}
          />
        )}
      </Track>
    </Container>
  )
}
