import React from 'react'
import useCurrentUser from '../../../../../../../hooks/useCurrentUser'
import Plain from '../../../Advanced/Condition/Term/Plain'
import label from './lib/label'
import description from './lib/description'
import termPath from './lib/termPath'

export default ({ formikProps }) => {
  const { currentUser } = useCurrentUser()

  if (!currentUser) return null

  const { systemOfUnits } = currentUser

  return (
    <Plain
      label={label({ formikProps, systemOfUnits })}
      name={`${termPath({ formikProps })}.value`}
      description={description({ formikProps })}
      formikProps={formikProps}
    />
  )
}
