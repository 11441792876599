import React from 'react'
import Button from '../Button'

export default ({ children, loading, ...rest }) => (
  <Button
    as='button'
    $primary
    size='big'
    type='submit'
    disabled={loading}
    {...rest}
  >
    {loading ? 'Loading...' : children || 'Save'}
  </Button>
)
