import _ from 'lodash'
import { celsiusToFahrenheit } from 'temperature'
import isNumber from 'is-number'
import formikKey from './formikKey'

export default ({ formikProps: { values }, name, systemOfUnits, index }) => {
  const rawValue = _.get(values, formikKey({ name, index }))
  if (!isNumber(rawValue)) return rawValue

  if (systemOfUnits === 'metric') {
    return rawValue
  }

  return _.round(celsiusToFahrenheit(rawValue), 2)
}
