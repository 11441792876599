import { gql } from 'graphql.macro'
import TimestampFragment from '../../Timestamps/lib/TimestampFragment'

export default gql`
  query EventTimestampsQuery($id: ID!) {
    event(id: $id) {
      id

      timestamps {
        ...TimestampFragment
      }
    }
  }

  ${TimestampFragment}
`
