import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import Loading from '../../../Loading'
import Error from '../../../Error'
import Heading from '../../../Heading'
import ListHeader from '../../../List/Header'
import BackIcon from './BackIcon'
import Form from './Form'
import query from './lib/query'
import getReadingTypes from './lib/readingTypes'

const CHART_READING_TYPE_TYPES = ['Integer', 'Float']

export default ({
  match: {
    params: { sensorId },
  },
}) => {
  const { loading, error, data } = useQuery(query, {
    variables: {
      id: sensorId,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const {
    sensor: {
      name,
      model: { eventTypes, modelReadingTypes },
    },
    sensor,
  } = data

  const readingTypes = _.filter(
    getReadingTypes({ modelReadingTypes }),
    ({ type }) => _.includes(CHART_READING_TYPE_TYPES, type)
  )

  return (
    <>
      <ListHeader>
        <Heading compact>
          <BackIcon /> {name}
        </Heading>
      </ListHeader>

      <Form
        sensor={sensor}
        readingTypes={readingTypes}
        eventTypes={eventTypes}
      />
    </>
  )
}
