import _ from 'lodash'
import query from './query'

export default (cache, {
  data: {
    deleteSite: {
      id,
    },
  },
}) => {
  const { sites } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { sites: _.reject(sites, { id }) },
  })
}
