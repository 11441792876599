export default ({
  testMutate,
}) => ({
  id,
}) => () => (
  testMutate({
    variables: {
      id,
    },
  })
)
