import _ from 'lodash'

export default ({ areas, gateways }, { gatewayId: id }) => {
  if (_.isEmpty(id)) return

  const gateway = _.find(gateways, { id })
  if (!gateway) return

  return _.get(gateway, 'area.mapImageUrl')
}
