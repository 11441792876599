import React from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/client'
import Loading from '../Loading'
import Error from '../Error'
import query from '../Models/List/Items/lib/query'
import RelationSelectInput from './Relation/Select'

export default ({ type, ...rest }) => {
  const { loading, error, data } = useQuery(query)

  if (loading) return <Loading />
  if (error) return <Error />

  return (
    <RelationSelectInput
      label='Model'
      name='modelId'
      options={_.filter(data.models, { type })}
      $maxWidth='300px'
      {...rest}
    />
  )
}
