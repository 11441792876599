import { gql } from 'graphql.macro'
import SensorFullFragment from '../../../../../../Sensors/lib/SensorFullFragment'
import EventBaseFragment from '../../../../../../Events/lib/EventBaseFragment'

export default gql`
  query Sensor($id: ID!) {
    sensor(id: $id) {
      ...SensorFullFragment
      status

      lastEvents {
        ...EventBaseFragment
      }
    }
  }

  ${SensorFullFragment}
  ${EventBaseFragment}
`
