import React from 'react'
import { Formik, Form } from 'formik'
import FormContainer from '../../Form/Container'
import FormButton from '../../Form/Button'
import TextInput from '../../Input/Text'
import AccountInput from '../../Input/Account'
import CurrentUser from '../../CurrentUser'
import validationSchema from './lib/validationSchema'

export default ({ children, ...rest }) => (
  <Formik validationSchema={validationSchema} {...rest}>
    {() => (
      <Form>
        <FormContainer>
          <TextInput label='Name' name='name' $maxWidth='300px' />
          <TextInput
            label='Local IP Address'
            name='localIpAddress'
            $maxWidth='300px'
          />
          <TextInput
            label='Public IP Address'
            name='publicIpAddress'
            $maxWidth='300px'
          />

          <CurrentUser>
            {({ currentUser: { isGlobalAdmin } }) =>
              isGlobalAdmin && <AccountInput includeEmpty={false} />
            }
          </CurrentUser>

          {children}

          <FormButton />
        </FormContainer>
      </Form>
    )}
  </Formik>
)
