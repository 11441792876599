import React from 'react'
import styled from 'styled-components'
import { Line } from 'rc-progress'
import colors from '../../../../../theme/colors'
import percent from './lib/percent'

const Container = styled.div`
  margin-bottom: 60px;
  background-color: ${(props) => props.theme.colors.red2};
  padding: 20px;
  border-radius: 8px;
`

const Heading = styled.div`
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 18px;
`

const Description = styled.div`
  padding-bottom: 20px;
`

const Paragraph = styled.div`
  padding-top: 10px;
`

const ProgressBar = styled.div`
  display: flex;
  align-items: center;
`

const LineContainer = styled.div`
  flex-grow: 1;
`

const Percent = styled.div`
  padding-left: 10px;
  font-size: 14px;
`

export default ({ job: { progress }, job, heading }) => (
  <Container>
    <Heading>{heading}</Heading>

    <Description>
      Depending on the sensor history size it might take up to 1 hour. Feel free
      to navigate away and use the dashboard in the meantime.
      <Paragraph>
        You can refresh this page periodically to check the progress.
      </Paragraph>
    </Description>

    <ProgressBar>
      <LineContainer>
        <Line percent={progress} strokeColor={colors.grey9} />
      </LineContainer>
      <Percent>{percent({ job })}</Percent>
    </ProgressBar>
  </Container>
)
