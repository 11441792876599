import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import config from './lib/config'

const icon = ({ type: { name } }) => {
  const item = _.find(config, { name })
  if (!item) return null

  return item.icon
}

const Container = styled.div`
  padding-right: 5px;
  max-height: 14px;
  max-width: 14px;
`

export default ({ type }) => <Container as={icon({ type })} />
