import { gql } from 'graphql.macro'
import HubFragment from '../../lib/HubFragment'

export default gql`
  query Hub($id: ID!) {
    hub(id: $id) {
      ...HubFragment
    }
  }

  ${HubFragment}
`
