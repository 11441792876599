import _ from 'lodash'
import serializeOptions from '../../Form/Config/Advanced/ScopesSelect/lib/serializeOptions'
import initialValueDefaults from '../../lib/initialValueDefaults'
import initialCondition from './initialCondition'
import serializeActions from './serializeActions'

export default ({
  rule: {
    name,
    slug,
    active,
    debouncePeriodInSeconds,
    debouncePeriodPerSensor,
    condition,
    scopes,
    actions,
    accountId,
  },
  readingTypesQueryProps,
  eventTypesQueryProps,
}) =>
  _.defaults(
    {
      name,
      slug,
      active,
      debouncePeriodInSeconds,
      debouncePeriodPerSensor,
      condition: initialCondition(condition),
      scopes: serializeOptions(scopes),
      actions: serializeActions({ actions }),
      accountId,
    },
    {
      ...initialValueDefaults({
        readingTypesQueryProps,
        eventTypesQueryProps,
      }),
      condition: null,
    }
  )
