import React, { useState } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { VariableSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'
import { Lifecycles } from 'libreact/lib/Lifecycles'
import useSensorFilters from '../../../../SensorFilters/useSensorFilters'
import Loading from '../../../../Loading'
import Error from '../../../../Error'
import Note from '../../../../Note'
import Item from './Item'
import query from './lib/query'
import variables from './lib/variables'
import loadMore from './lib/loadMore'
import isItemLoaded from './lib/isItemLoaded'
import itemSize from './lib/itemSize'

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  width: 100%;
  padding-top: 20px;
  position: relative;
`

export default () => {
  const sensorFiltersProps = useSensorFilters()

  const { loading, error, data, fetchMore } = useQuery(query, {
    variables: variables({ sensorFiltersProps }),
    fetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  })

  const [listRef, setListRef] = useState(null)

  if (loading && _.isEmpty(data)) return <Loading />
  if (_.isEmpty(data)) return <Note>Waiting for activity...</Note>
  if (error) return <Error />

  const {
    feedItems: {
      results,
      cursors: { hasNext },
    },
    feedItems,
  } = data

  const items = _.map(_.orderBy(results, ['createdAt', 'id'], ['desc', 'desc']))
  const itemCount = hasNext ? items.length + 1 : items.length

  return (
    <Container>
      {_.isEmpty(results) && <Note>No events yet.</Note>}

      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded({ hasNext, items })}
            itemCount={itemCount}
            loadMoreItems={loadMore({ fetchMore, feedItems, loading })}
          >
            {({ onItemsRendered, ref: setInfiniteLoaderRef }) => (
              <Lifecycles
                items={items}
                didUpdate={() => listRef && listRef.resetAfterIndex(0)}
              >
                <List
                  ref={(el) => {
                    setInfiniteLoaderRef(el)
                    setListRef(el)
                  }}
                  onItemsRendered={onItemsRendered}
                  height={height}
                  itemCount={items.length}
                  itemKey={(index) => items[index].id}
                  width={width}
                  itemData={{ items, hasNext }}
                  itemSize={itemSize({ items })}
                >
                  {Item}
                </List>
              </Lifecycles>
            )}
          </InfiniteLoader>
        )}
      </AutoSizer>
    </Container>
  )
}
