import React from 'react'
import Select from '../../../../../../../Input/Select'
import options from './lib/options'
import value from './lib/value'
import onChange from './lib/onChange'

export default ({ formikProps }) => (
  <Select
    label='Unit'
    name='unit'
    value={value}
    onChange={onChange}
    options={options}
    $maxWidth='150px'
  />
)
