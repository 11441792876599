import _ from 'lodash'

export default ({
  path,
  queryPath,
  data: { readingTypes },
  values: { modelReadingTypes },
}) =>
  _.reject(readingTypes, ({ id }) =>
    _.some(modelReadingTypes, { readingTypeId: id })
  )
