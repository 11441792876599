import _ from 'lodash'

export default ({ sensorFiltersProps: { filters }, feedItemAdded }) => {
  const filter = _.find(filters, { type: 'EventType' })

  if (!filter) return true
  if (!feedItemAdded.item.eventTypes) return true

  return _.includes(_.map(feedItemAdded.item.eventTypes, 'id'), filter.id)
}
