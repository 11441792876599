export default [
  '#01AE64',
  '#0197CA',
  '#FF784C',
  '#FFB73A',
  '#E650E6',
  '#6950E6',
  '#50B4E6',
  '#50E69B',
  '#82E650',
]
