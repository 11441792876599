import _ from 'lodash'

export default ({
  arrayHelpers: {
    remove,
  },
  values: {
    actions,
  },
}) => ({
  id,
}) => (
  remove(_.findIndex(actions, { id }))
)
