import React from 'react'
import styled from 'styled-components'
import TextInput from './Text'

const Input = styled.textarea`
  background-color: ${(props) => props.theme.colors.light5};
  padding: 10px;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  max-width: ${(props) => props.$maxWidth || 'inherit'};
`

export default (props) => <TextInput inputComponent={Input} {...props} />
