import React from 'react'
import { useQuery } from '@apollo/client'
import Loading from '../../../../Loading'
import Error from '../../../../Error'
import query from '../../../../Areas/Edit/lib/query'

export default ({ areaId, children }) => {
  const { loading, error, data } = useQuery(query, {
    variables: {
      id: areaId,
    },
  })

  if (loading) return <Loading />
  if (error) return <Error />

  return children({ area: data.area })
}
