import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import Loading from '../../../Loading'
import Error from '../../../Error'
import ListGroupedBySiteItems from '../../../List/GroupedBySiteItems'
import query from './lib/query'
import reorderMutation from '../../Edit/lib/mutation'
import deleteMutation from './lib/deleteMutation'
import deleteUpdate from './lib/deleteUpdate'

export default () => {
  const { loading, error, data } = useQuery(query)
  const [onDelete, mutationProps] = useMutation(deleteMutation, {
    update: deleteUpdate,
  })

  if (loading) return <Loading />
  if (error) return <Error />

  const { areas } = data

  return (
    <>
      <ListGroupedBySiteItems
        items={areas}
        query={query}
        queryName='areas'
        reorderMutation={reorderMutation}
        reorderMutationName='updateArea'
        onDelete={onDelete}
        editUrl={({ id }) => `/areas/edit/${id}`}
      />
      {mutationProps.error && <Error />}
    </>
  )
}
