import _ from 'lodash'
import query from '../../List/Items/lib/query'

export default (cache, {
  data: {
    createModel,
  },
}) => {
  const { models } = cache.readQuery({ query })

  cache.writeQuery({
    query,
    data: { models: _.concat(models, createModel) },
  })
}
