import React from 'react'
import { Redirect } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Layout from '../../Layout'
import Error from '../../Error'
import EditHeader from '../../Edit/Header'
import Form from '../Form'
import initialValues from './lib/initialValues'
import mutation from './lib/mutation'
import update from './lib/update'
import variables from './lib/variables'

export default () => {
  const [mutate, mutationProps] = useMutation(mutation, {
    update,
  })

  return (
    <Layout>
      <EditHeader>New model</EditHeader>

      <Form
        initialValues={initialValues}
        onSubmit={(values) => mutate({ variables: variables(values) })}
      />
      {mutationProps.error && <Error leftAligned />}
      {mutationProps.data && <Redirect to='/models' />}
    </Layout>
  )
}
