import React from 'react'
import _ from 'lodash'
import colors from '../../../../../../../../theme/colors'

export default ({
  x,
  y,
  dy,
  dx,
  payload: {
    value,
  },
  formatter = (value) => _.round(value, 1),
  ...rest
}) => (
  <text
    x={x}
    y={y}
    dx={dx}
    dy={dy}
    textAnchor='middle'
    fill={colors.grey}
    fontSize='12px'
  >
    {formatter(value)}
  </text>
)
