// import React from 'react'
import _ from 'lodash'
import isNumber from 'is-number'
import isJSON from 'is-json'
// import Json from './Json'

export default ({ reading: { value } }) => {
  if (isNumber(value)) return _.round(value, 1)
  if (isJSON(value)) return value
  //<Json value={value} />

  return value
}
