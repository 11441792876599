import { gql } from 'graphql.macro'
import GatewayFragment from '../../../lib/GatewayFragment'

export default gql`
  mutation DeleteGateway($id: ID!) {
    deleteGateway(id: $id) {
      ...GatewayFragment
    }
  }

  ${GatewayFragment}
`
