export default [
  {
    label: 'No notification/alert',
    value: -2,
  },
  {
    label: 'Quiet notification',
    value: -1,
  },
  {
    label: 'Normal priority',
    value: 0,
  },
  {
    label: "High-priority (bypass the user's quiet hours)",
    value: 1,
  },
  {
    label: 'Emergency priority (require confirmation from the user)',
    value: 2,
  },
]
